import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: GetScaledValue(40),
    paddingTop: GetScaledValue(60),
    paddingHorizontal: GetScaledValue(80),
    zIndex: 2,
  },
  iconContainer: {
    gap: GetScaledValue(40),
  },
  actionContainer: {
    flexDirection: 'row',
    gap: GetScaledValue(20),
  },
});

export default styles;
