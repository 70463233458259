import React from 'react';

import { AppProvider, useApp, appActions } from './App';
import { MenuProvider, useMenu, menuActions } from './Menu';
import { ProfileProvider, useProfile, profileActions } from './Profile';

/**
 * Global Context Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
const GlobalContextProvider = ({ children }) => {
  return (
    <AppProvider>
      <MenuProvider>
        <ProfileProvider>{children}</ProfileProvider>
      </MenuProvider>
    </AppProvider>
  );
};

export {
  GlobalContextProvider,
  useApp,
  appActions,
  useMenu,
  menuActions,
  useProfile,
  profileActions,
};
