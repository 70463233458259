import React, { memo, useCallback } from 'react';
import { FlatList } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import { PageRailListPropTypes } from './proptypes';
import RailItem from './railItem';

/**
 * Page Horizontal Rail List
 *
 * @param {object} props - props
 * @param {string} props.prefix - prefix
 * @param {Array} props.content - introduction data
 * @param {string} props.railListRef - railListRef
 * @param {string} props.leftFocusKey - leftFocusKey
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.onScrollToIndexFailedHandler - onScrollToIndexFailedHandler
 * @returns {module:JSX.Element} - JSX.Element
 */
const RailList = ({
  prefix,
  content,
  railListRef,
  leftFocusKey,
  onEnterPress,
  onScrollToIndexFailedHandler,
}) => {
  const keyExtractor = useCallback(
    (_item, index) => `${prefix}_${index}`,
    [prefix]
  );

  const onBecameFocused = useCallback((_coordinate, selected) => {
    setTimeout(() => {
      railListRef?.current?.scrollToIndex({
        viewPosition: 0,
        index: selected.index,
        animated: true,
      });
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <RailItem
          key={prefix}
          item={item}
          index={index}
          prefix={prefix}
          leftFocusKey={leftFocusKey}
          onEnterPress={onEnterPress}
          contentLength={content.length}
          onBecameFocused={onBecameFocused}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content?.length]
  );

  return (
    <FlatList
      data={content}
      ref={railListRef}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      showsVerticalScrollIndicator={false}
      onScrollToIndexFailed={onScrollToIndexFailedHandler}
      removeClippedSubviews={false}
      scrollEnabled={false}
      windowSize={50}
    />
  );
};

RailList.propTypes = PageRailListPropTypes;
RailList.whyDidYouRender = false;

export default memo(withFocusable()(RailList));
