import { StyleSheet, View } from 'react-native';
import React from 'react';
import Icon from '../../atoms/Icon';
import { PauseFilled } from '../../../assets/icons';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Pause Button
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const PauseButton = () => {
  return (
    <View style={styles.container}>
      <Icon
        icon={PauseFilled}
        width={120}
        height={120}
        color="white"
        style={styles.pauseButton}
      />
    </View>
  );
};

export default PauseButton;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: GetScaledValue(480),
    alignSelf: 'center',
    zIndex: 1,
  },
  pauseButton: {
    opacity: 0.56,
  },
});
