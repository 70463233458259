import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: GetScaledValue(10),
    height: GetScaledValue(60),
  },
  verticalLine: {
    height: GetScaledValue(50),
    width: GetScaledValue(4),
    backgroundColor: colors.primary500,
  },
  ratintText: {},
});

export default styles;
