import React from 'react';
import { NumpadData } from './data';
import KeyButton from '../../molecules/buttons/KeyButton';
import { styles } from './styles';

/**
 * Represents an item in the Numpad component.
 *
 * @param {object} props - The component props.
 * @param {object} props.item - Item.
 * @param {number} props.index - The item index.
 * @param {string} props.prefix - Focus prefix.
 * @param {Function} props.onEnterPress - The callback function for enter press.
 * @param {string} props.rightEdgeFocusKey - The focus key of the right edge.
 * @param {string} props.leftEdgeFocusKey - The focus key of the left edge.
 * @param {string} props.downEdgeFocusKey - The focus key of the down edge.
 * @param {string} props.upEdgeFocusKey - The focus key of the up edge.
 * @returns {module:JSX.Element} The rendered item component.
 */
const Item = ({
  item,
  index,
  prefix,
  onEnterPress,
  rightEdgeFocusKey,
  leftEdgeFocusKey,
  downEdgeFocusKey,
  upEdgeFocusKey,
}) => {
  const keyIconsAndStyles = NumpadData.keyIconsAndStyles;

  return (
    <KeyButton
      label={item.label}
      text={
        keyIconsAndStyles[item.label]
          ? keyIconsAndStyles[item.label]?.text
          : item.label
      }
      index={index}
      key={index}
      focusKey={`${prefix}-${index}`}
      rightFocusKey={
        index % 3 === 2
          ? rightEdgeFocusKey
            ? rightEdgeFocusKey
            : `${prefix}-${index - 2}`
          : `${prefix}-${index + 1}`
      }
      leftFocusKey={
        index % 3 === 0
          ? leftEdgeFocusKey
            ? leftEdgeFocusKey
            : `${prefix}-${index + 2}`
          : `${prefix}-${index - 1}`
      }
      downFocusKey={
        index + 3 > 11 ? downEdgeFocusKey : `${prefix}-${index + 3}`
      }
      upFocusKey={index - 3 < 0 ? upEdgeFocusKey : `${prefix}-${index - 3}`}
      theme={'neutral800'}
      themeFocused={'blackWhite'}
      style={styles.item}
      textSize={styles.textSize}
      iconSize={keyIconsAndStyles[item.label]?.size}
      icon={keyIconsAndStyles[item.label]?.icon}
      onEnterPress={onEnterPress}
    />
  );
};

export default Item;
