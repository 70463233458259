import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

import { SPACING_VALUES } from '../../../helpers/Enums';
import {
  itemMargin,
  isWeb,
  marginEnd,
  padding,
  sidebarWidth,
} from '../../../helpers/CommonHelper';

const styles = StyleSheet.create({
  pageArea: {
    backgroundColor: 'black',
  },
  gridContainer: {
    position: 'absolute',
    zIndex: 98,
  },
  listStyle: {
    paddingTop: padding,
  },
  contentContainerStyle: {
    paddingBottom: GetScaledValue(400),
  },
  contentContainerStyleRTL: {
    paddingStart: isWeb ? 0 : sidebarWidth + padding,
    paddingEnd: marginEnd,
  },
  flatListWrapper: {
    flex: 1,
    paddingStart: GetScaledValue(200),
  },
  flatListArea: {
    flex: 1,
  },
  renderItem: {
    marginBottom: GetScaledValue(30),
  },
  loading: {
    position: 'absolute',
    backgroundColor: 'black',
    zIndex: 99,
    start: 0,
    top: 0,
  },
});

/**
 * carousel poster style
 *
 * @param {number} posterWidth - poster width
 * @param {number} posterHeight - poster height
 * @returns {object} - object
 */
export const posterStyles = (posterWidth, posterHeight) =>
  StyleSheet.create({
    posterArea: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      marginEnd: itemMargin,
      zIndex: 1,
    },
    posterItem: {
      width: GetScaledValue(posterWidth),
      height: GetScaledValue(posterHeight),
      borderRadius: GetScaledValue(SPACING_VALUES.SBIG),
    },
  });

export default styles;
