import React, { memo, useCallback, useState } from 'react';
import { Image, View } from 'react-native';
import styles from './style';
import ContentLoader, { Rect } from 'react-content-loader/native';
import colors from '../../../helpers/Colors';
import { GetScaledValue } from '@digiturk/screen-size';
import Icon from '../Icon';
import { Lock, PlusStroke, Profile, Toddlers } from '../../../assets/icons';

/**
 *
 * @param {object} props - props
 * @param {object} props.source - source
 * @param {boolean} props.focused - focused
 * @param {object} props.style - style
 * @param {boolean} props.isLock - isLock
 * @param {boolean} props.isKid - isKid
 * @param {boolean} props.isAddProfile - isAddProfile
 * @param {number} props.iconWidth - iconWidth
 * @returns {module:JSX.Element} -
 */
const Avatar = ({
  source,
  style,
  isLock,
  isKid,
  isAddProfile,
  focused,
  iconWidth,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const calcMeasurements = useCallback(
    (px) => {
      return focused ? px * 1.5 : px;
    },
    [focused]
  );

  const onLoadEnd = useCallback(() => {
    setIsLoading(false);
  }, []);

  const Skeleteon = useCallback(() => {
    return (
      <ContentLoader
        speed={1}
        style={styles.loader}
        backgroundColor={colors.neutral700}
        foregroundColor={colors.neutral500}>
        <Rect
          x="0"
          y="0"
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={style?.width}
          height={style?.height}
        />
      </ContentLoader>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  const IconContainer = useCallback(() => {
    return (
      <View style={[styles.iconContainer, { width: style.width }]}>
        <Icon
          icon={isKid ? Toddlers : Lock}
          width={
            isKid ? calcMeasurements(iconWidth ?? 110) : calcMeasurements(40)
          }
          height={calcMeasurements(40)}
          color={colors.neutral50}
        />
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcMeasurements, isKid]);

  const AddProfile = useCallback(() => {
    return (
      <View style={[style, { backgroundColor: colors.neutral500 }]}>
        <Icon
          icon={PlusStroke}
          color={colors.neutral50}
          width={calcMeasurements(60)}
          height={calcMeasurements(60)}
        />
      </View>
    );
  }, [calcMeasurements, style]);

  const NewProfile = useCallback(() => {
    return (
      <View style={[style, { backgroundColor: colors.neutral500 }]}>
        <Icon
          icon={Profile}
          width={iconWidth}
          height={iconWidth}
          color={colors.transparent}
        />
      </View>
    );
  }, [iconWidth, style]);

  if (isAddProfile) return <AddProfile />;
  if (!source) return <NewProfile />;

  return (
    <View>
      <Image source={{ uri: source }} style={style} onLoadEnd={onLoadEnd} />
      {!isLoading && (isLock || isKid) && <IconContainer />}
      {isLoading && <Skeleteon />}
    </View>
  );
};

export default memo(Avatar);
