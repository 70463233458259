import axios from 'axios';
import env from './config';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { encryptKey } from './encryptKey';

const dune = `${env.SmartTv.ApiTest}api/v${env.SmartTv.ApiVersion}/harkonnen`;

const selectPlatform = Platform.select({
  ios: 'appletv',
  android: 'androidtv',
  web: 'smarttv',
});

const api = axios.create({
  baseURL: env.SmartTv.Endpoint,
  headers: {
    'accept-digi': '',
    'x-platform': selectPlatform,
    'accept-language': 'EN',
    'x-application': 'tod2',
    'x-device-type': 'tv',
    Authorization: '',
    'Cache-Control': 'public, max-age=31536000',
    'x-region': 'QA_5264',
  },
});

/**
 * set authorization token to api requests
 *
 * @param {string} token - token
 */
export const setAuth = (token) => {
  api.defaults.headers.Authorization = token;
};

/**
 * set api key to api requests
 *
 * @param {string} arrakis - api key
 */
export const setDigi = (arrakis) => {
  api.defaults.headers['accept-digi'] = arrakis;
};

/**
 * set baseUrl
 *
 * @param {string} url - url
 */
export const setBaseUrl = (url) => {
  api.defaults.baseURL = url;
};

/**
 * Request interceptor
 *
 * @param {object} config - request config
 * @returns {object} - request config
 */
const requestHandler = async (config) => {
  const value = await AsyncStorage.getItem('arrakis');

  if (!value) {
    const response = await axios.get(dune, { headers: api.defaults.headers });

    if (response) {
      let key = [...response.data].reverse().join('');

      key = key.substring(3, key.length - 3);
      AsyncStorage.setItem('arrakis', key);
      config.headers['accept-digi'] = encryptKey(key);
    } else console.error('Error getting Harkonnen key');
  } else {
    config.headers['accept-digi'] = encryptKey(value);
  }

  return config;
};

// Add a request interceptor
api.interceptors.request.use((config) => requestHandler(config));

/**
 * Response error handler
 *
 * @param {object} error - error response
 * @returns {object} - error response
 */
const responseErrorHandler = async (error) => {
  const originalRequest = error.config;

  if (error.response.status === 403 && !originalRequest.retry) {
    originalRequest.retry = true;
    const response = await axios.get(dune, { headers: api.defaults.headers });

    if (response) {
      let key = [...response.data].reverse().join('');

      key = key.substring(3, key.length - 3);
      AsyncStorage.setItem('arrakis', key);
      originalRequest.headers['accept-digi'] = encryptKey(key);
    }

    return api(originalRequest);
  }

  return Promise.reject(error);
};

/**
 * Response interceptor
 */
api.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error)
);

export default api;
