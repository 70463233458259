import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.shades100,
    padding: GetScaledValue(80),
  },
  description: {
    marginBottom: GetScaledValue(40),
  },
  addButton: {
    width: GetScaledValue(400),
    height: GetScaledValue(74),
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
});

export default styles;
