import { I18nManager, Platform } from 'react-native';
import { appActions, useApp } from '../../context';
import { setDirectionRTL } from '../../helpers/FocusHelper';
import { reloadApp } from '../../helpers/CommonHelper';

/**
 * Use Localization Hook
 *
 * @returns {object} - return object
 */
const useLocalization = () => {
  const { appState, appDispatch } = useApp();

  /**
   * Set Localization
   *
   * @param {boolean} data - localization direction
   */
  const setLocalization = (data) => {
    I18nManager.forceRTL(data);
    I18nManager.allowRTL(data);

    setDirectionRTL(data);

    (async function () {
      await appDispatch(appActions.setLanguageDirection(data ? 'rtl' : 'ltr'));

      await reloadApp();
    })();
  };

  /**
   * RTL Status
   *
   * @returns {boolean} direction status
   */
  const getIsRTL = () => {
    if (Platform.OS === 'web') {
      return appState?.languageDirection === 'rtl' ? true : false;
    } else {
      return I18nManager.getConstants().isRTL;
    }
  };

  return {
    isRTL: getIsRTL(),
    languageDirection: appState?.languageDirection,
    setLocalization: setLocalization,
  };
};

export default useLocalization;
