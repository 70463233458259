import { View } from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PlayerWidget from '../../molecules/PlayerWidget';
import {
  isWeb,
  windowHeight,
  windowWidth,
} from '../../../helpers/CommonHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { useFocusEffect } from '@react-navigation/native';
import HiddenFocusPlaceHolder from './hiddenFocusPlaceHolder';
import {
  getCurrentFocusKey,
  isLongPressActive,
  setFocusCustom,
} from '../../../helpers/FocusHelper';
import LivePlayerMenuProperties from '../../templates/LivePlayerMenuProperties';
import SportsStatistics from '../../templates/SportsStatistics';
import { VIDEO_MODES } from '../../../helpers/Enums';
import styles from './styles';

const ACTIVITY_TIMEOUT = 5000;

const sampleContent = {
  audios: ['English', 'Arabic'],
  contentType: { links: [[Object]], name: 'FILM', type: 1, vodType: 'LIVE' },
  duration: 119,
  genres: ['Thriller', 'Action', 'Crime'],
  id: 637649,
  image: {
    hero_16x9:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    hero_2x3:
      'https://image.tmdb.org/t/p/original/M7SUK85sKjaStg4TKhlAVyGlz3.jpg',
    hero_3x1:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    hero_3x3:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    hero_7x3:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
  },
  links: [
    {
      href: 'https://caladan.tod2-test.beiniz.biz/api/v2/contents/films/wrath-of-man',
      method: 'GET',
      rel: 'self',
    },
  ],
  railType: 'ContentFilmDetail',
  rating: '7.6',
  releaseDate: 2021,

  subtitles: ['English', 'Arabic', 'French'],
  summary:
    "A cold and mysterious new security guard for a Los Angeles cash truck company surprises his co-workers when he unleashes precision skills during a heist. The crew is left wondering who he is and where he came from. Soon, the marksman's ultimate motive becomes clear as he takes dramatic and irrevocable steps to settle a score.",
  title: 'Wrath of Man',
  titleOriginal: 'Wrath of Man',
  urlSlug: 'films/wrath-of-man',
  video: {
    dash: 'https://dash.akamaized.net/dash264/TestCases/1b/qualcomm/1/MultiRatePatched.mpd',
    id: 637649,
    poster:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    url: 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
  },
};

const source = {
  dash: 'https://bein-tod-live.akamaized.net/Content/DASH_DASH/Live/channel(Digiturktest)/manifest.mpd',
  hls: 'https://bein-tod-live.akamaized.net/Content/HLS_HLSv4/Live/channel(Digiturktest)/index.m3u8',
};

/**
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Live = () => {
  const refPlayer = useRef();
  const activityTimeout = useRef();
  const currentFocus = getCurrentFocusKey();

  const { goBack } = useCustomNavigation(null);

  const [audioTracks, setAudioTracks] = useState(null);
  const [showStatistics, setShowStatistics] = useState(false);
  const [playerCurrentTime, setPlayerCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isTrackLive, setIsTrackLive] = useState(true);
  const [userActivity, setUserActivity] = useState(true);

  const destroyAndGoBack = useCallback(() => {
    goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFocusCustom('progress-bar');
  }, []);

  useEffect(() => {
    !userActivity && setFocusCustom('hidden-focus-placeholder');
  }, [userActivity]);

  useHardwareBackPress(() => {
    destroyAndGoBack();
  }, true);

  useFocusEffect(
    useCallback(() => {
      // eslint-disable-next-line require-jsdoc
      const action = (evt) => {
        if (evt.keyCode === 8) {
          destroyAndGoBack();
        }
      };

      isWeb && window.addEventListener('keydown', action);

      return () => isWeb && window.removeEventListener('keydown', action);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      // eslint-disable-next-line require-jsdoc
      const action = (evt) => {
        if (evt.keyCode === 8) {
          destroyAndGoBack();
        }
      };

      isWeb && window.addEventListener('keydown', action);

      return () => isWeb && window.removeEventListener('keydown', action);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      // Temp condition to prevent activity timeout when statistics screen is open
      if (!showStatistics) {
        activityTimeout.current = setTimeout(() => {
          if (!isLongPressActive) {
            setUserActivity(false);
          }
        }, ACTIVITY_TIMEOUT);

        if (currentFocus !== 'hidden-focus-placeholder') {
          setUserActivity(true);
          clearTimeout(activityTimeout);
        }
      }

      return () => {
        if (!isLongPressActive) {
          clearTimeout(activityTimeout.current);
          setUserActivity(false);
        }
      };
    }, [currentFocus, showStatistics])
  );

  /**
   * On load handler player - set player, audio,total time and subtitle tracks
   *
   * @param {object} player - player
   */
  const onLoadHandler = useCallback(
    async (_player) => {
      if (!_player) return;

      refPlayer.current = _player;

      setAudioTrack(_player);
      await _player.timeShift(0);
      !_player.isPlaying() && _player.play();
    },
    [setAudioTrack]
  );

  /**
   * Set audio track - For web and native loads different audio tracks
   *
   * @param {object} _player - player
   */
  const setAudioTrack = useCallback((_player) => {
    if (!isWeb) {
      (async () => {
        const availableAudioTracks = await _player.getAvailableAudioTracks();

        setAudioTracks(availableAudioTracks);
      })();
    } else setAudioTracks(_player.getAvailableAudio());
  }, []);

  /**
   * On current time update handler - callback player current time calls from atoms/player
   *
   * @param {object} TimeChangedEvent - time changed event
   */
  const onCurrentTimeUpdateHandler = useCallback(async () => {
    const ct = await refPlayer.current.getTimeShift();
    const duration = await refPlayer.current.getMaxTimeShift();

    setIsPaused(false);
    setTotalTime(-duration);
    setPlayerCurrentTime(Math.floor(ct - duration));

    if (ct < 0) setIsTrackLive(false);
    else setIsTrackLive(true);
  }, []);

  const playPauseToggle = useCallback(() => {
    if (!isPaused) refPlayer.current.pause();
    else refPlayer.current.play();
  }, [isPaused]);

  const onEnterPressHandlerHiddenFocusPlaceholder = useCallback(() => {
    setFocusCustom('progress-bar');
    setUserActivity(!userActivity);
    setIsPaused(!isPaused);
    playPauseToggle();
  }, [isPaused, userActivity, playPauseToggle]);

  const setShowStatisticsCallback = useCallback(() => {
    setShowStatistics(!showStatistics);
  }, [showStatistics]);

  /**
   * On enter press handler for Progress bar
   * - play pause callback
   * - set focus to progress bar
   *
   * @returns {void}
   */
  const onEnterPressHandlerProgressBar = useCallback(() => {
    setIsPaused(!isPaused);
    playPauseToggle();
  }, [isPaused, playPauseToggle]);

  const activityHandlerWithFocus = useCallback(() => {
    setUserActivity(true);

    return 'progress-bar';
  }, []);

  return (
    <View style={styles.container}>
      <View>
        {!showStatistics && (
          <>
            <PlayerWidget
              onLoad={onLoadHandler}
              onCurrentTimeUpdate={onCurrentTimeUpdateHandler}
              refPlayer={refPlayer}
              isTrailer={false}
              content={sampleContent}
              source={source}
              width={windowWidth}
              height={windowHeight}
              showStatistics={showStatistics}
            />

            <HiddenFocusPlaceHolder
              focusKey={'hidden-focus-placeholder'}
              upFocusKey={activityHandlerWithFocus}
              downFocusKey={activityHandlerWithFocus}
              rightFocusKey={activityHandlerWithFocus}
              leftFocusKey={activityHandlerWithFocus}
              onEnterPress={onEnterPressHandlerHiddenFocusPlaceholder}
            />

            <LivePlayerMenuProperties
              videoType={VIDEO_MODES.LIVE}
              currentTime={playerCurrentTime}
              contentVod={sampleContent}
              isPaused={isPaused}
              refPlayer={refPlayer}
              userActivity={userActivity}
              totalTime={totalTime}
              audioList={audioTracks}
              isTrackLive={isTrackLive}
              showStatistics={showStatistics}
              onEnterPress={onEnterPressHandlerProgressBar}
              setShowStatisticsCallback={setShowStatisticsCallback}
            />
          </>
        )}
      </View>
      {showStatistics && (
        <View style={styles.statisticsScreen}>
          <SportsStatistics
            setShowStatisticsCallback={setShowStatisticsCallback}
            playerComponent={
              <>
                <PlayerWidget
                  onLoad={onLoadHandler}
                  refPlayer={refPlayer}
                  isTrailer={false}
                  content={sampleContent}
                  source={source}
                  width={1040}
                  height={585}
                  showStatistics={showStatistics}
                />
                <HiddenFocusPlaceHolder
                  focusKey={'hidden-focus-placeholder'}
                  upFocusKey="Commentary"
                />
              </>
            }
          />
        </View>
      )}
    </View>
  );
};

export default Live;
