import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  container: {
    height: GetScaledValue(1080),
    width: GetScaledValue(1920),
    justifyContent: 'space-between',
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: GetScaledValue(40),
    paddingTop: GetScaledValue(60),
    paddingEnd: GetScaledValue(80),
    paddingStart: GetScaledValue(80),
    zIndex: 1,
  },
  bottomContainer: {
    paddingBottom: GetScaledValue(80),
    paddingEnd: GetScaledValue(80),
    paddingStart: GetScaledValue(80),
  },
  topRightContainer: {
    flexDirection: 'row',
    gap: GetScaledValue(20),
  },
  backButton: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  playerMenuContainer: {
    position: 'absolute',
    bottom: GetScaledValue(80),
    left: GetScaledValue(80),
    right: GetScaledValue(80),
    zIndex: 1,
  },
  playerMenuProps: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  controllerButtonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'flex-end',
    gap: GetScaledValue(10),
  },
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    height: GetScaledValue(1080),
  },
  center: {
    position: 'absolute',
    top: GetScaledValue(480),
    left: GetScaledValue(900),
    zIndex: 1,
  },
  pauseButton: {
    opacity: 0.56,
  },

  fourkbutton: {
    gap: GetScaledValue(16),
  },
  arrowIcon: {
    width: 30,
    height: 30,
    color: colors.shades00,
  },
});

export default styles;
