import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { themeConfig } from '../buttons/configs';

/**
 * InfoBubble styles
 *
 * @param {boolean} focused - focused
 * @param {string} theme - theme
 * @param {string} themeFocused - themeFocused
 * @returns {object} - styles
 */
const style = (focused, theme, themeFocused) => {
  return StyleSheet.create({
    statisticItem: {
      backgroundColor: focused
        ? themeConfig[themeFocused].background
        : themeConfig[theme].background,
      width: GetScaledValue(720),
      height: GetScaledValue(197),
      borderRadius: GetScaledValue(12),
      marginBottom: GetScaledValue(20),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: GetScaledValue(40),
    },
    text: {
      color: focused ? themeConfig[themeFocused].text : themeConfig[theme].text,
    },
    imageArea: {
      width: GetScaledValue(280),
      height: GetScaledValue(158),
    },
  });
};

export default style;
