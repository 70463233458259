/**
 * isEmpty Method
 *
 * @param {object} value - value
 * @returns {boolean}  isEmpty - isEmpty
 */
export const isEmpty = (value) => {
  if (value === undefined) {
    return true;
  }

  if (
    typeof value === 'function' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    Object.prototype.toString.call(value) === '[object Date]'
  ) {
    return false;
  }

  if (value == null || (Array.isArray(value) && value.length === 0)) {
    return true;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
};
