import { setStorage } from '../storage';
import storageKeys from '../storageKeys';
import TYPES from './types';

const actions = {
  init: () => ({
    type: TYPES.INIT,
  }),

  setAuth: (authState) => {
    setStorage(storageKeys.auth, authState);

    return {
      type: TYPES.SET_AUTH,
      payload: authState,
    };
  },
};

export default actions;
