import React, { useCallback } from 'react';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES, SectionTypes } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import { StyleSheet, View } from 'react-native';
import AvatarList from '../../organisms/AvatarList';
import useLocalization from '../../../libs/localization/useLocalization';
import LinearGradient from 'react-native-linear-gradient';
import Language from './language';
import { translate } from '@digiturk/i18n';

/**
 * Avatar Image Profile
 *
 * @param {object} props -
 * @param {boolean} props.prefix -
 * @param {boolean} props.isEdit -
 * @param {Function} props.onSelectHandler -
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Avatar = ({ prefix, isEdit, onSelectHandler }) => {
  const { isRTL } = useLocalization();

  const onEnterPres = useCallback((iconPath) => {
    onSelectHandler(SectionTypes.Main, { iconPath });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <Text
        text={
          isEdit
            ? translate(Language.EDIT_PROFILE_AVATAR)
            : translate(Language.ADD_NEW_PROFILE_AVATAR)
        }
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.DISPLAYSMALL}
        color={colors.shades00}
      />
      <View>
        <AvatarList onEnterPres={onEnterPres} />
        <LinearGradient
          start={{ x: isRTL ? 0 : 1, y: 0 }}
          end={{ x: isRTL ? 1 : 0, y: 0 }}
          colors={[colors.shades100, colors.transparent]}
          style={styles.gradient}
        />
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          colors={[colors.shades100, colors.transparent]}
          style={styles.gradientTop}
        />
      </View>
    </View>
  );
};

export default Avatar;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  gradient: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    end: 0,
    zIndex: 99,
    width: '5%',
  },
  gradientTop: {
    position: 'absolute',
    top: 0,
    start: 0,
    end: 0,
    zIndex: 99,
    height: '15%',
  },
});
