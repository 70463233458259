import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import styles from './styles';
import ProgressBar from '../../molecules/ProgressBar';
import Text from '../../atoms/Text';
import colors from '../../../helpers/Colors';
import { translate } from '@digiturk/i18n';
import { Languages } from './language';

import timeKeeper from '../../../libs/timeKeeper';
import { withFocusable } from '@digiturk/react-spatial-navigation';
// import { BasicButton } from '../buttons';

/**
 * Player ProgressBar with TimeLine
 *
 * @param {object} props - props
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {boolean} props.focused - focused
 * @param {boolean} props.isPlayerReady - isPlayerReady
 * @param {boolean} props.isTrackLive - isTrackLive
 * @returns {module:JSX.Element} - JSX.Element
 */
const LivePlayerProgressBar = ({
  totalTime,
  currentTime,
  focused,
  isPlayerReady,
  isTrackLive,
}) => {
  const offsetForStartFeed = -totalTime;

  // const [nowTime, setNowTime] = useState(timeKeeper().hourAndMin);
  const [startingTime, setStartingTime] = useState(
    timeKeeper(null, offsetForStartFeed).hourAndMin
  );

  const oneSecondTimeInterval = useRef(null);

  useEffect(() => {
    oneSecondTimeInterval.current = setInterval(() => {
      // setNowTime(timeKeeper().hourAndMin);
      setStartingTime(timeKeeper(null, offsetForStartFeed).hourAndMin);
    }, 1000);

    return () => {
      clearInterval(oneSecondTimeInterval.current);
    };
  }, [offsetForStartFeed]);

  /**
   * Computes the progress and returns
   *
   * @returns {number} - progress
   */
  const progressCompute = () => {
    if (totalTime === 0) {
      return 0;
    }

    const progress = (currentTime / totalTime) * 100;
    const defaultProgress = 100;

    return isNaN(progress) ? defaultProgress : progress;
  };

  /**
   * counter text handler
   *
   * @returns {string|null} - counterText
   */
  const counterTextHandler = () => {
    const time = timeKeeper(null, currentTime + offsetForStartFeed).hourAndMin;

    return time;
  };

  return (
    <View style={styles.mainContainer}>
      <Text text={startingTime} style={styles.leftText} />
      <View style={styles.progressBar}>
        <ProgressBar
          focused={focused}
          point={isPlayerReady}
          progress={progressCompute()}
          color={colors.primary500}
          backgroundColor={colors.gray200}
          height={8}
          borderRadius={20}
          counterText={counterTextHandler()}
        />
      </View>
      {isTrackLive && (
        <View style={styles.liveReturn}>
          <View style={styles.liveButton}>
            <View style={styles.redDot} />
            <Text text={translate(Languages.LIVE)} style={styles.liveText} />
          </View>
        </View>
      )}
    </View>
  );
};

export default withFocusable()(LivePlayerProgressBar);
