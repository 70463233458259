import TYPES from './types';

import STORAGE_KEYS from '../storageKeys';
import { setStorage } from '../storage';

const actions = {
  init: () => ({
    type: TYPES.INIT,
  }),

  setLanguageDirection: (payload) => {
    setStorage(STORAGE_KEYS.languageDirection, payload);

    return {
      type: TYPES.SET_LANGUAGE_DIRECTION,
      payload,
    };
  },
};

export default actions;
