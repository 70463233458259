import { lazy } from 'react';

const BasicPoster = lazy(() => import('../../atoms/Posters/Basic'));
const TopCategoryTenInLocal = lazy(() => import('../TopCategoryTenInLocal'));
const TopTenInLocal = lazy(() => import('../TopTenInLocal'));
const TopTenInRegion = lazy(() => import('../TopTenInRegion'));
const PosterLive = lazy(() => import('../PosterLive'));
const PosterPlayerStatus = lazy(() => import('../PosterPlayerStatus'));
const LiveAndUpcoming = lazy(() => import('../LiveAndUpcoming'));
const PosterMatches = lazy(() => import('../PosterMatches'));
const LiveFootball = lazy(() => import('../LiveFootball'));
const PosterShowcase = lazy(() => import('../PosterShowcase'));
const CommonButtonItem = lazy(() => import('../CommonButtonItem'));
const EpisodeItem = lazy(() => import('../EpisodeItem'));
const GroupCard = lazy(() => import('../GroupCard'));

const posterComponentsMap = {
  default: BasicPoster,
  TopTenMoviesByGenreWithRegion: TopCategoryTenInLocal,
  TopTenMoviesByCountryForToday: TopTenInLocal,
  TopTenMoviesByRegionForToday: TopTenInRegion,
  WhatsOnNow: PosterLive,
  ContinueWatching: PosterPlayerStatus,
  LiveAndUpcoming: LiveAndUpcoming,
  Tournaments: PosterMatches,
  Football: LiveFootball,
  Showcase: PosterShowcase,
  tod360: LiveFootball,
  season: CommonButtonItem,
  episode: EpisodeItem,
  selectedButton: CommonButtonItem,
  GroupCard: GroupCard,
};

export default posterComponentsMap;
