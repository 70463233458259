import { GetWindowWidth, GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  heroContainer: {
    width: GetScaledValue(1920),
    height: GetScaledValue(1080),
    backgroundColor: colors.shades100,
  },
  imageContainer: {
    flex: 1,
  },
  image: {
    width: GetScaledValue(2321),
    height: GetScaledValue(1702),
    position: 'absolute',
    left: GetScaledValue(-200),
    opacity: 0.5,
    backgroundColor: colors.shades100,
  },
  gradient: {
    width: GetScaledValue(1920),
    height: GetScaledValue(630),
    position: 'absolute',
  },
  container: {
    width: GetWindowWidth(1920),
    alignItems: 'center',
    position: 'absolute',
    gap: GetScaledValue(55),
  },
  topContainer: {
    alignSelf: 'flex-start',
    paddingTop: GetScaledValue(60),
    paddingRight: GetScaledValue(80),
    paddingLeft: GetScaledValue(80),
  },
  bottomContainer: {
    width: GetScaledValue(1760),
    height: GetScaledValue(756),
    gap: GetScaledValue(80),
  },
  upgradeText: {
    gap: GetScaledValue(20),
    display: 'flex',
    alignItems: 'center',
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: GetScaledValue(40),
  },
  card: {
    textAlign: 'center',
    width: GetScaledValue(818),
    height: GetScaledValue(536),
    padding: GetScaledValue(40),
    borderRadius: GetScaledValue(20),
    backgroundColor: colors.blackTransparent64,
    backdropFilter: 'blur(3px)',
    borderWidth: 2,
    borderColor: colors.whiteTransparent16,
    gap: GetScaledValue(140),
  },
  card2: {
    gap: GetScaledValue(40),
    paddingStart: GetScaledValue(80),
    paddingEnd: GetScaledValue(80),
  },
  qrImage: {
    width: GetScaledValue(320),
    height: GetScaledValue(320),
    alignSelf: 'center',
  },
  alignText: {
    textAlign: 'center',
  },
  alignMid: {
    alignSelf: 'center',
  },
  backButton: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    backgroundColor: colors.transparent,
  },
});

export default styles;
