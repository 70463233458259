import React, { memo, useCallback, useEffect } from 'react';

import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import useLocalization from '../../../libs/localization/useLocalization';

import Menu from '../../organisms/Menu';

import { getMenuRoutes } from '../../../libs/menuRoutes';

import PropList from './proptypes';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { trailer } from '../../../helpers/CommonHelper';
import NavigationRoutes from '../../../navigation/NavigationRoutes';

/**
 * Sidebar
 *
 * @param {object} props - props
 * @param {boolean} props.hasFocusedChild - hasFocusedChild
 * @param {string} props.rightFocusKey - rightFocusKey
 * @param {Function} props.onNavigateBack - onNavigateBack
 * @returns {module:JSX.Element} - JSX.Element
 */
const Sidebar = ({ hasFocusedChild, rightFocusKey, onNavigateBack }) => {
  const player = trailer.get();
  const { replaceNavigate, goBack } = useCustomNavigation();
  const { isRTL, setLocalization } = useLocalization();

  const isSidebarOpened = hasFocusedChild;

  useHardwareBackPress(() => {
    if (onNavigateBack) {
      onNavigateBack();
    } else {
      goBack();
    }
  }, true);

  useEffect(() => {
    try {
      player && isSidebarOpened ? player?.pause() : player?.play();
    } catch (error) {
      console.info(error);
    }
  }, [player, isSidebarOpened]);

  /**
   * onEnterPress method
   *
   * @param {object} selected - selected
   */
  const onEnterPress = useCallback(
    async (selected) => {
      if (selected?.id === 'LANGUAGE') {
        player?.destroy();
        setLocalization(!isRTL);
      }

      if (selected?.id === 'PROFILES') {
        replaceNavigate(NavigationRoutes.profiles, { url: selected.url });

        return;
      }

      const menuRoute = getMenuRoutes(selected?.menu);
      const currentRoute = menuRoute?.route;

      if (currentRoute) {
        const currentParams = menuRoute?.params || {};

        replaceNavigate(currentRoute, {
          id: selected?.menu,
          menuId: selected?.menu,
          date: new Date().getTime(),
          url: selected.url,
          ...currentParams,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRTL, player]
  );

  return (
    <Menu
      isSidebarOpened={isSidebarOpened}
      onEnterPress={onEnterPress}
      rightFocusKey={rightFocusKey}
    />
  );
};

Sidebar.propTypes = PropList;
Sidebar.whyDidYouRender = false;

export default memo(
  withFocusable({
    trackChildren: true,
    forgetLastFocusedChild: false,
    autoRestoreFocus: false,
  })(Sidebar)
);
