import { View } from 'react-native';
import React from 'react';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

/**
 *
 * @param {object} props -
 * @param {object} props.containerStyle -
 * @param {any} props.icon -
 * @param {object} props.iconStyle -
 * @param {string} props.text -
 * @param {object} props.textStyle -
 * @returns {module:JSX.Element} -
 */
const IconWithText = ({ containerStyle, icon, iconStyle, text, textStyle }) => {
  return (
    <View style={containerStyle}>
      <Icon
        icon={icon}
        width={iconStyle.width}
        height={iconStyle.height}
        color={iconStyle.color}
      />
      <Text
        text={text}
        color={textStyle.color}
        size={textStyle.size}
        fontFamily={textStyle.fontFamily}
      />
    </View>
  );
};

export default IconWithText;
