import { StyleSheet, View } from 'react-native';
import React, { useCallback, useState } from 'react';
import { GetScaledValue } from '@digiturk/screen-size';
import {
  FONT_FAMILY,
  FONT_SIZES,
  ProfileTypes,
  SectionTypes,
} from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import Numpad from '../../organisms/Numpad';
import { useFocusEffect } from '@react-navigation/native';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import PINCode from '../../molecules/PINCode';

/**
 *
 * @param {object} props -
 * @param {boolean} props.isEdit -
 * @param {Function} props.onSelectHandler -
 * @returns {module:JSX.Element} -
 */
const EditPincode = ({ isEdit, onSelectHandler }) => {
  const digitCount = 4;
  const [pin, setPin] = useState('');

  useFocusEffect(
    useCallback(() => {
      setFocusCustom('edit-numpad-0');
    }, [])
  );

  const onDonePress = useCallback(() => {
    if (pin.length === digitCount) {
      onSelectHandler(SectionTypes.Main, {
        pinCode: pin,
        profileType: ProfileTypes.RESTRICTED,
      });
    }
  }, [onSelectHandler, pin]);

  return (
    <View style={styles.container}>
      <Text
        text={`${isEdit ? 'Edit' : 'Add'} PIN Code`}
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.DISPLAYSMALL}
        color={colors.shades00}
      />
      <View style={styles.innerContainer}>
        <Numpad
          value={pin}
          limit={digitCount}
          setValue={setPin}
          prefix={'edit-numpad'}
          onDonePress={onDonePress}
          containerStyle={styles.numpadContainer}
        />
        <PINCode
          pin={pin}
          digitCount={digitCount}
          hasTitle={'PIN Code'}
          containerStyle={styles.digitContainer}
        />
      </View>
    </View>
  );
};

export default EditPincode;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: GetScaledValue(60),
  },
  numpadContainer: {
    width: GetScaledValue(380),
  },
  digitContainer: {
    flex: 1,
    alignItems: 'center',
    gap: GetScaledValue(20),
  },
});
