import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';
import {
  GetScaledValue,
  GetWindowHeight,
  GetWindowWidth,
} from '@digiturk/screen-size';

const styles = StyleSheet.create({
  view: {
    position: 'absolute',
    height: GetWindowHeight(),
    width: GetWindowWidth(),
  },
  error: {
    position: 'absolute',
    zIndex: 99,
    justifyContent: 'flex-end',
    width: GetWindowWidth(),
    height: GetWindowHeight(),
    backgroundColor: colors.black,
  },
  statisticsView: {
    position: 'relative',
    height: GetScaledValue(585),
    width: GetScaledValue(1040),
  },
});

export default styles;
