import axios from 'axios';
import { convertVttToJson } from './vtt-convertor';

/**
 *
 * @param {*} subtitleUrl - subtitleUrl
 * @returns {*} result -result
 */
const subtitleParser = async (subtitleUrl) => {
  const result = [];

  if (subtitleUrl) {
    const { data: subtitleData } = await axios.get(subtitleUrl);

    const subtitleType =
      subtitleUrl.split('.')[subtitleUrl.split('.').length - 1];

    /**
     * TODO: Add support for srt
     */
    /*if (subtitleType === 'srt') {
    
        const parser: {
          fromSrt: (data: any) => srtParserSubtitle[]
        } = new srtParser2()
    
        const parsedSubtitle: srtParserSubtitle[] = parser.fromSrt(subtitleData)
    
        parsedSubtitle.forEach(({ startTime, endTime, text }) => {
          result.push({
            start: timeToSeconds(startTime.split(',')[0]),
            end: timeToSeconds(endTime.split(',')[0]),
            part: text,
          })
        })
      } */

    if (subtitleType === 'vtt') {
      const parsedSubtitle = await convertVttToJson(subtitleData);

      parsedSubtitle.forEach(({ start, end, part }) => {
        // For some reason this library adds the index of the subtitle at the end of the part, so we cut it
        result.push({
          start: start / 1000,
          end: end / 1000,
          part: part.slice(
            0,
            part.length - part.split(' ')[part.split(' ').length - 1].length
          ),
        });
      });
    }
  }

  return result;
};

export default subtitleParser;
