import { AppApis } from '../services';

/**
 * Returns menu contents from service
 *
 * @returns {object} - menu data
 */
export const getMenus = async () => {
  try {
    const response = await AppApis.MenuApis();

    return response.data.data;
  } catch (error) {
    console.error('ERROR', error);
  }

  return null;
};
