import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

const styles = StyleSheet.create({
  container: {
    height: GetScaledValue(504),
    width: GetScaledValue(378),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: GetScaledValue(4),
  },
  buttonStyle: {
    width: GetScaledValue(378),
    height: GetScaledValue(80),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: GetScaledValue(20),
    borderRadius: GetScaledValue(12),
  },
  item: {
    height: GetScaledValue(120),
    width: GetScaledValue(120),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: GetScaledValue(17),
  },
  textSize: {
    fontSize: GetScaledValue(40),
    lineHeight: GetScaledValue(60),
  },
});

export { styles };
