import React from 'react';
import { View } from 'react-native';

import style from './styles';
import PropList from './proptypes';
import Text from '../../atoms/Text';
import colors from '../../../helpers/Colors';
import { FONT_SIZES } from '../../../helpers/Enums';
/**
 * ProgressBar for player
 *
 * @param {object} props - props
 * @param {boolean} props.focused - focused
 * @param {number} props.bufferProgress - bufferProgress
 * @param {number} props.progress - progress
 * @param {string} props.color - color
 * @param {string} props.backgroundColor - backgroundColor
 * @param {number} props.height - height
 * @param {number} props.borderRadius - borderRadius
 * @param {string} props.counterText - counterText
 * @param {boolean} props.point - point
 * @param {boolean} props.isRTL - is RTL
 * @returns {module:JSX.Element} - JSX.Element
 */
const ProgressBar = ({
  focused,
  bufferProgress,
  progress,
  color,
  backgroundColor,
  height,
  borderRadius,
  counterText = null,
  point = true,
  isRTL = false,
}) => {
  const styles = style(
    bufferProgress,
    progress,
    color,
    backgroundColor,
    height,
    borderRadius,
    isRTL
  );

  return (
    <View style={styles.floor}>
      <View style={styles.fill} />
      {focused && (
        <View style={styles.controller}>
          {counterText && (
            <View style={styles.thumbnailView}>
              <View style={styles.text}>
                <Text
                  text={counterText}
                  color={colors.shades00}
                  align={'center'}
                  numberOfLines={1}
                  size={FONT_SIZES.HEADING5}
                  bold={true}
                />
              </View>
            </View>
          )}
          {point && <View style={styles.point} />}
        </View>
      )}
      <View style={styles.buffer} />
      <View style={styles.space} />
    </View>
  );
};

ProgressBar.propTypes = PropList;

export default React.memo(ProgressBar);
