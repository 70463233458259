import React, { useEffect, useRef, useCallback, useState } from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { GetScaledValue } from '@digiturk/screen-size';
import Item from './Item';
import useLocalization from '../../../libs/localization/useLocalization';
import { calculateForRTL, getLink, isWeb } from '../../../helpers/CommonHelper';
import colors from '../../../helpers/Colors';
import { ApiRels, FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import Text from '../../atoms/Text';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { useProfile } from '../../../context';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * EditAvatar component
 *
 * @param {object} props - props
 * @param {string} props.focusKeyPrefix - focusKeyPrefix
 * @param {Function} props.onEnterPres - onEnterPres
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const EditAvatar = ({ focusKeyPrefix = 'editAvatar', onEnterPres }) => {
  const horizontalListRefs = useRef([]);
  const verticalListRef = useRef(null);

  const { isRTL } = useLocalization();
  const { profileState } = useProfile();

  const [avatars, setAvatars] = useState([]);

  useEffect(() => {
    (async () => {
      const { url, method } = getLink(
        profileState,
        ApiRels.PROFILE.GET_AVATARS
      );
      const response = await makeApiCall({ url, method });

      if (response) {
        setAvatars(response?.avatars);
        setFocusCustom(`${focusKeyPrefix}-0-0`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBecameFocused = useCallback(
    (_coordinate, sectionIndex, itemIndex) => {
      const horizontalListRef = horizontalListRefs.current[sectionIndex];

      verticalListRef.current?.scrollToIndex({
        index: sectionIndex,
        animated: true,
      });

      _coordinate.node?.measure((fx, fy, width, height, px, py) => {
        const offset = width * itemIndex;

        horizontalListRef?.scrollToOffset({
          offset: isWeb && isRTL ? calculateForRTL(offset) : offset,
          viewPosition: 0,
          animated: true,
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnEnterPress = useCallback((iconPath) => {
    onEnterPres(iconPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFocusKeys = useCallback(
    (sectionIndex, itemIndex, itemCount) => ({
      leftFocusKey:
        itemIndex === 0
          ? null
          : `${focusKeyPrefix}-${sectionIndex}-${itemIndex - 1}`,
      rightFocusKey:
        itemIndex === itemCount - 1
          ? null
          : `${focusKeyPrefix}-${sectionIndex}-${itemIndex + 1}`,
      upFocusKey:
        sectionIndex === 0 ? null : `${focusKeyPrefix}-${sectionIndex - 1}-0`,
      downFocusKey:
        sectionIndex === avatars?.length - 1
          ? null
          : `${focusKeyPrefix}-${sectionIndex + 1}-0`,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [avatars?.length, focusKeyPrefix]
  );

  const Skeleteon = useCallback(() => {
    return (
      <View style={styles.skeletonContainer}>
        {new Array(5).fill(0).map((_, index) => (
          <ContentLoader
            key={index}
            speed={1}
            style={styles.skeleton}
            backgroundColor={colors.neutral700}
            foregroundColor={colors.neutral500}>
            <Rect
              x="0"
              y="0"
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(160)}
              height={GetScaledValue(160)}
            />
          </ContentLoader>
        ))}
      </View>
    );
  }, []);

  const renderSection = useCallback(
    ({ item, index: sectionIndex }) => {
      return (
        <View style={styles.sectionContainer}>
          <Text
            text={item.groupName}
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING3}
            color={colors.shades00}
            style={styles.sectionTitle}
          />
          <FlatList
            ref={(ref) => (horizontalListRefs.current[sectionIndex] = ref)}
            data={item.paths}
            horizontal={true}
            renderItem={({ item: listItem, index }) =>
              renderItem({
                listItem,
                index,
                sectionIndex,
                pathLength: item.paths.length,
              })
            }
            keyExtractor={(_, index) => index.toString()}
            showsHorizontalScrollIndicator={false}
            scrollEnabled={false}
            inverted={false}
          />
        </View>
      );
    },
    [renderItem]
  );

  const renderItem = useCallback(
    ({ listItem, index, sectionIndex, pathLength }) => {
      const focusKeys = getFocusKeys(sectionIndex, index, pathLength);

      return (
        <Item
          item={listItem}
          focusKey={`${focusKeyPrefix}-${sectionIndex}-${index}`}
          onBecameFocused={(coordinate) =>
            onBecameFocused(coordinate, sectionIndex, index)
          }
          onEnterPress={() => handleOnEnterPress(listItem)}
          rightFocusKey={focusKeys.rightFocusKey}
          leftFocusKey={focusKeys.leftFocusKey}
          downFocusKey={focusKeys.downFocusKey}
          upFocusKey={focusKeys.upFocusKey}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <FlatList
      ref={verticalListRef}
      data={avatars}
      renderItem={renderSection}
      keyExtractor={(_, index) => index.toString()}
      style={styles.container}
      scrollEnabled={false}
      inverted={false}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.contentContainerStyle}
      ListEmptyComponent={Skeleteon}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: GetScaledValue(1120),
    height: GetScaledValue(918),
    marginTop: GetScaledValue(40),
  },
  contentContainerStyle: {
    paddingBottom: GetScaledValue(110),
  },
  sectionContainer: {
    marginBottom: GetScaledValue(22),
  },
  sectionTitle: {
    marginStart: GetScaledValue(8),
  },
  skeletonContainer: {
    flexDirection: 'row',
    gap: GetScaledValue(20),
  },
  skeleton: {
    width: GetScaledValue(160),
    height: GetScaledValue(160),
  },
});

export default EditAvatar;
