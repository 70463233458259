import PropTypes from 'prop-types';

export const IndexPropTypes = {
  railType: PropTypes.string,
  railName: PropTypes.string,
  onEnterPress: PropTypes.func,
  focusKeyPrefix: PropTypes.string,
  rightFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leftFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  upFocusKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  downFocusKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
};

export const ItemPropTypes = {
  item: PropTypes.object,
  railType: PropTypes.string,
  focused: PropTypes.bool,
  posterWidth: PropTypes.number,
  posterHeight: PropTypes.number,
};
