/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { withFocusable } from '@digiturk/react-spatial-navigation';
import { indexStyles as styles } from './styles';
import Item from './item';
import { KeyboardData } from './data';
import { BasicButton, buttonThemes } from '../../molecules/buttons';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { useNavigation } from '@react-navigation/native';

/**
 * Keyboard component.
 *
 * @param {object} props - Component props.
 * @param {string} props.prefix - Prefix.
 * @param {object} props.containerStyle - Container style.
 * @param {Function} props.onDonePress - On done press callback.
 * @param {string} props.rightEdgeFocusKey - Right edge focus key.
 * @param {string} props.leftEdgeFocusKey - Left edge focus key.
 * @param {string} props.downEdgeFocusKey - Down edge focus key.
 * @param {string} props.upEdgeFocusKey - Up edge focus key.
 * @param {string} props.value - value.
 * @param {Function} props.setValue - setValue.
 * @param {number} props.limit - limit.
 * @returns {module:JSX.Element} Keyboard component.
 */
const Keyboard = ({
  prefix,
  containerStyle,
  onDonePress,
  rightEdgeFocusKey,
  leftEdgeFocusKey,
  downEdgeFocusKey = onDonePress ? 'doneButton' : false,
  upEdgeFocusKey,
  value,
  setValue,
  limit,
}) => {
  const [caps, setCaps] = useState(false);
  const [longPress, setLongPress] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState({
    lang: 'en',
    symbols: false,
  });
  const navigation = useNavigation();

  const onBecameFocused = useCallback((_item, selected) => {
    setCurrentIndex(selected.index);
    setLongPress(false);
  }, []);

  const keys =
    KeyboardData(prefix)[
      selectedLanguage.symbols ? 'symbols' : selectedLanguage.lang
    ].keys;
  const keyIconsAndStyles =
    KeyboardData(prefix)[
      selectedLanguage.symbols ? 'symbols' : selectedLanguage.lang
    ].keyIconsAndStyles;

  const onLongSelect = useCallback(() => {
    if (keys[currentIndex].longPress.length > 0) {
      setLongPress(true);
      setFocusCustom(`longbutton-0`);
    }
  }, [currentIndex, keys]);

  /**
   * Keyboard OnEnterPress method
   *
   * @param {string} key - key
   */
  const onEnterPress = useCallback(
    (key) => {
      switch (key.label) {
        case 'space':
          setValue((prevState) => prevState + ' ');
          break;
        case 'backspace':
          setValue(value.substring(0, value?.length - 1));
          break;
        case 'caps':
          setCaps((prevState) => !prevState);
          break;
        case 'globe':
          navigation.navigate(NavigationRoutes.modalLanguageSelection, {
            selected: selectedLanguage.lang,
            buttonList: ['en', 'ar'],
            setSelectedLanguage: setSelectedLanguage,
            prefix: prefix,
          });
          break;
        case 'symbols':
          value.length < limit &&
            setSelectedLanguage((prevState) => ({
              lang: prevState.lang,
              symbols: !selectedLanguage.symbols,
            }));
          break;
        default:
          const pressedKey = caps ? key.text.toUpperCase() : key.text;

          value.length < limit &&
            setValue((prevState) => prevState + pressedKey);
          break;
      }
    },
    [value, caps, limit, selectedLanguage.lang, selectedLanguage.symbols]
  );

  return (
    <View style={containerStyle}>
      <View style={styles.container}>
        {keys.map((item, idx) => (
          <Item
            item={item}
            index={idx}
            key={idx}
            prefix={prefix}
            caps={caps}
            onLongSelect={onLongSelect}
            longPressFlag={longPress}
            rightEdgeFocusKey={rightEdgeFocusKey}
            leftEdgeFocusKey={leftEdgeFocusKey}
            downEdgeFocusKey={downEdgeFocusKey}
            upEdgeFocusKey={upEdgeFocusKey}
            keyboardIconsAndStyles={keyIconsAndStyles}
            currentIndex={currentIndex}
            onBecameFocused={onBecameFocused}
            onEnterPress={onEnterPress}
            onDonePress={onDonePress}
          />
        ))}
      </View>
      {onDonePress && (
        <BasicButton
          style={styles.buttonStyle}
          theme={buttonThemes.neutral800}
          themeFocused={buttonThemes.primary}
          text={'Done'}
          focusKey="doneButton"
          upFocusKey={prefix}
          downFocusKey={prefix}
          onEnterPress={onDonePress}
        />
      )}
    </View>
  );
};

export default withFocusable({ trackChildren: true })(Keyboard);
