import { GetScaledValue } from '@digiturk/screen-size';
import PropTypes from 'prop-types';

/**
 * propType definitions for player component
 */
export const PlayerPropTypes = {
  onLoad: PropTypes.func,
  onCurrentTimeUpdate: PropTypes.func,
  onBufferHandler: PropTypes.func,
  source: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};

/**
 * default values for width and height
 * for player default props
 */
export const PlayerDefaultProps = {
  width: GetScaledValue(500),
  height: GetScaledValue(500),
};
