import React from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import Icon from '../../atoms/Icon';
import { LiveTVStroke } from '../../../assets/icons';

const devicesData = [
  { id: 1, name: 'LG TV 387224', isPrimary: true },
  { id: 2, name: 'Samsung TV 387225' },
  { id: 3, name: 'Sony TV 387226' },
  { id: 4, name: 'Panasonic TV 387227' },
  { id: 5, name: 'Toshiba TV 387228' },
];

/**
 * Devices Settings Component
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Devices = () => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text
          text={'Devices'}
          size={FONT_SIZES.HEADING3}
          fontFamily={FONT_FAMILY.SEMIBOLD}
        />
      </View>
      <View style={styles.devicesContainer}>
        {devicesData.map((device) => (
          <View key={device.id} style={styles.device}>
            <Icon
              icon={LiveTVStroke}
              width={40}
              height={40}
              color={device.isPrimary ? colors.primary500 : colors.neutral300}
            />
            <Text
              text={device.name}
              size={FONT_SIZES.HEADING5}
              fontFamily={FONT_FAMILY.REGULAR}
              color={colors.neutral300}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

export default Devices;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral800,
    padding: GetScaledValue(40),
    width: GetScaledValue(1299),
    height: GetScaledValue(224),
    borderRadius: GetScaledValue(20),
  },
  header: {
    marginBottom: GetScaledValue(20),
  },
  devicesContainer: {
    flexDirection: 'row',
    gap: GetScaledValue(40),
  },
  device: {
    alignItems: 'center',
    width: GetScaledValue(140),
    height: GetScaledValue(84),
    gap: GetScaledValue(20),
  },
});
