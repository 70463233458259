import MenuOptions from '../../helpers/MenuOptions';

/**
 * Menu Routes
 *
 * @param {string} id - id
 * @returns {object} - object
 */
export const getMenuRoutes = (id) => {
  return MenuOptions.find((item) => item.id === id);
};

/**
 * Menu Routes
 *
 * @param {string} route - id
 * @returns {object} - object
 */
export const getMenuId = (route) => {
  const selectedId = MenuOptions.find((item) => item.route === route);

  return selectedId.id;
};
