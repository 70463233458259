import { StyleSheet, View } from 'react-native';
import React from 'react';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { GetScaledValue } from '@digiturk/screen-size';
import { FONT_SIZES } from '../../../helpers/Enums';

/**
 *
 * @param {object} props - props
 * @param {string} props.text - width
 * @param {any} props.icon - height
 * @param {object} props.iconStyle - animatedStyle
 * @param {object} props.containerStyle - animatedStyle
 * @returns {module:JSX.Element} -
 */
const ArrowWithText = ({ text, icon, iconStyle, containerStyle }) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <Icon
        icon={icon}
        width={iconStyle.width}
        height={iconStyle.height}
        color={iconStyle.color}
      />
      <Text text={text} size={FONT_SIZES.HEADING3} />
    </View>
  );
};

export default ArrowWithText;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: GetScaledValue(20),
  },
});
