const environment = {
  name: 'production',
  SmartTv: {
    Endpoint: '',
    EndpointTest: '',
    EndpointReg: '',
    EndpointDisaster: '',
    ApiGenerateAuthCode:
      'https://caladan.tod2-test.beiniz.biz/api/v3/auth/generate-auth-code',
    ApiLoginWithAuthCode:
      'https://caladan.tod2-test.beiniz.biz/api/v3/auth/login-with-auth-code',
    ApiTest: 'https://caladan.tod2-test.beiniz.biz/',
    ApiRegression: 'https://navigation.tod2-reg.beiniz.biz/',
    ApiLive: 'https://navigation.tod2.beiniz.biz/',
    ApiVersion: '3',
  },
};

export default environment;
