import { StyleSheet, View } from 'react-native';
import React from 'react';
import colors from '../../../helpers/Colors';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Rounded
 *
 * @param {object} props - props
 * @param {string} props.color - color
 * @param {number} props.size - size
 * @returns {module:JSX.Element} -
 */
const Rounded = ({ color = colors.primary500, size = 12 }) => {
  const style = styles(color, size);

  return <View style={[style.container]} />;
};

export default Rounded;

/**
 *
 * @param {string} color  -
 * @param {number} size -
 * @returns {module:StyleSheet} -
 */
const styles = (color, size) =>
  StyleSheet.create({
    container: {
      backgroundColor: color,
      width: GetScaledValue(size),
      aspectRatio: 1,
      borderRadius: GetScaledValue(size * 2),
    },
  });
