import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { FONT_FAMILY } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: GetScaledValue(20),
  },
  progressBar: {
    minWidth: GetScaledValue(1455),
    flexGrow: 1,
  },
  leftText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: GetScaledValue(23),
    fontFamily: FONT_FAMILY.REGULAR,
    color: '#737373',
  },
  liveReturn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  liveButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.transparent,
    paddingVertical: GetScaledValue(8),
    paddingHorizontal: GetScaledValue(20),
    borderRadius: GetScaledValue(30),
    borderWidth: 1,
    borderColor: '#fff',
  },
  redDot: {
    width: GetScaledValue(10),
    height: GetScaledValue(10),
    borderRadius: GetScaledValue(5),
    backgroundColor: 'red',
    marginRight: GetScaledValue(10),
    borderColor: colors.transparent,
  },
  liveText: {
    color: 'white',
    fontSize: GetScaledValue(24),
    textAlign: 'center',
    lineHeight: GetScaledValue(36),
    fontWeight: 400,
  },
});

export default styles;
