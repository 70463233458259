import { GetScaledValue } from '@digiturk/screen-size';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

/**
 * Create react navigation routers
 *
 * @param {any} props - props
 * @param {string} props.label - label
 * @param {number} props.value - value
 * @param {number} props.maxValue - maxValue
 * @returns {module:JSX.Element} - JSX.Element
 */
const SportsProgressBar = ({ label, value, maxValue }) => {
  const percentage = (value / maxValue) * 100;

  return (
    <View style={styles.progressBarContainer}>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.progressBarBackground}>
        <View style={[styles.progressBarFill, { width: `${percentage}%` }]} />
      </View>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  progressBarContainer: {
    marginVertical: GetScaledValue(5),
  },
  label: {
    color: colors.shades00,
    fontSize: 16,
  },
  progressBarBackground: {
    height: GetScaledValue(10),
    backgroundColor: colors.primary500,
    borderRadius: GetScaledValue(5),
    overflow: 'hidden',
    marginVertical: GetScaledValue(5),
  },
  progressBarFill: {
    height: '100%',
    backgroundColor: colors.primary500,
  },
  value: {
    color: colors.shades00,
    fontSize: GetScaledValue(16),
    textAlign: 'right',
  },
});

export default SportsProgressBar;
