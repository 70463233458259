import { StyleSheet } from 'react-native';

import { sizeConfig, themeConfig } from '../configs';
import { FONT_FAMILY } from '../../../../helpers/Enums';

/**
 *
 * @param {string} size - size
 * @param {boolean} focused - focused
 * @param {boolean} circle - circle
 * @param {string} theme - theme
 * @param {string} themeFocused - theme focus
 * @param {any} themeCustom - theme custom
 * @returns {object} - styles
 */
const styles = (
  size = 'medium',
  focused,
  circle = false,
  theme = 'transparent16',
  themeFocused = theme,
  themeCustom
) => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      borderRadius: circle ? 100 : sizeConfig[size].container.borderRadius,
      paddingHorizontal: circle
        ? sizeConfig[size].container.paddingCircle
        : sizeConfig[size].container.paddingHorizontal,
      paddingVertical: circle
        ? sizeConfig[size].container.paddingCircle
        : sizeConfig[size].container.paddingVertical,
      backgroundColor: focused
        ? themeConfig[themeFocused].background
        : themeConfig[theme].background,
    },
    iconArea: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      // TOBE: refactor this field
      width: sizeConfig[size].icon.width,
      height: sizeConfig[size].icon.height,
      color: focused ? themeConfig[themeFocused].icon : themeConfig[theme].icon,
    },
    textArea: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontFamily: FONT_FAMILY.SEMIBOLD,
      fontSize: sizeConfig[size].text.fontSize,
      lineHeight: sizeConfig[size].text.lineHeight,
      color: focused ? themeConfig[themeFocused].text : themeConfig[theme].text,
    },
  });
};

export default styles;
