import { useEffect, useState } from 'react';
import { makeApiCall } from '../middleware/dynamic';
import { getLink } from '../helpers/CommonHelper';
import { ApiRels } from '../helpers/Enums';

/**
 * Get Rail Content
 *
 * @param {object} props - props
 * @param {object} props.item - item
 * @returns {object} - object
 */
const useGetRailContent = ({ item }) => {
  const [railContentData, setRailContentData] = useState(null);

  useEffect(() => {
    (async () => {
      if (item) {
        const { url } = getLink(item, ApiRels.SELF);
        const response = await makeApiCall({ url });

        if (response) setRailContentData(response);
      }
    })();
  }, [item]);

  return { railContentData };
};

export default useGetRailContent;
