import React, { createContext, useContext, useReducer, useMemo } from 'react';

import reducer from './reducer';

const initialState = {
  menuState: {
    isInitiated: false,
    error: null,
    data: null,
  },
};

const Menu = createContext();

/**
 * Context Hook
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const useMenu = () => useContext(Menu);

/**
 * Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const data = useMemo(
    () => ({
      menuState: state.menuState,
      menuDispatch: dispatch,
    }),
    [state.menuState]
  );

  return <Menu.Provider value={data}>{children}</Menu.Provider>;
};
