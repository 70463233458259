import React, { Suspense, memo, useCallback, useMemo } from 'react';
import { View } from 'react-native';

import {
  sidebarWidth,
  railIsCollection,
  marginBottom,
  getImageLink,
  titleHeight,
  getRailItemConfigurations,
} from '../../../helpers/CommonHelper';
import railComponentsMap from '../../molecules/SelectedComponent/Rails';
import useLocalization from '../../../libs/localization/useLocalization';
import styles from './styles';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import useGetRailContent from '../../../hooks/useGetRailContent';
import { filterFocusableComponents } from '../../../helpers/FocusHelper';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Page Horizontal Rail Item
 *
 * @param {object} props - props
 * @param {object} props.item - item
 * @param {number} props.index - index
 * @param {string} props.prefix - prefix
 * @param {string} props.leftFocusKey - leftFocusKey
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.setGlobalFocusedItem - setGlobalFocusedItem
 * @param {number} props.baseRailListLength - baseRailListLength
 * @returns {module:JSX.Element} -
 */
const RailItem = ({
  item,
  index,
  prefix,
  leftFocusKey,
  onEnterPress,
  setGlobalFocusedItem,
  baseRailListLength,
}) => {
  const { isRTL } = useLocalization();

  const { railContentData } = useGetRailContent({ item });
  const { height, position } = getRailItemConfigurations(item?.railType);

  const collectionWallpaper = useMemo(
    () => getImageLink(item, item.railType),
    [item]
  );

  const containerStyle = useMemo(() => {
    const containerHeight =
      GetScaledValue(height) +
      marginBottom +
      titleHeight +
      (position?.top || 0);

    const _cHeight = railIsCollection(item?.railType)
      ? GetScaledValue(height + 720 - 120)
      : containerHeight;

    return {
      height: _cHeight,
      marginBottom: marginBottom,
    };
  }, [height, item.railType, position]);

  const SelectedComponent = useMemo(() => {
    return railIsCollection(item.railType)
      ? railComponentsMap.Collection
      : railComponentsMap[item.railType] || railComponentsMap.Default;
  }, [item.railType]);

  const verticalFocusHandler = useCallback(
    (currentIndex, directionalValue) => {
      if (index === 0 && directionalValue === -1) return;
      if (index === baseRailListLength - 1 && directionalValue === 1) return;

      const nextFocusKey = filterFocusableComponents(
        prefix,
        currentIndex,
        directionalValue
      );

      return nextFocusKey;
    },
    [baseRailListLength, index, prefix]
  );

  if (!railContentData?.items || railContentData.items?.length === 0) {
    return <View style={containerStyle} />;
  }

  return (
    <View style={containerStyle}>
      <Suspense>
        <SelectedComponent
          title={item.title}
          railType={item.railType}
          collectionWallpaper={collectionWallpaper}
          railContentData={railContentData}
          distanceFromEdge={sidebarWidth}
          listStyle={styles.listStyle}
          contentContainerStyle={
            isRTL
              ? styles.contentContainerStyleRTL
              : styles.contentContainerStyle
          }
          onEnterPress={onEnterPress}
          focusKeyPrefix={`${prefix}-${index}`}
          focusKey={`${prefix}-${index}`}
          leftFocusKey={leftFocusKey}
          upFocusKey={() => verticalFocusHandler(index, -1)}
          downFocusKey={() => verticalFocusHandler(index, +1)}
          setGlobalFocusedItem={setGlobalFocusedItem}
          railOffsetStartOfPage={0}
          railOffsetInlineStart={190}
          railWidth={1920}
          railItemGap={20}
        />
      </Suspense>
    </View>
  );
};

export default memo(withFocusable({ trackChildren: true })(RailItem));
