import withFocusable from './withFocusable';
import SpatialNavigation from './spatialNavigation';

const {init: initNavigation, setKeyMap} = SpatialNavigation;

export {
  withFocusable,
  initNavigation,
  setKeyMap
};
