import { View } from 'react-native';
import React from 'react';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import styles from './styles';
import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import colors from '../../../helpers/Colors';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { CheckBold } from '../../../assets/icons';

/**
 * ButtonWithDescription component
 *
 * @param {object} props -
 * @param {object} props.focused -
 * @param {object} props.item -
 * @param {string} props.profileType -
 * @returns {module:JSX.Element}-
 */
const ButtonWithDescription = ({ focused, item, profileType }) => {
  const backgroundColor = focused ? colors.primary500 : colors.neutral800;

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <View style={styles.titleContainer}>
        <Icon
          icon={item.icon}
          color={focused ? colors.shades100 : colors.shades00}
          width={40}
          height={40}
        />
        <Text
          text={item.label}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          size={FONT_SIZES.HEADING4}
          color={focused ? colors.shades100 : colors.shades00}
        />
        {profileType === item.type && (
          <Icon
            icon={CheckBold}
            color={focused ? colors.shades100 : colors.shades00}
            width={24}
            height={24}
          />
        )}
      </View>
      <Text
        text={item.description}
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.HEADING5}
        color={focused ? colors.shades100 : colors.neutral300}
        numberOfLines={2}
        multiline
      />
    </View>
  );
};

export default withFocusable()(ButtonWithDescription);
