import React, { useState, useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import { setFocusCustom } from '../../../helpers/FocusHelper';
import Hero from '../../molecules/SelectedComponent/Hero';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { getLink, sidebarWidth } from '../../../helpers/CommonHelper';
import useFlatlistVerticalAnimation from '../../../hooks/useFlatlistVerticalAnimation';

import Skeleton from './skeleton';
import RailLists from './railList';
import { FullViewPropTypes } from './proptypes';
import styles from './styles';
import useLazyLoadOnFlatlist from '../../../hooks/useLazyLoadOnFlatlist';
import RailTitle from '../../molecules/RailTitle';
import { makeApiCall } from '../../../middleware/dynamic';
import { ApiRels } from '../../../helpers/Enums';

/**
 * Page content component of pages
 *
 * @param {object} props - props
 * @param {string} props.url - url
 * @param {string} props.prefix - prefix
 * @param {string|boolean} props.leftFocusKey - leftFocusKey
 * @returns {module:JSX.Element} - JSX.Element
 */
const FullViewWrapper = ({ url, prefix, leftFocusKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [globalFocusedItem, setGlobalFocusedItem] = useState({});
  const [baseRailList, setBaseRailList] = useState([]);
  const { navigate } = useCustomNavigation();
  const { dynamicRailList, railFocusIndexSetter } = useLazyLoadOnFlatlist(
    baseRailList,
    prefix
  );
  const { railListRef, onScrollToIndexFailedHandler } =
    useFlatlistVerticalAnimation(globalFocusedItem);

  /**
   * Fetch Page Rail List
   */
  useEffect(() => {
    (async () => {
      if (url) {
        const railList = await makeApiCall({ url });

        setBaseRailList(railList.rails);

        // loading finish
        setTimeout(() => setIsLoading(false), 1000);
        setTimeout(() => setFocusCustom(`${prefix}-0`), 1000);
      }
    })();
  }, [url, prefix]);

  /**
   * Rail Item OnEnterPress
   *
   * @param {object} selected - selected item
   */
  const onEnterPress = useCallback((selected) => {
    // TODO: check useCallback usage in here
    const { url: nextLink } = getLink(selected.item, ApiRels.SELF);

    navigate(NavigationRoutes.contentdetail, { url: nextLink });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.contentArea}>
      {isLoading && <Skeleton />}
      <Hero globalFocusedItem={globalFocusedItem} />
      {!isLoading && (
        <RailTitle
          text={'My List'}
          railOffsetInlineStart={sidebarWidth}
          containerStyle={styles.titleContainer}
        />
      )}
      <View style={styles.railContainer}>
        <RailLists
          prefix={prefix}
          railListRef={railListRef}
          content={dynamicRailList}
          leftFocusKey={leftFocusKey}
          onEnterPress={onEnterPress}
          setGlobalFocusedItem={setGlobalFocusedItem}
          railFocusIndexSetter={railFocusIndexSetter}
          onScrollToIndexFailedHandler={onScrollToIndexFailedHandler}
        />
      </View>
    </View>
  );
};

FullViewWrapper.propTypes = FullViewPropTypes;
FullViewWrapper.whyDidYouRender = false;

export default withFocusable()(FullViewWrapper);
