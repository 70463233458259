import React, { useCallback, useEffect, useState } from 'react';
import { View, Image, ImageBackground, FlatList } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import colors from '../../../helpers/Colors';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import api from '../../../middleware/services/api';
import { ArrowLeftBold, Loading } from '../../../assets/icons';
import image from '../../../assets/images/png/FourKBackground/fourkbackground.png';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { makeApiCall } from '../../../middleware/dynamic';

import styles from './styles';
import { ApiRels, FONT_SIZES } from '../../../helpers/Enums';
import { setAuth } from '../../../middleware/services/api';
import { useNavigation } from '@react-navigation/native';
import { getLink } from '../../../helpers/CommonHelper';

import env from '../../../middleware/services/config';

import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { authActions, useAuth } from '../../../context/Auth';

/**
 * SignIn  component
 *
 * @param {Function} handleModal - handleModal
 * @returns {module:JSX.Element} - JSX.Element
 */
const SignIn = ({ handleModal }) => {
  const [loginAuth, setLoginAuth] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [isGenerating, setIsGenerating] = useState(true);
  const [hasError] = useState(false);
  const [pinCodes, setPinCodes] = useState([]);
  const [timer, setTimer] = useState(null);
  let authInterval = null;
  const navigation = useNavigation();

  const { authDispatch } = useAuth();

  useEffect(() => {
    setFocusCustom('renewButton');
  }, []);

  useEffect(() => {
    /*     let authInterval;
    
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://user.tod2-test.beiniz.biz/api/v1/auth/generate-auth-code',
          headers: api.defaults.headers,
        };
    
        axios
          .request(config)
          .then((response) => {
    
            authInterval = setInterval(() => {
                      fetch(
                        'https://user.tod2-test.beiniz.biz/api/auth/login-with-auth-code',
                        {
                          method: 'POST',
                          headers: api.defaults.headers,
                          body: { authCode: { loginAuth: loginAuth.code } },
                          redirect: 'follow',
                        }
                      ).catch((error) => console.error(error));
                    }, 5000); 
          })
          .catch((error) => {
            setIsGenerating(false);
            setHasError(false);
          });
    
        return () => {
          clearInterval(authInterval);
        }; */
  }, []);

  useEffect(() => {
    loginHandler();

    return () => {
      clearInterval(authInterval);
    };
  }, []);

  const loginHandler = useCallback(async () => {
    const generateWithAuthCodeURL = env.SmartTv.ApiGenerateAuthCode;
    const response = await makeApiCall({
      url: generateWithAuthCodeURL,
      method: 'POST',
    });

    const splittedCode = response.code.split('');
    let data = [];

    splittedCode.map((e) => {
      data.push({ value: e });
    });
    setPinCodes(data);
    setIsGenerating(false);
    setLoginAuth(response);
    loginWithAuthCode(response.code);
  }, []);

  const loginWithAuthCode = useCallback(async (authCode) => {
    const loginWithAuthCodeURL = env.SmartTv.ApiLoginWithAuthCode;

    if (authCode) {
      authInterval = setInterval(async () => {
        const response = await makeApiCall({
          url: loginWithAuthCodeURL,
          method: 'POST',
          headers: api.defaults.headers,
          body: { authCode: authCode },
        });

        if (response) {
          setAuth(`Bearer ${response.at}`);
          authDispatch(
            authActions.setAuth({ at: response.at, rt: response.rt })
          );
          //await AsyncStorage.setItem('TODMENA::AUTH', JSON.stringify(res));
          const { url: nextLink } = getLink(
            response,
            ApiRels.PROFILE.GET_PROFILES
          );

          navigation.replace(NavigationRoutes.profiles, { url: nextLink });
        }
      }, 5000);
    }
  });

  useEffect(() => {
    if (!isExpired && loginAuth) {
      const timeout = setTimeout(() => {
        setIsExpired(true);
      }, 180000);

      setTimer(timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loginAuth, isExpired]);

  /**
   * Render Item
   *
   * @param {object} props - props
   * @param {object} props.item - item
   * @returns {module:JSX.Element} - JSX.Element
   */
  const renderItem = ({ item }) => {
    return (
      <View style={styles.flatListContainer}>
        <Text text={item.value} size={FONT_SIZES.DISPLAYSMALL} />
      </View>
    );
  };

  /**
   * Renew QR Code
   */
  const handleRenewCode = () => {
    setIsExpired(false);

    if (timer) {
      clearTimeout(timer);
    }

    loginHandler();
  };

  return (
    <View>
      <View style={styles.heroContainer}>
        <ImageBackground source={image} style={styles.image} />
        <LinearGradient
          colors={[colors.blackTransparent01, colors.blackTransparent04]}
          style={styles.gradient}
        />
      </View>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <BasicButton
            icon={ArrowLeftBold}
            text="Back"
            size={buttonSize.xlarge}
            theme={buttonThemes.ghostWhite}
            themeFocused={buttonThemes.ghostWhite}
            style={styles.backButton}
            focusKey={'backButton'}
            onEnterPress={() => {
              handleModal(null);
            }}
          />
        </View>
        <View style={styles.bottomContainer}>
          <View style={styles.signInText}>
            <Text
              text="Sign in to explore"
              color={colors.primary500}
              size="DS"
              bold={true}
            />
            <Text
              text="Welcome to TOD! Unlimited movies, TV shows, and more. Watch anywhere. Cancel anytime."
              color={colors.neutral200}
              size="H2"
            />
          </View>

          {isGenerating ? (
            <View style={styles.containerCard}>
              <View>
                <Icon
                  icon={Loading}
                  width={72}
                  height={72}
                  fill={colors.shades100}
                />
              </View>
              <Text
                text="Generating the new code..."
                size={FONT_SIZES.HEADING2}
              />
            </View>
          ) : hasError ? (
            <View style={styles.containerCard}>
              <Text
                text="An error occurred. Please try again."
                size={FONT_SIZES.HEADING2}
              />
              <View>
                <BasicButton
                  style={styles.renewButton}
                  text="Renew Code"
                  focusKey={'renewButton'}
                  theme={buttonThemes.primary}
                  size={buttonSize.xlarge}
                  onEnterPress={() => {
                    handleRenewCode();
                  }}
                />
              </View>
            </View>
          ) : isExpired ? (
            <View style={styles.containerCard}>
              <Text
                text="Activation code has expired. Please generate a new code."
                size={FONT_SIZES.HEADING2}
              />
              <BasicButton
                style={styles.renewButton}
                text="Renew Code"
                focusKey={'renewButton'}
                theme={buttonThemes.primary}
                size={buttonSize.xlarge}
                onEnterPress={() => {
                  handleRenewCode();
                }}
              />
            </View>
          ) : (
            <>
              <View>
                <View style={styles.cardContainer}>
                  <View style={styles.card}>
                    <View style={styles.leftCardContainer}>
                      <Text
                        text="To watch on your TV, go to the URL 
and enter the code below"
                        numberOfLines={3}
                        size={FONT_SIZES.HEADING2}
                        color={colors.neutral100}
                        style={styles.alignText}
                      />
                      <View style={styles.leftCardBottom}>
                        <Text
                          text="https://tod.tv/tvcode"
                          numberOfLines={3}
                          size="H2"
                          color={colors.neutral100}
                          style={styles.linkText}
                        />
                        <View style={styles.flatlist}>
                          <FlatList
                            data={pinCodes}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => index}
                            horizontal={true}
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  <Text
                    text="OR"
                    size="H3"
                    color={colors.neutral400}
                    style={styles.alignMid}
                  />

                  <View style={[styles.card, styles.card2]}>
                    <Text
                      text="Scan the QR code 
to Sign In"
                      numberOfLines={3}
                      size="H2"
                      color={colors.neutral100}
                      style={styles.alignText}
                    />
                    <Image
                      source={{ uri: loginAuth?.imagePath }}
                      style={styles.qrImage}
                    />
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
        <View style={styles.copyright}>
          <Text
            text="All rights reserved © TOD 2024"
            size={FONT_SIZES.HEADING5}
            color={colors.neutral100}
          />
          <Text
            text="v2.0.2.4"
            size={FONT_SIZES.HEADING5}
            color={colors.neutral100}
          />
        </View>
      </View>
    </View>
  );
};

export default SignIn;
