import api from './api';
import env from './config';

const baseURL = env.SmartTv.ApiTest + 'api/v' + env.SmartTv.ApiVersion;

export const AppApis = {
  MenuApis: () => api.get(baseURL + '/ui/menus'),
  DynamicGet: (url) => api.get(url),
  DynamicPost: (url, body, headers) => api.post(url, body, headers),
  DynamicPut: (url, body, headers) => api.put(url, body, headers),
  DynamicDelete: (url) => api.delete(url),
};
