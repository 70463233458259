import { HTTP_URL, HTTPS_URL } from '../../helpers/Constants';

/**
 * Decode URL
 *
 * @param {string} url - url
 * @returns {string} - string
 */
const urlDecode = (url) => {
  if (!url || url === '') {
    return null;
  }

  return decodeURIComponent(url)?.replace(HTTP_URL, HTTPS_URL);
};

export default urlDecode;
