import './i18n';
import React from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import useStorage from './useStorage';
import LOCALES from './locales';
import translate from './translate';

const LocaleContext = React.createContext();

/**
 * LocaleContextProvider
 *
 * @param {PropTypes.node} children - children
 * @returns {React.JSX.Element} - JSX.Element
 */
export const LocaleContextProvider = ({ children }) => {
  const [locale, changeLocale] = useStorage('@language', LOCALES.ENGLISH);

  I18n.locale = locale.name;

  /**
   * changeLocaleLang
   *
   * @param {string} locales - locales
   */
  const changeLocaleLang = (locales) => {
    I18n.locale = locales.name;
    changeLocale(locales);
  };

  const providerConfiguration = {
    ...I18n,
    localeProvider: locale,
    getString: translate,
    changeLocale: changeLocaleLang,
  };

  return (
    <LocaleContext.Provider value={providerConfiguration}>
      {children}
    </LocaleContext.Provider>
  );
};

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleContext;
