/*
 * Global Colors
 */
const globals = {
  gray200: '#c8c8c8',
  primary50: '#FFF8E6',
  primary100: '#FFF2CC',
  primary200: '#FFE499',
  primary300: '#FFD766',
  primary400: '#FFC933',
  primary500: '#FFBC00',
  primary600: '#FFA600',
  primary700: '#E69500',
  primary800: '#CC8500',
  primary900: '#B37400',
  neutral50: '#ECECEC',
  neutral100: '#DCDCDC',
  neutral200: '#B9B9B9',
  neutral300: '#969696',
  neutral400: '#737373',
  neutral500: '#505050',
  neutral600: '#404040',
  neutral700: '#303030',
  neutral800: '#202020',
  neutral900: '#101010',
  secondary50: '#E6F1FC',
  secondary100: '#99C7F5',
  secondary200: '#73B2F1',
  secondary300: '#4D9DED',
  secondary400: '#2688E9',
  secondary500: '#0073E5',
  secondary600: '#0062C3',
  secondary700: '#0051A0',
  secondary800: '#003F7E',
  secondary900: '#002E5C',
  tertiary50: '#E6F4E6',
  tertiary100: '#99D399',
  tertiary200: '#73C273',
  tertiary300: '#4DB14D',
  tertiary400: '#26A126',
  tertiary500: '#009000',
  tertiary600: '#007A00',
  tertiary700: '#006500',
  tertiary800: '#004F00',
  tertiary900: '#003A00',
  destructive50: '#FCE6E6',
  destructive100: '#F19999',
  destructive200: '#EC7373',
  destructive300: '#E74D4D',
  destructive400: '#E12626',
  destructive500: '#DC0000',
  destructive600: '#BB0000',
  destructive700: '#9A0000',
  destructive800: '#790000',
  destructive900: '#580000',
  shades00: '#FFF',
  shades100: '#000',
  transparent: 'rgba(0, 0, 0, 0)',
  blackTransparent01: 'rgba(0,0,0,0.1)',
  blackTransparent04: 'rgba(0,0,0,0.04)',
  blackTransparent07: 'rgba(0,0,0,0.07)',
  blackTransparent08: 'rgba(0,0,0,0.08)',
  blackTransparent16: 'rgba(0,0,0,0.16)',
  blackTransparent24: 'rgba(0,0,0,0.24)',
  blackTransparent40: 'rgba(0,0,0,0.40)',
  blackTransparent56: 'rgba(0,0,0,0.56)',
  blackTransparent64: 'rgba(0,0,0,0.64)',
  blackTransparent72: 'rgba(0,0,0,0.72)',
  blackTransparent80: 'rgba(0,0,0,0.80)',
  blackTransparent92: 'rgba(0,0,0,0.92)',
  whiteTransparent04: 'rgba(255,255,255,0.04)',
  whiteTransparent08: 'rgba(255,255,255,0.08)',
  whiteTransparent16: 'rgba(255,255,255,0.16)',
  whiteTransparent24: 'rgba(255,255,255,0.24)',
  whiteTransparent40: 'rgba(255,255,255,0.40)',
  whiteTransparent56: 'rgba(255,255,255,0.56)',
  whiteTransparent64: 'rgba(255,255,255,0.64)',
  whiteTransparent72: 'rgba(255,255,255,0.72)',
  whiteTransparent80: 'rgba(255,255,255,0.80)',
  whiteTransparent92: 'rgba(255,255,255,0.92)',
};

const colors = {
  primary: globals.primary500,
  neutral: globals.neutral500,
  secondary: globals.secondary500,
  tertiary: globals.tertiary500,
  destructive: globals.destructive500,
  success: globals.tertiary500,
  info: globals.secondary500,
  warning: globals.primary500,
  error: globals.destructive500,
  text: globals.shades00,
  background: globals.shades100,
  transparent: 'transparent',
  ...globals,
};

export default colors;
