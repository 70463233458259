import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import subtitleParser from '../../../libs/subtitleParser';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

/**
 *
 * PlayerSubtitle
 *
 * @param {object} props - props
 * @param {string} props.selectedSubtitle - selectedSubtitle
 * @param {number} props.currentTime - currentTime
 * @param {object} props.containerStyle - containerStyle
 * @param {object} props.textStyle - textStyle
 * @returns {module:JSX.Element} - JSX.Element
 */
const PlayerSubtitle = ({
  selectedSubtitle,
  currentTime,
  containerStyle,
  textStyle = {},
}) => {
  const [text, setText] = useState('');
  const [subtitles, setSubtitles] = useState([]);

  useEffect(() => {
    (async () => {
      const parsedSubtitles = await subtitleParser(selectedSubtitle);

      setSubtitles(parsedSubtitles);
    })();
  }, [selectedSubtitle]);

  useEffect(() => {
    if (subtitles.length > 0) {
      let start = 0;
      let end = subtitles.length - 1;

      while (start <= end) {
        const mid = Math.floor((start + end) / 2);

        const subtitle = subtitles[mid];

        setText(subtitle.part.trim());
        if (currentTime >= subtitle.start && currentTime <= subtitle.end) {
          start++;

          return;
        } else if (currentTime < subtitle.start) {
          end = mid - 1;
        } else {
          start = mid + 1;
        }
      }
    } else setText([]);
  }, [currentTime, subtitles]);

  return (
    <View style={styles.container}>
      {text.length > 0 && <Text style={styles.subtitle}>{text}</Text>}
    </View>
  );
};

export default PlayerSubtitle;

const styles = StyleSheet.create({
  subtitle: {
    fontSize: GetScaledValue(36),
    color: colors.shades00,
    textAlign: 'center',
    alignSelf: 'center',

    textShadowColor: colors.shades100,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 2,
  },
  container: {
    position: 'absolute',
    top: GetScaledValue(800),
    left: GetScaledValue(600),
    right: GetScaledValue(600),
  },
});
