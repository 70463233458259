import { StyleSheet } from 'react-native';
import { FONT_FAMILY, FONT_VALUES } from '../../../../helpers/Enums';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../../helpers/Colors';
/**
 * AnimatedButton styles
 *
 * @param {object} props - props
 * @param {boolean} props.focused - focused
 * @param {any} props.btnWidth - btnWidth
 * @returns {object} - styles
 */
const style = ({ btnWidth, focused }) => {
  return StyleSheet.create({
    text: {
      fontFamily: FONT_FAMILY.BOLD,
      fontSize: GetScaledValue(28),
      lineHeight: FONT_VALUES.H3.LINE_HEIGHT,
      position: 'absolute',
      color: 'black',
      zIndex: 2,
    },
    animatedContainer: {
      borderRadius: GetScaledValue(12),
      overflow: 'hidden',
      width: btnWidth,
      justifyContent: 'center',
      alignItems: 'center',
      height: GetScaledValue(80),
      backgroundColor: 'gray',
      marginTop: GetScaledValue(40),
      borderWidth: focused ? 2 : 0,
      borderColor: focused ? colors.primary : colors.transparent,
    },
    animatedInner: {
      backgroundColor: 'white',
      borderRadius: GetScaledValue(12),
      ...StyleSheet.absoluteFillObject,
    },
    animatedButton: {
      height: GetScaledValue(80),
    },
  });
};

export default style;
