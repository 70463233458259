import { StyleSheet, View } from 'react-native';
import React, { useCallback, useState } from 'react';
import { GetScaledValue } from '@digiturk/screen-size';
import Keyboard from '../../organisms/Keyboard';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import Text from '../../atoms/Text';
import Language from './language';
import { FONT_FAMILY, FONT_SIZES, SectionTypes } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import { translate } from '@digiturk/i18n';
import FakeInput from '../../molecules/FakeInput';
import { useFocusEffect } from '@react-navigation/native';

/**
 *
 * @param {object} props -
 * @param {boolean} props.prefix -
 * @param {boolean} props.isEdit -
 * @param {Function} props.onSelectHandler -
 * @returns {module:JSX.Element}-
 */
const Name = ({ prefix, isEdit, onSelectHandler }) => {
  const [value, setValue] = useState('');

  useFocusEffect(
    useCallback(() => {
      setFocusCustom(`${prefix}-keyboard-0`);
    }, [prefix])
  );

  const onDonePress = useCallback(() => {
    value !== '' && onSelectHandler(SectionTypes.Main, { profileName: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <View style={styles.container}>
      <Text
        text={
          isEdit
            ? translate(Language.EDIT_PROFILE_NAME_TITLE)
            : translate(Language.ADD_NEW_PROFILE_NAME)
        }
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.DISPLAYSMALL}
        color={colors.shades00}
      />
      <View style={styles.innerContainer}>
        <Keyboard
          limit={24}
          value={value}
          setValue={setValue}
          prefix={`${prefix}-keyboard`}
          onDonePress={onDonePress}
          containerStyle={styles.keyboardContainer}
        />
        <View style={styles.inputContainer}>
          <FakeInput
            value={value}
            maxChar={24}
            label={'Name'}
            fakeFocus={value.length}
            inputContainer={styles.input}
          />
        </View>
      </View>
    </View>
  );
};

export default Name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: GetScaledValue(60),
  },
  keyboardContainer: {
    zIndex: 99,
    width: GetScaledValue(380),
  },
  inputContainer: {
    flex: 1,
    zIndex: 98,
    alignItems: 'center',
  },
  input: {
    width: GetScaledValue(520),
    height: GetScaledValue(100),
    borderRadius: GetScaledValue(15),
    borderWidth: GetScaledValue(2),
    justifyContent: 'center',
    paddingHorizontal: GetScaledValue(20),
  },
});
