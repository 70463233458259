import React, { useCallback, useState, useEffect } from 'react';
import { FlatList } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import { PageRailListPropTypes } from './proptypes';

import RailItem from './railItem';

import { makeApiCall } from '../../../middleware/dynamic';

/**
 * RailList component renders a horizontal rail list.
 *
 * @param {object} props - Props for the RailList component.
 * @param {string} props.prefix - Prefix for the rail list.
 * @param {Array} props.content - Content of the rail list.
 * @param {string} props.leftFocusKey - Left focus key.
 * @param {Function} props.onEnterPress - Function triggered on enter press.
 * @param {Function} props.setGlobalFocusedItem - setGlobalFocusedItem
 * @param {string} props.railListRef - railListRef
 * @param {Function} props.railFocusIndexSetter - railFocusIndexSetter
 * @param {Function} props.onScrollToIndexFailedHandler - onScrollToIndexFailedHandler
 * @returns {module:JSX.Element} - JSX.Element
 */
const RailLists = ({
  prefix,
  content,
  leftFocusKey,
  onEnterPress,
  setGlobalFocusedItem,
  railListRef,
  railFocusIndexSetter,
  onScrollToIndexFailedHandler,
}) => {
  const [newItems, setNewItems] = useState([]);

  const keyExtractor = useCallback(
    (_, index) => `${prefix}_${index}`,
    [prefix]
  );

  /**
   * Fetch Rail List
   */
  useEffect(() => {
    (async () => {
      if (content?.length) {
        const response = await makeApiCall({ url: content[0]?.links[0]?.href });

        //burada items yerine bölünmüş olan datalar listelenicek

        const items = response?.items;
        const rails = [];

        for (let i = 0; i < items?.length; i += 6) {
          rails.push(items.slice(i, i + 6));
        }

        // Creating the desired format
        const transformedData = {
          data: {
            rails: rails.map((rail, index) => ({
              id: `R10${index}`,
              links: [
                {
                  href: rail,
                  id: `R100${index}`,
                  page: 1,
                  pageSize: 10,
                  railType: `MyList`,
                  title: 'My List',
                  totalItems: 4,
                  urlSlug: 'mylist',
                },
              ],
              page: 1,
              pageSize: 20,
              railType: `MyList`,
              title: 'Mylist',
              totalItems: rails.length,
              urlSlug: 'mylist',
            })),
          },
        };

        setNewItems(transformedData.data.rails);
      }
    })();
  }, [content]);

  const onBecameFocused = useCallback((_coordinate, selected) => {
    railFocusIndexSetter(selected?.index + 1);
    railListRef?.current?.scrollToIndex({
      index: selected.index,
      viewPosition: 0,
      animated: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <RailItem
          item={item}
          key={prefix}
          index={index}
          prefix={prefix}
          leftFocusKey={leftFocusKey}
          onEnterPress={onEnterPress}
          onBecameFocused={onBecameFocused}
          setGlobalFocusedItem={setGlobalFocusedItem}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newItems]
  );

  return (
    <FlatList
      data={newItems}
      ref={railListRef}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      showsHorizontalScrollIndicator={false}
      removeClippedSubviews={false}
      onScrollToIndexFailed={onScrollToIndexFailedHandler}
      scrollEnabled={false}
    />
  );
};

RailLists.propTypes = PageRailListPropTypes;

export default React.memo(withFocusable()(RailLists));
