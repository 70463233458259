import { BackspaceStroke, TrashStroke } from '../../../assets/icons';

const NumpadData = {
  keys: [
    {
      label: '1',
    },
    {
      label: '2',
    },
    {
      label: '3',
    },
    {
      label: '4',
    },
    {
      label: '5',
    },
    {
      label: '6',
    },
    {
      label: '7',
    },
    {
      label: '8',
    },
    {
      label: '9',
    },
    {
      label: 'trash',
    },
    {
      label: '0',
    },
    {
      label: 'backspace',
    },
  ],

  keysRTL: [
    {
      label: '١',
    },
    {
      label: '٢',
    },
    {
      label: '٣',
    },
    {
      label: '٤',
    },
    {
      label: '٥',
    },
    {
      label: '٦',
    },
    {
      label: '٧',
    },
    {
      label: '٨',
    },
    {
      label: '٩',
    },
    {
      label: 'backspace',
    },
    {
      label: '٠',
    },
    {
      label: 'trash',
    },
  ],

  keyIconsAndStyles: {
    backspace: {
      icon: BackspaceStroke,
      size: { width: 58, height: 58 },
    },
    trash: {
      icon: TrashStroke,
      size: { width: 40, height: 40 },
    },
  },
};

export { NumpadData };
