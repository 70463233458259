import React, { memo } from 'react';
import { View } from 'react-native';
import {
  GetScaledValue,
  GetWindowWidth,
  GetWindowHeight,
} from '@digiturk/screen-size';
import ContentLoader, { Rect } from 'react-content-loader/native';

import { contentTypeList } from '../../../helpers/Enums';
import useLocalization from '../../../libs/localization/useLocalization';
import colors from '../../../helpers/Colors';

import style from './styles';

/**
 * Skeleton Loader
 *
 * @param {object} props - props
 * @param {object} props.contentType - contentType
 * @returns {module:JSX.Element} - JSX.Element
 */
const Skeleton = ({ contentType = contentTypeList.movie }) => {
  const { isRTL } = useLocalization();
  const styles = style();

  return (
    <View style={styles.loading}>
      <ContentLoader
        rtl={isRTL}
        speed={1}
        width={GetWindowWidth()}
        height={GetWindowHeight()}
        backgroundColor={colors.neutral700}
        foregroundColor={colors.neutral500}>
        {contentType === contentTypeList.eventAndTournament ||
        contentType === contentTypeList.sports ? (
          <>
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(420)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(540)}
              height={GetScaledValue(170)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(620)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(430)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(660)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(240)}
              y={GetScaledValue(660)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(350)}
              y={GetScaledValue(660)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(460)}
              y={GetScaledValue(660)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(705)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(210)}
              height={GetScaledValue(50)}
            />
            <Rect
              x={GetScaledValue(350)}
              y={GetScaledValue(705)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(50)}
            />
            <Rect
              x={GetScaledValue(460)}
              y={GetScaledValue(705)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(50)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(785)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(170)}
              height={GetScaledValue(90)}
            />
            <Rect
              x={GetScaledValue(310)}
              y={GetScaledValue(785)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(90)}
            />
            <Rect
              x={GetScaledValue(420)}
              y={GetScaledValue(785)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(90)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(980)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(190)}
              height={GetScaledValue(50)}
            />
          </>
        ) : (
          <>
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(360)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(340)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(395)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(110)}
              height={GetScaledValue(45)}
            />
            <Rect
              x={GetScaledValue(245)}
              y={GetScaledValue(395)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(110)}
              height={GetScaledValue(45)}
            />
            <Rect
              x={GetScaledValue(360)}
              y={GetScaledValue(395)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(110)}
              height={GetScaledValue(45)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(470)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(800)}
              height={GetScaledValue(110)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(595)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(340)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(635)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(340)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(680)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(450)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(720)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(450)}
              height={GetScaledValue(30)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(785)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(170)}
              height={GetScaledValue(90)}
            />
            <Rect
              x={GetScaledValue(310)}
              y={GetScaledValue(785)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(90)}
            />
            <Rect
              x={GetScaledValue(420)}
              y={GetScaledValue(785)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(100)}
              height={GetScaledValue(90)}
            />
            <Rect
              x={GetScaledValue(130)}
              y={GetScaledValue(980)}
              rx={GetScaledValue(20)}
              ry={GetScaledValue(20)}
              width={GetScaledValue(190)}
              height={GetScaledValue(50)}
            />
          </>
        )}
      </ContentLoader>
    </View>
  );
};

export default memo(Skeleton);
