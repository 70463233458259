import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, View, Image } from 'react-native';
import { GetScaledValue, GetWindowHeight } from '@digiturk/screen-size';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import useLocalization from '../../../libs/localization/useLocalization';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import {
  isWeb,
  trailer,
  isValidRailType,
  isFullScreenSection,
} from '../../../helpers/CommonHelper';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { ArrowDownBold, ArrowTopBold } from '../../../assets/icons';
import Background from '../../atoms/Posters/Background';
import ArrowWithText from '../../molecules/ArrowWithText';
import FocusableSection from './section';
import Skeleton from './skeleton';
import style from './styles';
import { useRoute } from '@react-navigation/native';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * ContentDetail
 *
 * @returns {module:JSX.Element} -
 */
const ContentDetail = () => {
  const player = trailer.get();
  const railListRef = useRef();
  const { url, contentType } = useRoute().params;
  const { goBack } = useCustomNavigation();
  const { languageDirection } = useLocalization();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [image, setImage] = useState({ backgroundImage: '', logoImage: '' });
  const [index, setIndex] = useState(0);
  const [title, setTitle] = useState({
    up: '',
    down: '',
  });
  const styles = style();
  let offsetArr = [GetScaledValue(0), GetScaledValue(1080)];

  useHardwareBackPress(() => {
    goBack();
  }, true);

  useEffect(() => {
    if (player) {
      if (index === 0 && player.isPaused()) player.play();
      else player.pause();
    }
  }, [player, index]);

  const keyExtractor = useCallback((_item, _index) => `detail_${_index}`, []);

  /**
   * Filters rails to include only valid railTypes
   *
   * @param {Array} rails - The array of rails to filter
   * @returns {Array} - The filtered array of rails with valid railTypes
   */
  const filterRails = (rails) => {
    return rails.filter((rail) => isValidRailType(rail.railType));
  };

  useEffect(() => {
    (async () => {
      if (url) {
        const response = await makeApiCall({ url });
        const filteredRails = filterRails(response.rails);

        setData(filteredRails);
        setTimeout(() => setIsLoading(false), 1000);
        setTimeout(() => {
          setFocusCustom('detail-0-button-0');
        }, 100);
      }

      console.info(title);
    })();
  }, [url]);

  const onScrollToIndexFailedHandler = useCallback(
    ({ index: idx, averageItemLength }) => {
      railListRef?.current?.scrollToOffset({
        offset: idx * averageItemLength,
        animated: true,
      });

      setTimeout(() => {
        railListRef?.current?.scrollToIndex({
          index: idx,
          animated: true,
        });
      }, 100);
    },
    []
  );

  const counter = useCallback(
    (_data, idx) => {
      if (idx !== 0) {
        offsetArr[idx + 1] = offsetArr[idx] + GetScaledValue(_data);
      }

      return offsetArr[idx];
    },
    [offsetArr]
  );

  const onBecameFocus = useCallback(
    (coordinate, selected) => {
      setTimeout(() => {
        if (isWeb) {
          railListRef.current?.scrollToOffset({
            offset: counter(coordinate.height, selected?.index),
            animated: true,
          });
        } else {
          railListRef.current?.scrollToIndex({
            index: selected?.index,
            animated: true,
          });
        }
      }, 100);

      setIndex(selected?.index);
    },
    [counter]
  );

  /**
   * Render item
   */
  const renderItem = useCallback(
    ({ item, index: idx }) => (
      <FocusableSection
        item={item}
        index={idx}
        setImage={setImage}
        focusKeyPrefix={'detail'}
        contentType={contentType}
        contentLength={data.length}
        onBecameFocused={onBecameFocus}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(() => {
    setTitle({});
    if (titleArrowTimer?.current) {
      clearTimeout(titleArrowTimer);
    }

    const titleArrowTimer = setTimeout(() => {
      if (data) {
        if (index === 0) {
          setTitle({
            down: `${data[index + 1]?.title}`,
          });
        } else if (index === data.length - 1) {
          setTitle({
            up: `${data[index - 1]?.title}`,
          });
        } else {
          if (isFullScreenSection(data[index].railType)) {
            setTitle({
              up: `${data[index - 1]?.title}`,
              down: `${data[index + 1]?.title}`,
            });
          } else {
            setTitle({
              up: `${data[index - 1]?.title}`,
            });
          }
        }
      }
    }, 1000);

    return () => {
      clearTimeout(titleArrowTimer);
    };
  }, [data, index]);

  if (!data) return null;

  return (
    <Background
      source={image.backgroundImage}
      blurRadius={32}
      containerStyle={styles.backgroundContainer}>
      <View dir={languageDirection}>
        {title.up && contentType === 3 && index !== 0 && (
          <View style={styles.iconArea}>
            <Image
              source={{ uri: image.logoImage }}
              resizeMode="contain"
              style={styles.logo}
            />
          </View>
        )}
        {isLoading && <Skeleton contentType={contentType} />}
        {title.up && (
          <ArrowWithText
            icon={ArrowTopBold}
            iconStyle={styles.iconStyle}
            text={title.up}
            containerStyle={styles.upArrowStyle}
          />
        )}
        <View style={styles.fullScreenContainer}>
          <FlatList
            ref={railListRef}
            data={data}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            showsVerticalScrollIndicator={false}
            onScrollToIndexFailed={onScrollToIndexFailedHandler}
            removeClippedSubviews={false}
            scrollEnabled={false}
            ListFooterComponent={<View style={{ height: GetWindowHeight() }} />}
          />
        </View>
        {title.down && (
          <ArrowWithText
            icon={ArrowDownBold}
            containerStyle={styles.downArrowStyle}
            text={title.down}
            iconStyle={styles.iconStyle}
          />
        )}
      </View>
    </Background>
  );
};

export default ContentDetail;
