import { View } from 'react-native';
import React from 'react';

import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Renders a modal component with the provided children.
 *
 * @param {object} props - The props object containing the children.
 * @param {React.ReactNode} props.children - The content to be rendered inside the modal.
 * @returns {React.ReactElement} A View component containing the provided children.
 */
const WatchModal = ({ children }) => {
  return (
    <View
      style={{
        position: 'absolute',
        width: GetScaledValue(1920),
        height: GetScaledValue(1080),
        zIndex: 1,
      }}>
      {children}
    </View>
  );
};

export default WatchModal;
