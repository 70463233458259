import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  heroContainer: {
    width: GetScaledValue(1920),
    height: GetScaledValue(1080),
    backgroundColor: colors.shades100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topContainer: {
    alignSelf: 'flex-start',
    position: 'absolute',
    top: GetScaledValue(60),
    paddingRight: GetScaledValue(80),
    paddingLeft: GetScaledValue(80),
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: GetScaledValue(40),
  },
  warningButton: {
    alignSelf: 'center',
    height: GetScaledValue(120),
    transform: [{ scale: 3 }],
  },
  lowerBackButton: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: GetScaledValue(380),
  },
});

export default styles;
