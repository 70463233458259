import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  topContainer: {
    paddingStart: GetScaledValue(60),
    paddingTop: GetScaledValue(80),
    paddingEnd: GetScaledValue(80),
  },
  bottomContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingStart: GetScaledValue(80),
    paddingBottom: GetScaledValue(80),
    paddingEnd: GetScaledValue(80),
  },
  innerContainer: {
    width: '50%',
    flexDirection: 'column',
  },
  description: {
    color: colors.neutral200,
  },
  innerRight: {
    justifyContent: 'flex-end',
  },
});

export default styles;
