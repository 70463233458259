/* eslint-disable jsdoc/check-param-names */
import React, { useCallback } from 'react';
import { PlayerPropTypes, PlayerDefaultProps } from './proptypes';
import { useFocusEffect } from '@react-navigation/native';
import { Player, PlayerEvent } from 'bitmovin-player';

/**
 * Player component
 *
 * @param {Function} onLoad - on load callback
 * @param {object} refPlayer - reference to the player
 * @param {object} props.onCurrentTimeUpdate - on current time update callback -> currentTime: number
 * @param {object} props.onDurationChange - on duration change callback -> seekableDuration: number
 * @param {object} props.source - player source
 * @returns {module:JSX.Element} - player html
 */
const LivePlayer = ({ onLoad, refPlayer, onCurrentTimeUpdate, source }) => {
  const playerConfig = {
    key: '047DDDE8-7D3F-4355-959A-4DC51EC5B10E',
    ui: false,
  };

  const playerSource = {
    dash: source.dash,
    hls: source.hls,
    poster:
      'https://bitdash-a.akamaihd.net/content/MI201109210084_1/poster.jpg',
  };

  /**
   * İnitialize Player
   */
  const initializePlayer = useCallback(() => {
    const player = new Player(document.getElementById('player'), playerConfig);

    player.load(playerSource);

    player.on(PlayerEvent.TimeChanged, (event) => {
      onCurrentTimeUpdate && onCurrentTimeUpdate(event);
    });

    player.on(PlayerEvent.SourceLoaded, (event) => {
      console.info('Source Loaded', event);
      onLoad && onLoad(player);
    });

    player.on(PlayerEvent.SourceUnloaded, (error) => {
      console.info('Source Unloaded', error);
    });

    player.on(PlayerEvent.Destroy, (error) => {
      console.info('Destroyed Vod', error);
    });

    player.on(PlayerEvent.Error, (event) => {
      console.info('PlayerEvent Error', event);
    });

    player.on(PlayerEvent.CastStart, (event) => {
      console.info('PlayerEvent CastStart', event);
    });

    player.on(PlayerEvent.Ready, (event) => {
      console.info('Ready', event);
      player?.play();
    });

    return () => {
      player?.destroy();
    };
  }, []);

  useFocusEffect(initializePlayer);

  return <div id={'player'} />;
};

LivePlayer.propTypes = PlayerPropTypes;
LivePlayer.defaultProps = PlayerDefaultProps;

export default LivePlayer;
