import React from 'react';
import styles from './styles';
import { View } from 'react-native';
import { ArrowLeftBold, ArrowRightBold } from '../../../assets/icons';
import IconWithText from '../../molecules/IconWithText';
import useLocalization from '../../../libs/localization/useLocalization';
import { translate } from '@digiturk/i18n';
import Language from './language';
import AvatarWithText from '../../molecules/AvatarWithText';

/**
 *
 * @param {object} props - props
 * @param {object} props.profile - profile
 * @param {object} props.children - children
 * @returns {module:JSX.Element} -
 */
const ProfileWrapper = ({ profile, children }) => {
  const { isRTL } = useLocalization();

  return (
    <View style={styles.container}>
      <IconWithText
        containerStyle={styles.backButton}
        focusKey={'back-button'}
        icon={isRTL ? ArrowRightBold : ArrowLeftBold}
        iconStyle={styles.iconStyle}
        text={translate(Language.BACK)}
        textStyle={styles.textStyle}
      />
      <View style={styles.body}>
        <View style={styles.childContainer}>{children}</View>
        <AvatarWithText
          item={profile}
          showType={true}
          containerStyle={styles.avatarContainer}
        />
      </View>
    </View>
  );
};

export default ProfileWrapper;
