import { GetWindowHeight, GetWindowWidth } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import {
  isWeb,
  marginEnd,
  padding,
  sidebarWidth,
} from '../../../helpers/CommonHelper';

const styles = StyleSheet.create({
  pageArea: {
    flex: 1,
    backgroundColor: 'black',
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'black',
  },
  contentArea: {
    flex: 1,
  },
  railContainer: {
    // TODO: should be 190
    position: 'absolute',
    height: GetWindowHeight(),
    width: GetWindowWidth(),
  },
  listStyle: {
    paddingVertical: padding,
    paddingStart: sidebarWidth + padding,
    paddingEnd: isWeb ? 0 : marginEnd + sidebarWidth + padding,
  },
  contentContainerStyle: {
    paddingStart: sidebarWidth + padding,
  },
});

export default styles;
