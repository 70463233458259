import { i18n } from '@digiturk/i18n';

/**
 * localLanguageController
 *
 */
export const localLanguageController = () => {
  const langEn = _interopRequireDefault(
    require('../../translations/languages/eng.json')
  );

  const langTr = _interopRequireDefault(
    require('../../translations/languages/tr.json')
  );

  const defaultLocale = 'tr';

  const locale = 'tr';

  /**
   * _interopRequireDefault
   *
   * @param {obj} obj  -obj
   * @returns {obj} -obj
   */
  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
  }

  i18n(defaultLocale, locale, langEn, langTr);
};
