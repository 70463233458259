import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

import colors from '../../../../helpers/Colors';
import { FONT_FAMILY, FONT_VALUES } from '../../../../helpers/Enums';
import { itemMargin } from '../../../../helpers/CommonHelper';

/**
 * ProgressBar styles
 *
 * @param {boolean} focused - focused
 * @param {boolean} selected - selected
 * @returns {object} - styles
 */
const style = (focused, selected) => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconArea: {
      alignItems: 'center',
      justifyContent: 'center',
      marginEnd: itemMargin,
    },
    icon: {
      width: 40,
      height: 40,
      color: focused || selected ? colors.primary : colors.neutral300,
    },
    textArea: {
      alignItems: 'center',
      justifyContent: 'center',
      height: GetScaledValue(40),
    },
    text: {
      color: focused || selected ? colors.primary : colors.neutral300,
      fontSize:
        focused || selected
          ? GetScaledValue(FONT_VALUES.H2.FONT_SIZE)
          : GetScaledValue(FONT_VALUES.H3.FONT_SIZE),
      lineHeight:
        focused || selected
          ? GetScaledValue(FONT_VALUES.H2.LINE_HEIGHT)
          : GetScaledValue(FONT_VALUES.H3.LINE_HEIGHT),
      fontFamily:
        focused || selected ? FONT_FAMILY.SEMIBOLD : FONT_FAMILY.REGULAR,
    },
  });
};

export default style;
