import React from 'react';
import { View } from 'react-native';
import Text from '../../atoms/Text';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import styles from './styles';
import { ArrowLeftBold, Warning } from '../../../assets/icons';
import { FONT_SIZES } from '../../../helpers/Enums';

/**
 * FourKQR  component
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const GeneralError = () => {
  return (
    <View style={styles.heroContainer}>
      <View style={styles.topContainer}>
        <BasicButton
          text="Back"
          icon={ArrowLeftBold}
          size={buttonSize.xlarge}
          theme={buttonThemes.ghostWhite}
          style={styles.backButton}
          focusKey={'backButton'}
          downFocusKey={'upgradeLink'}
          onEnterPress={() => {
            console.info('backed');
          }}
        />
      </View>
      <View style={styles.bottomContainer}>
        <BasicButton
          icon={Warning}
          size={buttonSize.xlarge}
          theme={buttonThemes.ghostPrimary}
          style={styles.warningButton}
        />
        <Text
          text="An error occurred. Please try again later."
          size={FONT_SIZES.DISPLAYSMALL}
        />
        <BasicButton
          text="Back"
          theme={buttonThemes.primary}
          style={styles.lowerBackButton}
        />
      </View>
    </View>
  );
};

export default GeneralError;
