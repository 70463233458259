import { GetScaledValue, GetWindowHeight } from '@digiturk/screen-size';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { ArrowUpStroke } from '../../../assets/icons';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { FONT_SIZES } from '../../../helpers/Enums';
import Commentary from '../../organisms/Commantary';
import TeamStats from '../../organisms/TeamStats';
import Teams from '../../organisms/Teams';
import Highlights from '../../organisms/Highlights';
import TopPlayers from '../../molecules/TopPlayers';

/**
 *
 * @param {object} props - props
 * @param {React.JSX.Element} props.playerComponent - player
 * @param {boolean} props.showStatistics - showStatistics
 * @param {Function} props.setShowStatisticsCallback - setShowStatisticsCallback
 * @returns {module:JSX.Element} - JSX.Element
 */
const SportsStatistics = ({
  playerComponent,
  showStatistics,
  setShowStatisticsCallback,
}) => {
  const [selectedTab, setSelectedTab] = useState('Commentary');

  useEffect(() => {
    setFocusCustom(selectedTab);
  }, [showStatistics, selectedTab]);

  /**
   *
   * @returns {module:JSX.Element} - JSX.Element
   */
  const renderComponent = () => {
    switch (selectedTab) {
      case 'Commentary':
        return <Commentary />;
      case 'TeamStats':
        return <TeamStats />;
      case 'Lineups':
        return <Teams />;
      case 'Highlights':
        return <Highlights />;
      case 'TopPlayers':
        return <TopPlayers />;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.pageHeader}>
        <View style={styles.backTo}>
          <BasicButton
            icon={ArrowUpStroke}
            text="Match"
            theme={buttonThemes.ghostWhite}
            themeFocused={buttonThemes.whiteTransparent16}
            focusKey="backToFullscreen"
            downFocusKey={'Commentary'}
            onEnterPress={() => setShowStatisticsCallback()}
            size={buttonSize.xlarge}
          />
        </View>
        <View style={styles.buttonArea}>
          <BasicButton
            text="Commentary"
            theme={
              selectedTab === 'Commentary'
                ? buttonThemes.neutralPrimary
                : buttonThemes.neutral500
            }
            themeFocused={buttonThemes.primary}
            size={buttonSize.big}
            focusKey={'Commentary'}
            rightFocusKey={'Highlights'}
            upFocusKey={'backToFullscreen'}
            downFocusKey="focusKey-1"
            onEnterPress={() => setSelectedTab('Commentary')}
            style={styles.buttonItem}
          />
          <BasicButton
            text="Highlights"
            size={buttonSize.big}
            theme={
              selectedTab === 'Highlights'
                ? buttonThemes.neutralPrimary
                : buttonThemes.neutral500
            }
            themeFocused={buttonThemes.primary}
            style={styles.buttonItem}
            focusKey={'Highlights'}
            rightFocusKey={'Lineups'}
            leftFocusKey={'Commentary'}
            downFocusKey="focusKey-1"
            onEnterPress={() => setSelectedTab('Highlights')}
          />
          <BasicButton
            text="Lineups"
            size={buttonSize.big}
            theme={
              selectedTab === 'Lineups'
                ? buttonThemes.neutralPrimary
                : buttonThemes.neutral500
            }
            themeFocused={buttonThemes.primary}
            style={styles.buttonItem}
            focusKey={'Lineups'}
            rightFocusKey={'Team-Stats'}
            leftFocusKey={'Highlights'}
            downFocusKey="focuskey-Barcelona"
            onEnterPress={() => setSelectedTab('Lineups')}
          />
          <BasicButton
            text="Team Stats"
            size={buttonSize.big}
            theme={
              selectedTab === 'TeamStats'
                ? buttonThemes.neutralPrimary
                : buttonThemes.neutral500
            }
            themeFocused={buttonThemes.primary}
            style={styles.buttonItem}
            focusKey={'Team-Stats'}
            rightFocusKey={'Top-Players'}
            leftFocusKey={'Lineups'}
            onEnterPress={() => setSelectedTab('TeamStats')}
          />
          <BasicButton
            text="Top Players"
            size={buttonSize.big}
            theme={
              selectedTab === 'TopPlayers'
                ? buttonThemes.neutralPrimary
                : buttonThemes.neutral500
            }
            themeFocused={buttonThemes.primary}
            style={styles.buttonItem}
            focusKey={'Top-Players'}
            leftFocusKey={'Team-Stats'}
            downFocusKey="focusKey-1"
            onEnterPress={() => setSelectedTab('TopPlayers')}
          />
        </View>
      </View>
      <View style={styles.pageContent}>
        <View style={styles.halfView1}>{renderComponent()}</View>
        <View style={styles.video}>
          <View>
            <Text text="Barcelona vs Real Madrid" size={FONT_SIZES.HEADING2} />
          </View>
          {playerComponent}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.shades100,
    height: GetWindowHeight(),
  },
  pageContent: {
    flex: 1,
    flexDirection: 'row',
  },
  pageHeader: {
    height: GetScaledValue(200),
    backgroundColor: colors.shades100,
    display: 'flex',
    marginStart: GetScaledValue(40),
    marginBottom: GetScaledValue(20),
  },
  video: {
    flex: 1,
    backgroundColor: colors.shades100,
    width: GetScaledValue(1080),
    height: GetScaledValue(585),
  },
  halfView1: {
    backgroundColor: colors.shades100,
    alignItems: 'center',
    width: GetScaledValue(840),
    display: 'flex',
  },
  backTo: {
    display: 'flex',
    flexDirection: 'row',
    color: colors.shades00,
    marginVertical: GetScaledValue(10),
    marginHorizontal: GetScaledValue(40),
  },
  buttonArea: {
    flexDirection: 'row',
    marginHorizontal: GetScaledValue(40),
  },
  buttonItem: {
    marginEnd: 20,
    padding: GetScaledValue(20),
  },
  statisticsArea: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: GetScaledValue(40),
  },
});

export default SportsStatistics;
