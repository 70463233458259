import PropTypes from 'prop-types';

export default {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  asset: PropTypes.bool,
  resizeMode: PropTypes.string,
  blur: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bgColor: PropTypes.string,
  onError: PropTypes.func,
};
