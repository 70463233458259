import { View } from 'react-native';
import React from 'react';
import styles from './styles';
import Rounded from '../Rounded';
import colors from '../../../helpers/Colors';

/**
 * @param {object} props - props
 * @param {boolean} props.focused - focused
 * @param {string} props.value - value
 * @param {boolean} props.isWrong - isWrong
 * @returns {module:JSX.Element} -
 */
const Digit = ({ focused, value, isWrong }) => {
  const style = styles(focused, value, isWrong);

  return (
    <View style={style.container}>
      {focused && !value && <View style={style.pipe} />}
      {value && <Rounded color={colors.shades00} size={20} />}
    </View>
  );
};

export default Digit;
