import { AppApis } from '../services';

//TODO: Add checking the responses status code and behave accordingly

/**
 * DynamicCall
 *
 * @param {string} method - method
 * @param {string} url - url
 * @param {object} body - body
 * @returns {object} - response
 */
const DynamicCall = async (method = 'GET', url, body) => {
  switch (method) {
    case 'GET':
      return await AppApis.DynamicGet(url);
    case 'POST':
      return await AppApis.DynamicPost(url, body);
    case 'PUT':
      return await AppApis.DynamicPut(url, body);
    case 'DELETE':
      return await AppApis.DynamicDelete(url);
    default:
      throw new Error('Method not supported');
  }
};

/**
 * makeApiCall
 *
 * @param {object} params - params
 * @param {string} params.method - method
 * @param {string} params.url - url
 * @param {object} params.body - body
 * @returns {object} - response
 */
export const makeApiCall = async ({ method, url, body }) => {
  const response = await DynamicCall(method, url, body);

  if (response.status !== 200) {
    return null;
  }

  return response.data.data;
};
