/**
 * Converts seconds to HH:MM or MM:SS format
 *
 * @param {number} value - data
 * @returns {string} time - time
 */
const convertTimeFormat = (value) => {
  const isMinus = value > 0 ? 1 : -1;
  let { hours, minutes, seconds } = convertTime(isMinus * value);

  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  const time =
    parseInt(hours, 10) === 0
      ? minutes + ':' + seconds
      : hours + ':' + minutes + ':' + seconds;

  return isMinus > 0 ? time : `-${time}`;
};

/**
 * Converts seconds to hour, minute, second format
 *
 * @param {number} value - data
 * @returns {object} hours, minutes, seconds - hours, minutes, seconds
 */
export const convertTime = (value) => {
  const sec = parseInt(value, 10);
  // get hours
  let hours = Math.floor(sec / 3600);
  // get minutes
  let minutes = Math.floor((sec - hours * 3600) / 60);
  //  get seconds
  let seconds = sec % 60;

  return { hours, minutes, seconds };
};

export default convertTimeFormat;
