import { withFocusable } from '@digiturk/react-spatial-navigation';
import React from 'react';
import { StyleSheet, View } from 'react-native';

/**
 * Hidden focus placeholder component
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const HiddenFocusPlaceholder = () => {
  return <View style={styles.hiddenFocusPlaceholder} />;
};

const styles = StyleSheet.create({
  hiddenFocusPlaceholder: {
    width: 0,
    height: 0,
  },
});

export default withFocusable()(HiddenFocusPlaceholder);
