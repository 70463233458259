import React, { useEffect } from 'react';
import { View, Image, ScrollView } from 'react-native';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import Icon from '../../atoms/Icon';

import { withFocusable } from '@digiturk/react-spatial-navigation';
import styles from './styles';
import colors from '../../../helpers/Colors';
import { Substitution } from '../../../assets/icons';

/**
 * Lineup component
 *
 * @param {any} props - props
 * @param {boolean} props.focused - focused
 * @param {Array} props.teams - teams data
 * @param {Function} props.onFocus - onFocus
 * @returns {module:JSX.Element} - JSX.Element
 */
const Lineup = ({ teams, focused, onFocus }) => {
  useEffect(() => {
    if (focused && onFocus) {
      onFocus();
    }
  }, [focused, onFocus]);

  const style = styles(focused);

  return (
    <>
      <View style={style.container} key={teams.name}>
        <View style={style.header}>
          <Image source={''} style={style.logo} />
          <Text
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING3}
            text={teams.name}
          />
        </View>
        <View style={style.lineupContainer}>
          <View style={style.playersList}>
            <ScrollView>
              {teams?.players.map((player, index) => (
                <View key={index} style={style.playerRow}>
                  <Text
                    fontFamily={FONT_FAMILY.REGULAR}
                    size={FONT_SIZES.HEADING5}
                    style={style.playerNumber}
                    text={player.number}
                  />
                  <Text
                    fontFamily={FONT_FAMILY.REGULAR}
                    size={FONT_SIZES.HEADING5}
                    style={style.playerName}
                    text={player.name}
                  />
                  <View style={style.icons}>
                    {player.icons?.map((icon, i) => (
                      <Icon key={i} icon={icon} />
                    ))}
                  </View>
                </View>
              ))}
            </ScrollView>
          </View>
          <View style={style.substitutedContainer}>
            <Text
              fontFamily={FONT_FAMILY.REGULAR}
              size={FONT_SIZES.HEADING4}
              style={style.substitutedHeader}
              text={'Substituted'}
            />
            <ScrollView>
              {teams?.substituted.map((sub, index) => (
                <View style={style.subArea}>
                  <View style={style.iconArea}>
                    <Icon icon={Substitution} width={32} height={32} />
                  </View>
                  <View>
                    <View key={index} style={style.substitutedRow}>
                      <Text
                        fontFamily={FONT_FAMILY.REGULAR}
                        size={FONT_SIZES.PARAGRAPH1}
                        style={style.playerNumber}
                        text={sub.number}
                      />
                      <Text
                        fontFamily={FONT_FAMILY.REGULAR}
                        size={FONT_SIZES.PARAGRAPH1}
                        style={style.playerName}
                        text={sub.name}
                      />
                    </View>
                    <View key={index} style={style.substitutedRow}>
                      <Text
                        color={colors.neutral200}
                        fontFamily={FONT_FAMILY.REGULAR}
                        size={FONT_SIZES.PARAGRAPH2}
                        style={style.playerNumber}
                        text={sub.subNum}
                      />
                      <Text
                        color={colors.neutral200}
                        fontFamily={FONT_FAMILY.REGULAR}
                        size={FONT_SIZES.PARAGRAPH2}
                        style={style.playerName}
                        text={sub.subName}
                      />
                    </View>
                  </View>
                </View>
              ))}
            </ScrollView>
          </View>
        </View>
      </View>
    </>
  );
};

export default withFocusable()(Lineup);
