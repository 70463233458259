import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import { View, FlatList } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import LinearGradient from 'react-native-linear-gradient';

import { useMenu } from '../../../context';
import { ApiRels, FONT_SIZES } from '../../../helpers/Enums';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { getLink } from '../../../helpers/CommonHelper';
import colors from '../../../helpers/Colors';

import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import { BasicButton } from '../../molecules/buttons';
import { buttonSize, buttonThemes } from '../../molecules/buttons';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import Keyboard from '../../organisms/Keyboard';
import SearchNoResults from '../../organisms/SearchNoResults';
import { SearchMobileTabStroke } from '../../../assets/icons';

import RailList from './railList';
import Skeleton from './skeleton';
import { PageContentPropTypes } from './proptypes';
import styles from './styles';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * Search View Template of pages
 *
 * @param {object} props - props
 * @param {object} props.contentSource - content source
 * @param {string} props.prefix - prefix
 * @param {string} props.apiKey - apiKey
 * @param {string|boolean} props.leftFocusKey - leftFocusKey
 * @param {Function} props.globalFocusedItem - globalFocusedItem
 * @returns {module:JSX.Element} - JSX.Element
 */
const SearchView = ({
  contentSource,
  prefix,
  apiKey,
  leftFocusKey,
  globalFocusedItem = () => {},
}) => {
  const railListRef = useRef();
  const suggestionListRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [searchKey, setSearchKey] = useState('');
  const [searchLinks, setSearchLinks] = useState([]);
  const [railList, setRailList] = useState([]);
  const [buttonList, setButtonList] = useState([]);
  const [searchNoResult, setSearchNoResult] = useState(false);

  const { menuState } = useMenu();

  const { navigate, replaceNavigate } = useCustomNavigation();

  /**
   * Fetch Searchable Keys for Button List
   */
  useEffect(() => {
    (async () => {
      // initial fetch and empty value
      if (searchKey === '') {
        // clear state
        setSearchNoResult(false);

        const { url } = getLink(menuState.data, 'search');
        const initialRailRequest = await makeApiCall({ url });

        const { url: suggestionLink } = getLink(
          initialRailRequest,
          ApiRels.SEARCH.SUGGESTIONS_KEYS
        );

        const initialSuggestions = await makeApiCall({ url: suggestionLink });

        setSearchLinks({ links: initialRailRequest.links });
        setRailList(initialRailRequest?.rails);
        setButtonList(initialSuggestions.keys);
      }

      // searchKey
      if (searchKey.length >= 3) {
        const { url: searchableKeysLink } = getLink(
          searchLinks,
          ApiRels.SEARCH.SEARCHABLE_KEYS
        );
        const searchableKeysRequest = await makeApiCall({
          url: searchableKeysLink,
        });

        // TODO: added blabal for empty search case. it will change with setButtonList(searchableKeysRequest.keys);
        setButtonList(
          ['blabla', 'anything', 'blablabla'].concat(searchableKeysRequest.keys)
        );
      }
    })();
  }, [searchKey]);

  useEffect(() => {
    setFocusCustom(`keyboard-0`);
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  /**
   * Filter From Result Components
   *
   * @param {object} railList - rail list
   * @returns {object} responseRailList - filtered rail list
   */
  const cleanFromResultsComponent = (railList) => {
    // Search Result Items - NOTE: the results components comes in "response.rails" array
    const searchResultComponents = ['SearchResultTitle', 'SearchNoResultTitle'];

    // filter rails without searchResultComponents
    const responseRailList = railList.filter(
      (rail) => !searchResultComponents.includes(rail.railType)
    );

    return responseRailList;
  };

  /**
   * Has SearchNoResultTitle Component
   *
   * @param {object} railList - rail list
   * @returns {object|boolean} hasElements - filtered rail list
   */
  const hasNoResults = (railList) => {
    const hasElements = railList.find(
      (rail) => rail.railType === 'SearchNoResultTitle'
    );

    return hasElements ? hasElements : false;
  };

  /**
   * OnEnterPress - Button List
   *
   * @param {object} selected - selected item
   */
  const onEnterPressButonList = useCallback(
    (selected) => {
      (async () => {
        // Clear States
        setRailList([]);
        setSearchNoResult(false);

        const { url: searchUrl } = getLink(
          searchLinks,
          ApiRels.SEARCH.SEARCH_RESULT
        );

        const searchRequest = await makeApiCall({
          url: searchUrl + encodeURIComponent(selected.text),
        });

        // No Search Result Case
        setSearchNoResult(hasNoResults(searchRequest.rails));

        setSearchKey(selected.text);
        setRailList((_oldData) =>
          cleanFromResultsComponent(searchRequest.rails)
        );
      })();
    },
    [searchLinks]
  );

  /**
   * OnEnterPress - Rail Item
   *
   * @param {object} selected - selected item
   */
  const onEnterPressRailItem = useCallback((selected) => {
    // is livetv?
    // is the rail item is ViewAll?
    if (prefix === 'livetv-raillist') {
      navigate(NavigationRoutes.live);
    } else if (selected?.viewAll) {
      // NOTE: FlatList cannot change numColumns value at runtime. so it was replaced with navigation.replace method
      replaceNavigate(NavigationRoutes.viewAll, {
        url: selected?.url,
      });
    } else {
      // TODO: check useCallback usage in here
      const { url } = getLink(selected.item, ApiRels.SELF);

      navigate(NavigationRoutes.contentdetail, {
        url: url,
        contentType: selected?.item?.contentType?.type || 1,
      });
    }
  }, []);

  // onBecameFocused of Button List
  const onBecameFocused = useCallback((_coordinate, selected) => {
    suggestionListRef?.current?.scrollToIndex({
      index: selected.index,
      animated: true,
    });
  }, []);

  /**
   * onScrollToIndexFailedHandler
   */
  const onScrollToIndexFailedHandler = useCallback(
    ({ index, averageItemLength }) => {
      railListRef?.current?.scrollToOffset({
        offset: index * averageItemLength,
        animated: true,
      });

      setTimeout(() => {
        railListRef?.current?.scrollToIndex({
          index: index,
          animated: true,
        });
      }, 100);
    },
    []
  );

  /**
   * Render Item of Button List
   *
   * @param {object} props - props
   * @param {object} props.item - item
   * @param {object} props.index - item
   * @returns {object} - object
   */
  const renderItem = ({ item, index }) => (
    <BasicButton
      key={index}
      text={item}
      index={index}
      size={buttonSize.medium}
      focusKey={`button-list-${index}`}
      upFocusKey={index === 0 ? `keyboard-42` : `button-list-${index - 1}`}
      downFocusKey={
        index === buttonList.length - 1 ? false : `button-list-${index + 1}`
      }
      leftFocusKey={'sidebar'}
      rightFocusKey={'search-page-raillist'}
      themeFocused={buttonThemes.primary}
      onEnterPress={onEnterPressButonList}
      onBecameFocused={onBecameFocused}
      style={[styles.buttonListItems, { ...item.style }]}
    />
  );

  return (
    <View style={styles.container}>
      {isLoading && <Skeleton />}
      <View style={styles.leftArea}>
        <Keyboard
          limit={24}
          value={searchKey}
          setValue={setSearchKey}
          prefix={`keyboard`}
          focusKey={'keyboard'}
          containerStyle={styles.keyboardContainer}
          upEdgeFocusKey={false}
          leftEdgeFocusKey={'sidebar'}
          downEdgeFocusKey={'button-list-0'}
          rightEdgeFocusKey={`${prefix}-raillist`}
        />
        <View style={styles.suggestions}>
          <LinearGradient
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0 }}
            colors={[colors.shades100, colors.transparent]}
            style={styles.gradientVertical}
          />
          <FlatList
            data={buttonList}
            renderItem={renderItem}
            keyExtractor={(_, index) => index.toString()}
            contentContainerStyle={{}}
            ref={suggestionListRef}
            showsHorizontalScrollIndicator={false}
            removeClippedSubviews={false}
            scrollEnabled={false}
            showsVerticalScrollIndicator={false}
            windowSize={6}
          />
        </View>
      </View>
      <View style={styles.rightArea}>
        <View style={styles.inputArea}>
          <View style={styles.icon}>
            <Icon
              icon={SearchMobileTabStroke}
              width={38}
              height={38}
              color={colors.neutral400}
            />
          </View>
          <Text
            text={searchKey ? searchKey : 'Matches, movies, series and more'}
            size={FONT_SIZES.HEADING2}
            color={colors.neutral400}
            style={searchKey && { color: colors.shades00 }}
          />
        </View>
        {searchNoResult && (
          <SearchNoResults
            railType={searchNoResult.railType}
            dataURL={searchNoResult?.links[0]?.href}
          />
        )}
        <View style={styles.railListContainer}>
          {railList && (
            <RailList
              prefix={`${prefix}-raillist`}
              focusKey={`${prefix}-raillist`}
              content={railList}
              railListRef={railListRef}
              leftFocusKey={'keyboard-42'}
              onEnterPress={onEnterPressRailItem}
              onScrollToIndexFailedHandler={onScrollToIndexFailedHandler}
            />
          )}
        </View>
      </View>
    </View>
  );
};

SearchView.propTypes = PageContentPropTypes;
SearchView.whyDidYouRender = false;

export default memo(withFocusable()(SearchView));
