import { useCallback } from 'react';
import {
  getBreakPoint,
  itemMargin,
  padding,
  calculateForRTL,
  windowWidth,
  paddingBottom,
} from '../helpers/CommonHelper';
import useLocalization from '../libs/localization/useLocalization';

/**
 * useTransition Hook
 *
 * @returns {object} - object
 */
const useTransition = () => {
  const { isRTL } = useLocalization();

  const borderTransition = useCallback(
    ({
      width,
      px,
      py,
      selected,
      contentLength,
      railConfig,
      distanceFromEdge,
      // added for search-page carousel position
      startPositionOnPage = 0,
      itemGap = false,
      railWidth = false,
    }) => {
      const numberWidth = railConfig?.position?.start ?? 0;

      if (!selected) {
        return isRTL
          ? px +
              width +
              padding +
              numberWidth -
              windowWidth +
              startPositionOnPage
          : px - padding - numberWidth - startPositionOnPage;
      }

      const selectItemGap = itemGap ? itemGap : itemMargin;
      const itemWidth = width + selectItemGap;
      const { breakpoint, decimal } = getBreakPoint(
        contentLength,
        itemWidth,
        railWidth
      );

      if (selected.index === breakpoint && numberWidth !== 0) {
        return isRTL
          ? calculateForRTL(distanceFromEdge - numberWidth)
          : distanceFromEdge;
      }

      // BUG: temp condition for is last item equal to breakpoint
      // GroupCard
      if (selected.index === breakpoint && breakpoint === contentLength) {
        console.info(
          'This condition is temporary for now to GroupCard. If you see this message for any other rail, please check the breakpoint calculation.'
        );

        const _position = distanceFromEdge + width * decimal - padding * 1.5;

        return isRTL ? calculateForRTL(_position - numberWidth) : _position;
      }

      if (selected.index === 0 || selected.index <= breakpoint) {
        return isRTL
          ? calculateForRTL(distanceFromEdge - numberWidth)
          : distanceFromEdge;
      }

      return isRTL
        ? px + width + numberWidth + padding - windowWidth + startPositionOnPage
        : px - padding - startPositionOnPage;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const borderYTransition = useCallback(
    ({ height, contentLength, selected }) => {
      if (selected && selected.index < contentLength / 2) {
        return 0;
      }

      return height + paddingBottom;
    },
    []
  );

  return { borderTransition, borderYTransition };
};

export default useTransition;
