import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

const indexStyles = StyleSheet.create({
  container: {
    height: GetScaledValue(588),
    width: GetScaledValue(380),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: GetScaledValue(380),
    height: GetScaledValue(80),
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: GetScaledValue(20),
    borderRadius: GetScaledValue(12),
  },
});

/**
 * Returns the styles for an item based on the focus state.
 *
 * @param {boolean} focused - Indicates whether the item is focused.
 * @param {boolean} longPress - Indicates whether the item is being long pressed.
 * @param {number} index - The index of the item.
 * @returns {object} - The styles for the item.
 */
const itemStyles = (focused, longPress, index) => {
  return StyleSheet.create({
    item: {
      height: GetScaledValue(70),
      width: GetScaledValue(60),
      justifyContent: 'center',
      alignItems: 'center',
      opacity: !focused && longPress ? 0.5 : 1,
    },
    textArea: {
      marginStart: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
    longItem: {
      height: GetScaledValue(70),
      width: GetScaledValue(60),
      marginRight: GetScaledValue(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
    longItemContainer: {
      zIndex: 2,
      position: 'absolute',
      flexDirection: 'row',
      top: GetScaledValue(Math.floor(index / 6) * 74 - 80),
      left: GetScaledValue((index % 6) * 64),
    },
  });
};

export { indexStyles, itemStyles };
