import { StyleSheet, View } from 'react-native';
import React from 'react';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import useLocalization from '../../../libs/localization/useLocalization';

/**
 *
 * @param {object} props -
 * @param {string} props.label -
 * @param {string} props.value -
 * @param {boolean} props.fakeFocus -
 * @param {object} props.inputContainer -
 * @param {number} props.maxChar -
 * @returns {module:JSX.Element} -
 */
const FakeInput = ({ label, value, fakeFocus, inputContainer, maxChar }) => {
  const { isRTL } = useLocalization();
  const borderColor = fakeFocus ? colors.primary500 : colors.neutral400;
  const flexDirection = isRTL ? 'row-reverse' : 'row';

  return (
    <View style={styles.container}>
      <Text
        text={label}
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.HEADING2}
      />
      <View style={[inputContainer, { borderColor }]}>
        <Text
          text={value}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          size={FONT_SIZES.HEADING2}
        />
      </View>
      <View style={[styles.infoContainer, { width: inputContainer?.width }]}>
        <Text
          text={`Max. ${maxChar} character`}
          fontFamily={FONT_FAMILY.REGULAR}
          size={FONT_SIZES.HEADING5}
          color={colors.neutral400}
        />
        <View style={{ flexDirection }}>
          <Text
            text={value?.length || 0}
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING5}
            color={colors.neutral200}
          />
          <Text
            text={` / ${maxChar}`}
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING5}
            color={colors.neutral400}
          />
        </View>
      </View>
    </View>
  );
};

export default FakeInput;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    gap: GetScaledValue(20),
  },
  infoContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
