import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

/**
 * useHardwareBackPress custom hook
 *
 * @param {Function} backAction - function to call when the back button is pressed
 * @param {boolean} preventDefault - whether to prevent the default action from firing
 */
export const useHardwareBackPress = (backAction, preventDefault = false) => {
  /**
   * handles the back action
   *
   * @returns {boolean} - true to prevent the default action from firing
   */
  const handleBackAction = useCallback(
    (event) => {
      if (event.keyCode === 8) {
        backAction();

        return preventDefault;
      }
    },
    [backAction, preventDefault]
  );

  useFocusEffect(
    useCallback(() => {
      window.addEventListener('keydown', handleBackAction);

      return () => {
        window.removeEventListener('keydown', handleBackAction);
      };
    }, [handleBackAction])
  );
};
