import React from 'react';
import { View } from 'react-native';
import { itemStyles as style } from './styles';
import { KeyboardData } from './data';
import KeyButton from '../../molecules/buttons/KeyButton';

/**
 * Renders an item in the keyboard component.
 *
 * @param {object} props - The component props.
 * @param {object} props.item - The item data.
 * @param {number} props.index - The index of the item.
 * @param {string} props.prefix - The prefix for the focus keys.
 * @param {boolean} props.caps - Flag indicating if caps lock is enabled.
 * @param {string} props.rightEdgeFocusKey - The focus key of the right edge.
 * @param {string} props.leftEdgeFocusKey - The focus key of the left edge.
 * @param {string} props.downEdgeFocusKey - The focus key of the down edge.
 * @param {string} props.upEdgeFocusKey - The focus key of the up edge.
 * @param {object} props.keyboardIconsAndStyles - The icons and styles for the keyboard.
 * @param {boolean} props.longPressFlag - Flag indicating if long press is active.
 * @param {number} props.currentIndex - The index of the currently focused item.
 * @param {Function} props.onLongSelect - The callback function for long select.
 * @param {Function} props.onEnterPress - The callback function for enter press.
 * @param {Function} props.onDonePress - The callback function for done press.
 * @param {Function} props.onBecameFocused - The callback function for when the item becomes focused.
 * @returns {module:JSX.Element} The rendered item component.
 */
const Item = ({
  item,
  index,
  prefix,
  caps,
  rightEdgeFocusKey,
  leftEdgeFocusKey,
  downEdgeFocusKey,
  upEdgeFocusKey,
  keyboardIconsAndStyles,
  longPressFlag,
  currentIndex,
  onLongSelect,
  onEnterPress,
  onDonePress,
  onBecameFocused,
}) => {
  const keyboardData = KeyboardData(
    prefix,
    rightEdgeFocusKey,
    leftEdgeFocusKey
  );
  const specialKeys = keyboardData.special;
  const upThreshold = keyboardData.upThreshold;
  const downThreshold = keyboardData.downThreshold;

  const text = keyboardIconsAndStyles[item.label]
    ? keyboardIconsAndStyles[item.label]?.text
    : caps
    ? item.label.toUpperCase()
    : item.label;

  const styles = style(currentIndex === index, longPressFlag, index);

  return (
    <>
      <KeyButton
        label={item.label}
        text={text}
        index={index}
        style={[styles.item, keyboardIconsAndStyles[item.label]?.style]}
        focusKey={`${prefix}-${index}`}
        rightFocusKey={
          specialKeys[index]?.rightFocusKey
            ? specialKeys[index]?.rightFocusKey
            : index % 6 === 5
            ? rightEdgeFocusKey
              ? rightEdgeFocusKey
              : `${prefix}-${index - 5}`
            : `${prefix}-${index + 1}`
        }
        leftFocusKey={
          specialKeys[index]?.leftFocusKey
            ? specialKeys[index]?.leftFocusKey
            : index % 6 === 0
            ? leftEdgeFocusKey
              ? leftEdgeFocusKey
              : `${prefix}-${index + 5}`
            : `${prefix}-${index - 1}`
        }
        downFocusKey={
          index + upThreshold < downThreshold
            ? !isNaN(specialKeys[index]?.downFocusKey)
              ? `${prefix}-${specialKeys[index].downFocusKey}`
              : `${prefix}-${index + upThreshold}`
            : downEdgeFocusKey
            ? downEdgeFocusKey
            : `${prefix}-${specialKeys[index].downFocusKey}`
        }
        upFocusKey={
          index - upThreshold >= 0
            ? !isNaN(specialKeys[index]?.upFocusKey)
              ? `${prefix}-${specialKeys[index].upFocusKey}`
              : `${prefix}-${index - upThreshold}`
            : upEdgeFocusKey
            ? upEdgeFocusKey
            : `${prefix}-${specialKeys[index].upFocusKey}`
        }
        theme={
          currentIndex === index && longPressFlag ? 'blackWhite' : 'neutral800'
        }
        themeFocused={'blackWhite'}
        iconSize={keyboardIconsAndStyles[item.label]?.size}
        icon={keyboardIconsAndStyles[item.label]?.icon}
        textSize={keyboardIconsAndStyles[item.label]?.textSize}
        onEnterPress={onEnterPress}
        longSelectFocusKey={onLongSelect}
        onBecameFocused={onBecameFocused}
      />
      {currentIndex === index && longPressFlag && (
        <View style={styles.longItemContainer}>
          {item.longPress.map((key, idx) => (
            <KeyButton
              text={caps ? key.toUpperCase() : key}
              key={idx}
              focusKey={`longbutton-${idx}`}
              style={styles.longItem}
              theme={'neutral800'}
              themeFocused={'primary'}
              rightFocusKey={
                idx + 1 < item.longPress.length
                  ? `longbutton-${idx + 1}`
                  : false
              }
              leftFocusKey={idx > 0 ? `longbutton-${idx - 1}` : false}
              downFocusKey={`${prefix}-${index}`}
              onEnterPress={onEnterPress}
            />
          ))}
        </View>
      )}
    </>
  );
};

export default Item;
