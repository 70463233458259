import React, { memo } from 'react';

import { View } from 'react-native';

import { PlayerWidgetPropTypes } from './proptypes';
import styles from './styles';
import VodPlayer from '../../atoms/VodPlayer';
import LivePlayer from '../../atoms/LivePlayer';

/**
 * Player Widget
 *
 * @param {object} props - props
 * @param {object} props.source - source
 * @param {string} props.streamFormat - streamFormat
 * @param {object} props.drmData - drmData
 * @param {Function} props.onDurationChange - on duration change callback -> seekableDuration: number
 * @param {Function} props.onCurrentTimeUpdate - on current time update callback -> currentTime: number
 * @param {Function} props.onLoad - on load callback -> { currentPosition: number, duration: number, naturalSize: object, audioTracks: array, textTracks: array, videoTracks: array }
 * @param {object} props.refPlayer - reference to the player, to use seek method you should use like refPlayer.current.seek(seconds)
 * @param {object} props.onBufferHandler - on Buffer Handler
 * @param {object} props.content - isTrailer
 * @param {boolean} props.showStatistics - showStatistics
 * @returns {module:JSX.Element} - JSX.Element
 */
const PlayerWidget = ({
  source,
  streamFormat = 'DASH',
  drmData,
  onDurationChange,
  onCurrentTimeUpdate,
  onLoad,
  refPlayer,
  onBufferHandler,
  content,
  showStatistics,
}) => {
  return (
    <>
      {showStatistics ? (
        <View style={styles.statisticsView}>
          {content.contentType.vodType === 'VOD' && (
            <VodPlayer
              id={'player-for-video-screen'}
              onLoad={onLoad}
              refPlayer={refPlayer}
              onCurrentTimeUpdate={onCurrentTimeUpdate}
              onDurationChange={onDurationChange}
              onBufferHandler={onBufferHandler}
              source={source}
            />
          )}
          {content.contentType.vodType === 'LIVE' && (
            <LivePlayer
              id={'player-for-video-screen'}
              onLoad={onLoad}
              refPlayer={refPlayer}
              onCurrentTimeUpdate={onCurrentTimeUpdate}
              source={source}
            />
          )}
        </View>
      ) : (
        <View style={styles.view}>
          {content.contentType.vodType === 'VOD' && (
            <VodPlayer
              id={'player-for-video-screen'}
              onLoad={onLoad}
              refPlayer={refPlayer}
              onCurrentTimeUpdate={onCurrentTimeUpdate}
              onDurationChange={onDurationChange}
              onBufferHandler={onBufferHandler}
              source={source}
            />
          )}
          {content.contentType.vodType === 'LIVE' && (
            <LivePlayer
              id={'player-for-video-screen'}
              onLoad={onLoad}
              refPlayer={refPlayer}
              onCurrentTimeUpdate={onCurrentTimeUpdate}
              source={source}
            />
          )}
        </View>
      )}
    </>
  );
};

PlayerWidget.whyDidYouRender = false;
PlayerWidget.propTypes = PlayerWidgetPropTypes;

export default memo(PlayerWidget);
