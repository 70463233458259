import PropTypes from 'prop-types';

export default {
  text: PropTypes.string,
  focused: PropTypes.bool,
  icon: PropTypes.any,
  iconSize: PropTypes.object,
  textSize: PropTypes.object,
  theme: PropTypes.string,
  themeFocused: PropTypes.string,
  style: PropTypes.any,
};
