import React, { memo, useEffect, useState } from 'react';
import { View } from 'react-native';
import Text from '../../atoms/Text';

import colors from '../../../helpers/Colors';

import styles from './styles';
import PropList from './proptypes';
import { FONT_SIZES } from '../../../helpers/Enums';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * Search No Results
 *
 * @param {object} props - props
 * @param {string} props.railType - rail type
 * @param {string} props.dataURL - data url
 * @returns {module:JSX.Element} - JSX.Element
 */
const SearchNoResults = ({ railType, dataURL }) => {
  const [searchResultData, setSearchResultData] = useState({
    title: '',
    subTitle: '',
  });

  // Fetch
  useEffect(() => {
    (async () => {
      if (dataURL) {
        const response = await makeApiCall({ url: dataURL });

        setSearchResultData(response);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [railType]);

  return (
    <View style={styles.message}>
      <Text
        text={searchResultData?.title || ''}
        bold
        size={FONT_SIZES.HEADING2}
        color={colors.neutral400}
        style={styles.messageTitle}
      />
      <Text
        text={searchResultData?.subTitle || ''}
        size={FONT_SIZES.HEADING3}
        color={colors.neutral400}
        style={styles.messageParagraph}
      />
    </View>
  );
};

SearchNoResults.propTypes = PropList;
SearchNoResults.whyDidYouRender = false;

export default memo(SearchNoResults);
