import I18n from 'i18n-js';

const missingTranslationRegex = /^\[missing ".*" translation\]$/;

/**
 *This function is a wrapper to avoid exception wich leads in a crash
 *
 * @param {string} initialMessage - massage
 * @param {string} options - options
 * @returns {string} - return
 */
const translate = (initialMessage, options) => {
  if (typeof initialMessage !== 'string') {
    __DEV__ &&
      console.info(
        `I18n: you must give a string to translate instead of "${typeof initialMessage}"`
      );

    return '';
  }

  /**
   * localMessage
   *
   * @param {string} initialMessage
   * @param {string} options
   */
  const localMessage = I18n.t(initialMessage, options);

  /**
   *This if statement is a wrapper to detect the missing translation.
   */
  if (missingTranslationRegex.test(localMessage)) {
    __DEV__ &&
      console.info(
        `translation "${initialMessage}" does not exists in translations files`
      );

    return initialMessage;
  }

  return localMessage;
};

export default translate;
