import PropTypes from 'prop-types';

export const PageContentPropTypes = {
  contentSource: PropTypes.object,
  prefix: PropTypes.string,
  apiKey: PropTypes.string,
  leftFocusKey: PropTypes.string,
};

export const ItemPropTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  railType: PropTypes.string,
  focused: PropTypes.bool,
  posterWidth: PropTypes.number,
  posterHeight: PropTypes.number,
};

export const PageRailListPropTypes = {
  content: PropTypes.array,
  onEnterPress: PropTypes.func,
  rightFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leftFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  upFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  downFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
