import { GetScaledValue } from '@digiturk/screen-size/src';
import { StyleSheet } from 'react-native';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';

const styles = StyleSheet.create({
  page: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    paddingBottom: GetScaledValue(60),
  },
  buttonContainer: {
    paddingBottom: GetScaledValue(8),
  },
  button: {
    width: GetScaledValue(480),
    height: GetScaledValue(70),
    borderRadius: GetScaledValue(12),
    alignSelf: 'center',
    flexDirection: 'row',
  },
  titleOptions: {
    fontFamily: FONT_FAMILY.SEMIBOLD,
    size: FONT_SIZES.HEADING1,
  },
});

export default styles;
