import { useCallback, useRef } from 'react';
import { GetScaledValue } from '@digiturk/screen-size';
import {
  useAnimatedStyle,
  withTiming,
  useSharedValue,
} from 'react-native-reanimated';
import { isAnimationsDisabled } from '../helpers/CommonHelper';

/**
 * FlatList Vertical Animation
 *
 * @param {Function} globalFocusedItem - globalFocusedItem
 * @returns {object} - return object
 */
const useFlatlistVerticalAnimation = (globalFocusedItem) => {
  const railTop = GetScaledValue(globalFocusedItem?.railTop || 635);

  const railListRef = useRef();
  const scrollY = useSharedValue(0);

  const animatedTop = useAnimatedStyle(() => {
    const paddingTop = isAnimationsDisabled
      ? railTop
      : withTiming(railTop, { duration: 750 });

    return { paddingTop };
  }, [railTop]);

  /**
   * onScrollToIndexFailedHandler
   */
  const onScrollToIndexFailedHandler = useCallback(
    ({ index, averageItemLength }) => {
      railListRef?.current?.scrollToOffset({
        offset: index * averageItemLength,
        animated: true,
      });

      setTimeout(() => {
        railListRef?.current?.scrollToIndex({
          index: index,
          animated: true,
        });
      }, 100);
    },
    []
  );

  return {
    animatedTop,
    railListRef,
    scrollY,
    onScrollToIndexFailedHandler,
  };
};

export default useFlatlistVerticalAnimation;
