import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import SportsProgressBar from '../../atoms/SportsProgressBar';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

/**
 * StatsList
 *
 * @param {any} props - props
 * @param {string} props.team - team data
 * @param {string} props.logo - team logo
 * @param {object} props.stats - team Stats
 * @returns {module:JSX.Element} - JSX.Element
 */
const StatList = ({ team, logo, stats }) => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image source={logo} style={styles.logo} />
        <Text style={styles.headerText}>{team}</Text>
      </View>
      <View>
        {stats.map((stat, index) => (
          <SportsProgressBar
            key={index}
            label={stat.label}
            value={stat.value}
            maxValue={stat.maxValue}
          />
        ))}
      </View>
    </View>
  );
};

/**
 * Team Stats
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const TeamStats = () => {
  const teams = [
    {
      team: 'Real Madrid',
      logo: '',
      stats: [
        { label: 'Ball Position %', value: 35, maxValue: 100 },
        { label: 'Total Shots', value: 7, maxValue: 10 },
        { label: 'Shots On Target', value: 2, maxValue: 10 },
        { label: 'Good Chances', value: 0, maxValue: 10 },
        { label: 'Corners', value: 6, maxValue: 10 },
        { label: 'Offsides', value: 3, maxValue: 10 },
        { label: 'Saves', value: 3, maxValue: 10 },
        { label: 'Fouls Committed', value: 7, maxValue: 10 },
        { label: 'Yellow Card', value: 2, maxValue: 10 },
        { label: 'Red Card', value: 0, maxValue: 10 },
      ],
    },
    {
      team: 'Barcelona',
      logo: '',
      stats: [
        { label: 'Ball Position %', value: 35, maxValue: 100 },
        { label: 'Total Shots', value: 7, maxValue: 10 },
        { label: 'Shots On Target', value: 2, maxValue: 10 },
        { label: 'Good Chances', value: 0, maxValue: 10 },
        { label: 'Corners', value: 6, maxValue: 10 },
        { label: 'Offsides', value: 3, maxValue: 10 },
        { label: 'Saves', value: 3, maxValue: 10 },
        { label: 'Fouls Committed', value: 7, maxValue: 10 },
        { label: 'Yellow Card', value: 2, maxValue: 10 },
        { label: 'Red Card', value: 0, maxValue: 10 },
      ],
    },
  ];

  return (
    <View style={styles.contentContainer}>
      {teams.map((teamData, index) => (
        <StatList
          key={index}
          team={teamData.team}
          logo={teamData.logo}
          stats={teamData.stats}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    padding: 20,
    marginHorizontal: 10,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  logo: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
  },
  contentContainer: {
    padding: 20,
    backgroundColor: colors.shades100,
    display: 'flex',
    flexDirection: 'row',
    width: GetScaledValue(720),
  },
});

export default TeamStats;
