import React, { useRef } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import HighlightBubble from '../../molecules/HighlightBubble';
import { buttonThemes } from '../../molecules/buttons';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Highlights
 *
 * @returns {React.JSX.Element} - JSX.Element
 */
const Highlights = () => {
  const flatListRef = useRef(null);

  const data = Array.from({ length: 12 }, (_, i) => ({
    key: `focusKey-${i + 1}`,
    theme: buttonThemes.neutral500,
    themeFocused: buttonThemes.primary,
    focusKey: `focusKey-${i + 1}`,
    downFocusKey: `focusKey-${i + 2}`,
    upFocusKey: i > 0 ? `focusKey-${i}` : 'Highlights',
  }));

  /**
   * Flat list scroll controller
   *
   * @param {any} index - index
   */
  const handleFocus = (index) => {
    if (flatListRef.current) {
      flatListRef.current.scrollToIndex({ index, animated: true });
    }
  };

  /**
   * Flat list render
   *
   * @param {object} props - props
   * @param {any} props.item - render item
   * @param {number} props.index - index
   * @returns {React.JSX.Element} - returns flatlist items
   */
  const renderItem = ({ item, index }) => {
    return <HighlightBubble {...item} onFocus={() => handleFocus(index)} />;
  };

  return (
    <FlatList
      ref={flatListRef}
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => item.key}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.statisticsArea}
      scrollEnabled={false}
    />
  );
};

const styles = StyleSheet.create({
  statisticsArea: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: GetScaledValue(40),
  },
});

export default Highlights;
