import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import {
  getCurrentFocusKey,
  setFocusCustom,
} from '../../../helpers/FocusHelper';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { getId, resetId, exitApp } from '../../../helpers/CommonHelper';
import useLocalization from '../../../libs/localization/useLocalization';
import { IndexPropTypes } from './proptypes';
import styles from './styles';
import PageContent from '../../templates/PageContent';
import FocusableSidebar from '../../templates/Sidebar';

/**
 * Creates a tv shows screen view
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const Sports = ({ route }) => {
  const [date, setDate] = useState(0);
  const lastFocusKey = useRef();
  const { languageDirection } = useLocalization();

  useEffect(() => {
    if (route?.params?.date) {
      setDate(route?.params?.date);
    }
  }, [route]);

  /**
   * on navigate back method
   */
  const onNavigateBack = () => {
    if (getCurrentFocusKey()?.indexOf('sidebar') === -1) {
      const currentId = getId(getCurrentFocusKey());

      if (currentId != null) {
        if (parseInt(currentId, 10) > 0) {
          lastFocusKey.current = resetId(getCurrentFocusKey());
          setFocusCustom(lastFocusKey.current);
        } else {
          setFocusCustom('sidebar');
        }
      } else {
        setFocusCustom('sidebar');
      }
    } else exitApp();
  };

  useHardwareBackPress(() => {
    onNavigateBack();
  }, true);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`sports-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'sports-content'}
        onNavigateBack={onNavigateBack}
      />
      <PageContent
        key={'sports'}
        apiKey={'Sports'}
        prefix={'sports-raillist'}
        focusKey={'sports-content'}
        leftFocusKey={'sidebar'}
      />
    </View>
  );
};

Sports.propTypes = IndexPropTypes;
Sports.whyDidYouRender = false;

export default Sports;
