import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

/**
 *
 * @param {boolean} focused -
 * @param {boolean} hasValue -
 * @param {boolean} isWrong -
 * @returns {module:JSX.StyleSheet} -
 */
const styles = (focused, hasValue, isWrong) =>
  StyleSheet.create({
    container: {
      backgroundColor: colors.transparent,
      width: GetScaledValue(100),
      aspectRatio: 1,
      borderRadius: GetScaledValue(15),
      borderWidth: GetScaledValue(2),
      borderColor: isWrong
        ? 'red'
        : focused
        ? colors.primary500
        : hasValue
        ? colors.neutral400
        : colors.neutral600,
      justifyContent: 'center',
      alignItems: 'center',
    },
    pipe: {
      width: GetScaledValue(2),
      height: GetScaledValue(48),
      borderRadius: GetScaledValue(4),
      backgroundColor: colors.shades00,
    },
  });

export default styles;
