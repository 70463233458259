import React, { useMemo } from 'react';
import GenericModal from '../../../molecules/GenericModal';
import useCustomNavigation from '../../../../hooks/useCustomNavigation';
import styles from './style';
import NavigationRoutes from '../../../../navigation/NavigationRoutes';
import { useRoute } from '@react-navigation/native';
import { profileActions, useProfile } from '../../../../context';
import { useHardwareBackPress } from '../../../../hooks/useHardwareBackPress';
import { makeApiCall } from '../../../../middleware/dynamic';
import { getLink } from '../../../../helpers/CommonHelper';
import { ApiRels } from '../../../../helpers/Enums';

/**
 * Delete Profile
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Delete = () => {
  const { currentProfileId } = useRoute().params;
  const { profileState, profileDispatch } = useProfile();
  const { navigate, goBack } = useCustomNavigation();

  useHardwareBackPress(() => {
    goBack();
  }, true);

  const currentProfile = useMemo(() => {
    return profileState?.profiles.find(
      (p) => p.userProfileId === currentProfileId
    );
  }, [profileState, currentProfileId]);

  const buttons = [
    {
      text: 'Cancel',
      onEnterPress: () => goBack(),
    },
    {
      text: 'Delete',
      onEnterPress: async () => {
        const { url, method } = getLink(
          currentProfile,
          ApiRels.PROFILE.DELETE_PROFILE
        );

        const response = await makeApiCall({
          url,
          method,
        });

        if (response) {
          profileDispatch(profileActions.deleteProfile(currentProfileId));
          navigate(NavigationRoutes.profiles, {}, 'profile-0');
        } else goBack();
      },
    },
  ];

  return (
    <GenericModal
      title={`Are you sure you want to delete ${currentProfile?.profileName}'s profile?`}
      message="If you delete the profile, you will lose access to downloaded videos. This action can't be undone."
      buttons={buttons}
      highlightedText={`${currentProfile?.profileName}'s`}
      textContainerHeader={styles.textContainerHeader}
      textContainerContent={styles.textContainerContent}
      buttonStyle={styles.buttonStyle}
      buttonContainerStyle={styles.buttonContainerStyle}
      pageStyle={styles.page}
      highlightedTextStyle={styles.highlightedTextStyle}
      titleOptions={styles.titleOptions}
      contentOptions={styles.contentOptions}
      highlightedTextOptions={styles.highlightedTextOptions}
    />
  );
};

export default Delete;
