import React, { memo } from 'react';
import { Image as RNImage } from 'react-native';

import PropList from './proptypes';

/**
 * Image
 *
 * @param {object} props - props
 * @param {string|number} props.source - image source
 * @param {number} props.asset - asset
 * @param {number} props.resizeMode - resize mode
 * @param {boolean} props.blur - blur
 * @param {object} props.style - style
 * @param {string} props.bgColor - bgColor
 * @param {Function} props.onError - onError
 * @returns {React.JSX.Element} - JSX.Element
 */
const Image = ({
  source,
  asset = false,
  resizeMode = 'contain',
  blur = false,
  bgColor = false,
  onError = () => false,
  style,
}) => {
  return (
    <RNImage
      source={
        asset
          ? source
          : {
              uri: source,
            }
      }
      resizeMode={resizeMode}
      style={[bgColor && { backgroundColor: bgColor }, style]}
      blurRadius={blur ? 10 : 0}
      onError={onError}
      // onLoadEnd={onLoadEnd}
    />
  );
};

Image.whyDidYouRender = false;
Image.propTypes = PropList;

export default memo(Image);
