import { useState, useCallback, useEffect } from 'react';

const config = {
  eachTriggerPoint: 2,
};

/**
 * Lazy Load On FlatList Custon Hook
 *
 * @param {Array} baseRailList - baseRailList
 * @param {string} prefix - prefix
 * @returns {object} - object
 */
const useLazyLoadOnFlatlist = (baseRailList, prefix) => {
  const [dynamicRailList, setDynamicRailList] = useState([]);
  const [focusIndex, setFocusIndex] = useState(0);

  const railFocusIndexSetter = useCallback((data) => {
    setFocusIndex(data);
  }, []);

  // First rail list data
  useEffect(() => {
    // Is baseRailList ready
    if (baseRailList.length > 0) {
      setDynamicRailList(baseRailList.slice(0, config.eachTriggerPoint + 2));
    }
  }, [baseRailList]);

  // Other lazy-load rail list datas
  useEffect(() => {
    // Up and down limits of list
    if (focusIndex > 0 && focusIndex < baseRailList.length) {
      // Is index on a triggerpoint and
      // Has the triggerpoint worked before
      if (
        focusIndex % config.eachTriggerPoint === 0 &&
        !(dynamicRailList.length > focusIndex + 2)
      ) {
        setDynamicRailList((oldArray) => [
          ...oldArray,
          ...baseRailList.slice(
            focusIndex + 2,
            focusIndex + 2 + config.eachTriggerPoint
          ),
        ]);
      }
    }
  }, [focusIndex, baseRailList.length]);

  return {
    dynamicRailList,
    railFocusIndexSetter,
  };
};

export default useLazyLoadOnFlatlist;
