import React, { memo, Suspense, useMemo } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import { getLink } from '../../../helpers/CommonHelper';
import useLocalization from '../../../libs/localization/useLocalization';
import SelectedSectionComponent from '../../molecules/SelectedComponent/Section';
import Text from '../../atoms/Text';

import style from './styles';
import { ApiRels } from '../../../helpers/Enums';

/**
 * Renders a section component.
 *
 * @param {object} props - The component props.
 * @param {object} props.item - The item object.
 * @param {number} props.index - The index of the item.
 * @param {string} props.focusKeyPrefix - The focusKeyPrefix string.
 * @param {Function} props.setGlobalFocusedItem - The setGlobalFocusedItem function.
 * @param {Function} props.setImage - The setImage function.
 * @param {string|boolean} props.hasFocusedChild - hasFocusedChild
 * @param {number} props.contentLength - The content length.
 * @returns {module:JSX.Element} The rendered section component.
 */
const Section = ({
  item,
  index,
  focusKeyPrefix,
  contentLength,
  setGlobalFocusedItem,
  setImage,
  hasFocusedChild,
}) => {
  const { url } = getLink(item, ApiRels.SELF);
  const { isRTL } = useLocalization();

  const distanceFromEdge = GetScaledValue(140);
  const styles = style(distanceFromEdge, hasFocusedChild, item.railType);

  const SelectedComponent = useMemo(
    () =>
      SelectedSectionComponent[item.railType] ||
      SelectedSectionComponent.default,
    [item.railType]
  );

  const upFocusKey = index > 0 ? `${focusKeyPrefix}-${index - 1}` : false;
  const downFocusKey =
    index < contentLength - 1 ? `${focusKeyPrefix}-${index + 1}` : false;

  return (
    <View style={styles.sectionContainer}>
      <Suspense fallback={<Text text={'loading'} />}>
        <SelectedComponent
          index={index}
          dataURL={url}
          listStyle={styles.sectionListStyle}
          distanceFromEdge={distanceFromEdge}
          focusKeyPrefix={`${focusKeyPrefix}-${index}`}
          focusKey={`${focusKeyPrefix}-${index}`}
          setGlobalFocusedItem={setGlobalFocusedItem}
          upFocusKey={upFocusKey}
          downFocusKey={downFocusKey}
          setImage={setImage}
          railType={item.railType}
          contentContainerStyle={
            isRTL
              ? styles.sectionContentContainerStyleRTL
              : styles.sectionContentContainerStyle
          }
        />
      </Suspense>
    </View>
  );
};

export default memo(withFocusable({ trackChildren: true })(Section));
