import React, { useRef, useState } from 'react';
import { FlatList, View } from 'react-native';

import styles from './style';

import Item from './item';
import { Substitution } from '../../../assets/icons';
import { GetScaledValue } from '@digiturk/screen-size';
import { withFocusable } from '@digiturk/react-spatial-navigation';

/**
 * Event Boxes
 *
 * @param {any} commentaries - commentaries
 * @returns {module:JSX.Element} - JSX.Element
 */
export const EventBoxes = ({ commentaries }) => {
  const sliderRef = useRef();
  const [focusedIndex, setFocusedIndex] = useState(0);

  /**
   * Flat list scroll controller
   *
   * @param {any} index - index
   */
  const handleFocus = (index) => {
    setFocusedIndex(index);
    if (sliderRef.current) {
      const offset = GetScaledValue(180) * index;

      sliderRef.current.scrollToOffset({
        offset,
        animated: true,
      });
    }
  };

  /**
   * Render Item
   *
   * @param {object} props - props
   * @param {object} props.item - item
   * @param {object} props.index - item
   * @returns {object} - object
   */
  const renderItem = ({ item, index }) => (
    <View>
      <Item
        unfocusedText={item.time}
        text={item.comment}
        icon={Substitution}
        focusKey={item.focusKey}
        onBecameFocused={() => handleFocus(index)}
        index={item.index}
        rightFocusKey={`button${index + 1}`}
        leftFocusKey={index === 0 ? false : `button${index - 1}`}
        upFocusKey="progress-bar"
        downFocusKey="statistics-screen"
        isFocused={index === focusedIndex}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <FlatList
        ref={sliderRef}
        horizontal={true}
        data={commentaries}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={false}
        keyExtractor={(_, index) => index.toString()}
      />
    </View>
  );
};

EventBoxes.whyDidYouRender = false;

export default withFocusable()(EventBoxes);
