import React from 'react';
import { StyleSheet, Text as RNText, Platform } from 'react-native';
import PropList from './proptypes';
import colors from '../../../helpers/Colors';
import { getOrganizeStyles, isWeb } from '../../../helpers/CommonHelper';
import { GetScaledValue } from '@digiturk/screen-size';
import { FONT_VALUES, FONT_SIZES, FONT_FAMILY } from '../../../helpers/Enums';
import useLocalization from '../../../libs/localization/useLocalization';

/**
 * Text (Atom)
 *
 * @param {object} props - props
 * @param {string|number|object} props.text - text
 * @param {string} props.color - color
 * @param {boolean} props.bold - bold
 * @param {boolean} props.italic - italic
 * @param {string} props.transform - italic
 * @param {string} props.size - size
 * @param {string} props.align - align
 * @param {boolean} props.multiline - multiline
 * @param {number} props.numberOfLines - number of lines
 * @param {number} props.style - style
 * @param {number} props.fontFamily - fontFamily
 * @param {number} props.padding - Padding
 * @returns {module:JSX.Element} - JSX.Element
 */
const Text = ({
  text,
  color,
  bold,
  italic,
  size,
  transform,
  align,
  multiline,
  numberOfLines,
  style = null,
  fontFamily,
  padding,
}) => {
  const { isRTL, languageDirection } = useLocalization();

  // NOTE: shouldn't set the "fontWeight" for Android
  if (style?.fontWeight) {
    getOrganizeStyles(style);
  }

  const styles = StyleSheet.create({
    text: [
      {
        color: color,
        textTransform: transform ? transform : null,
        fontStyle: italic ? 'italic' : 'normal',
        textAlign: align ? align : 'left',
        fontSize: GetScaledValue(FONT_VALUES[size].FONT_SIZE),
        lineHeight: GetScaledValue(FONT_VALUES[size].LINE_HEIGHT),
        fontFamily: fontFamily || FONT_FAMILY.REGULAR,
        textAlignVertical: 'center',
        includeFontPadding: false,
        writingDirection: languageDirection,
        // NOTE: there is diff between Android TV & Apple TV when use special font
        paddingTop: Platform.OS === 'android' ? 2 : 0,
        paddingBottom: Platform.OS === 'android' ? 2 : 0,
        padding: padding,
      },
      bold && { fontFamily: FONT_FAMILY.SEMIBOLD },
      isRTL && isWeb && { textAlign: 'right' },
    ],
  });

  return (
    <RNText
      style={[styles.text, style]}
      multiline={multiline}
      numberOfLines={numberOfLines}>
      {text}
    </RNText>
  );
};

Text.defaultProps = {
  color: colors.shades00,
  bold: false,
  italic: false,
  size: FONT_SIZES.PARAGRAPH1,
  multiline: false,
  numberOfLines: 1,
  fontFamily: FONT_FAMILY.REGULAR,
};

Text.whyDidYouRender = false;
Text.propTypes = PropList;

export default React.memo(Text);
