const TYPES = {
  INIT: 'INIT',
  SET_PROFILES: 'SET_PROFILES',
  SET_SELECTED_PROFILE: 'SET_SELECTED_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  DELETE_PROFILE: 'DELETE_PROFILE',
  ADD_PROFILE: 'ADD_PROFILE',
  ERROR: 'ERROR',
};

export default TYPES;
