import { BackHandler, Platform } from 'react-native';
import {
  GetScaledValue,
  GetWindowHeight,
  GetWindowWidth,
} from '@digiturk/screen-size';
import RNRestart from 'react-native-restart';
import { FONT_FAMILY, SPACING_VALUES } from '../helpers/Enums';
import urlDecode from '../libs/urlDecode';

export const sidebarWidth = GetScaledValue(200);
export const borderWidth = GetScaledValue(6);
export const borderRadius = GetScaledValue(SPACING_VALUES.SBIG);
export const itemMargin = GetScaledValue(SPACING_VALUES.SLG);
export const marginEnd = GetScaledValue(68);
export const padding = GetScaledValue(SPACING_VALUES.SSM);
export const titleArea = GetScaledValue(84);
export const titleHeight = GetScaledValue(60);
export const titleSpace = titleArea - titleHeight;
export const isWeb = Platform.OS === 'web';
export const marginBottom = GetScaledValue(30);
export const paddingBottom = GetScaledValue(SPACING_VALUES.SLG);
export const windowWidth = GetWindowWidth();
export const windowHeight = GetWindowHeight();
export const isAnimationsDisabled = false;
export const SEEK_VALUE = 10;
export const railItemBorderGap = 20;

/**
 * CalculateTimeLeft
 * <p>Calculates remaining time until given date </p>
 *
 * @param {string} startDate - givenDate
 * @returns {object} - returns date as days hours minutes and seconds
 */
export const calculateTimeLeft = (startDate) => {
  const now = new Date().getTime();
  const targetDate = new Date(startDate).getTime();
  const timeDifference = targetDate - now;

  if (timeDifference <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

/**
 * Exit from the application method
 */
export const exitApp = () => {
  if (Platform.OS !== 'web') {
    BackHandler.exitApp();
  } else {
    // TODO: check if this works properly on Smart TVs
    window.close();
  }
};

/**
 * Reload application method
 */
export const reloadApp = () => {
  if (Platform.OS === 'web') {
    window.location.reload(false);
  } else {
    RNRestart.Restart();
  }
};

/**
 * replace item index with value 1 for an id
 *
 * @param {string} value - value
 * @returns {string} - updated id
 */
export const resetId = (value) => {
  return value.replace(/[0-9]+(?!.*[0-9])/, function (match) {
    return 0;
  });
};

/**
 * get id from an indexed value
 *
 * @param {string} value - value
 * @returns {string} - id
 */
export const getId = (value) => {
  return value.match(/[0-9]+(?!.*[0-9])/)?.[0];
};

/**
 *
 * @param {string} hexCode - color
 * @param {string} factor - rate of luminance
 * @returns {any} -
 */
export const adjustColor = (hexCode, factor) => {
  if (!hexCode) return;
  // Hex kodunu R, G, B bileşenlerine ayırma
  var r = parseInt(hexCode.slice(1, 3), 16);
  var g = parseInt(hexCode.slice(3, 5), 16);
  var b = parseInt(hexCode.slice(5, 7), 16);

  // Koyulaştırma veya aydınlatma faktörü uygulama
  r = Math.round(r * factor);
  g = Math.round(g * factor);
  b = Math.round(b * factor);

  // Değerleri sınırlama (0-255 aralığına)
  r = Math.min(Math.max(r, 0), 255);
  g = Math.min(Math.max(g, 0), 255);
  b = Math.min(Math.max(b, 0), 255);

  // Yeni renk kodunu oluşturma
  var adjustedHexCode =
    '#' + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return adjustedHexCode;
};

/**
 * @param {string|number} iconPath - hex or uri
 * @returns {object} -
 */
export const handleDecideColorOrImage = (iconPath) => {
  if (iconPath === '' || !iconPath) return { color: '#ffbc00' };
  else if (iconPath.length === 6) {
    return isHex(iconPath) ? { color: '#' + iconPath } : { color: '#ffbc00' };
  } else if (iconPath[0] === '#') return { color: iconPath };
  else return { iconPath };
};

/**
 *
 * @param {string} string -
 * @returns {boolean} - isHex
 */
export const isHex = (string) => {
  for (var i = 0; i < string.length; i++) {
    var char = string[i];

    if (
      !(
        (char >= '0' && char <= '9') ||
        (char >= 'A' && char <= 'F') ||
        (char >= 'a' && char <= 'f')
      )
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Create Key-Value object from Array
 *
 * @param {Array} data - array
 * @returns {object} - object
 */
export const getAssignArrayToObject = (data) => {
  return Object.getOwnPropertyNames(data).reduce(
    (a, v) => ({ ...a, [v]: v }),
    {}
  );
};

/**
 *
 * @param {number} itemLength - length of list
 * @param {number} itemWidth - width of item
 * @param {number|boolean} railWidth - width of rail
 * @returns {number} - breakpoint of scrollToIndex
 */
export const getBreakPoint = (itemLength, itemWidth, railWidth) => {
  const width = railWidth ? railWidth : windowWidth - sidebarWidth;
  const lengthWithViewAll = itemLength + 1;

  const viewableItemCount = width / itemWidth;
  const integer = Math.floor(viewableItemCount);
  const decimal = viewableItemCount % 1;

  const breakpoint = lengthWithViewAll - integer;

  return { breakpoint, decimal };
};

/**
 * Get Rail Item Configurations
 *
 * @param {string} railType - rail type
 * @returns {object} - width height object
 */
export const getRailItemConfigurations = (railType) => {
  const defaultWidth = 240;
  const defaultHeight = 320;
  const defaultHeroHeight = 630;
  const defaultRailTop = 530;

  const rails = {
    Slider: {
      width: 380,
      height: 126,
      heroHeight: 720,
      railTop: 640,
      title: false,
    },
    BoxSets: {
      width: 170,
      height: 340,
      heroHeight: 630,
      railTop: 530,
    },
    MyList: {
      width: 240,
      height: 360,
      heroHeight: 660,
      railTop: 580,
    },
    SuperHeroes: {
      width: 380,
      height: 214,
      heroHeight: 630,
      railTop: 530,
    },
    Competitions: {
      width: 240,
      height: 240,
      heroHeight: 720,
      railTop: 530,
    },
    Channels: {
      width: 240,
      height: 240,
      heroHeight: 0,
      railTop: 50,
    },
    FootballCompetitions: {
      width: 240,
      height: 240,
      heroHeight: 720,
      railTop: 450,
    },
    Sports: {
      width: 380,
      height: 285,
      heroHeight: 720,
      railTop: 530,
    },
    TrendingOnTod: {
      width: 520,
      height: 260,
      heroHeight: 630,
      railTop: 530,
    },
    NewOnTod: {
      width: 240,
      height: 360,
      heroHeight: 630,
      railTop: 530,
    },
    DontMissOut: {
      width: 240,
      height: 360,
      heroHeight: 630,
      railTop: 530,
    },
    WhatsOnNow: {
      width: 520,
      height: 376,
      heroHeight: 630,
      railTop: 530,
    },
    ContinueWatching: {
      width: 380,
      height: 277,
      heroHeight: 720,
      railTop: 618,
    },
    LiveAndUpcoming: {
      width: 520,
      height: 318,
      heroHeight: 720,
      railTop: 610,
    },
    Tournaments: {
      width: 520,
      height: 264,
      heroHeight: 630,
      railTop: 530,
    },
    Football: {
      width: 520,
      height: 324,
      heroHeight: 630,
      railTop: 530,
    },
    tod360: {
      width: 520,
      height: 324,
      heroHeight: 630,
      railTop: 530,
    },
    Showcase: {
      width: 380,
      height: 160,
      position: {
        top: GetScaledValue(52.5),
        zIndex: 0,
      },
      heroHeight: 720,
      railTop: 635,
      title: false,
    },
    TopTenMoviesByGenreWithRegion: {
      width: 380,
      height: 213,
      position: { top: GetScaledValue(120) },
      heroHeight: 720,
      railTop: 630,
    },
    TopTenMoviesByRegionForToday: {
      width: 380,
      height: 214,
      position: { start: GetScaledValue(140) },
      heroHeight: 630,
      railTop: 530,
    },
    TopTenMoviesByCountryForToday: {
      width: 240,
      height: 360,
      position: { start: GetScaledValue(140) },
      heroHeight: 630,
      railTop: 530,
    },
    ComingSoon: {
      width: 380,
      height: 760,
      heroHeight: 0,
      railTop: 30,
    },
    ContentFilmDetail: {
      width: 1920,
      height: 1080,
      heroHeight: 0,
    },
    season: {
      width: 395,
      height: 94,
      railTop: 282,
    },
    episode: {
      width: 380,
      height: 214,
      railTop: 436,
    },
    MovieCollection: {
      heroHeight: 0,
      railTop: 0.1,
      width: 380,
      height: 214,
      position: { top: titleHeight },
    },
    MovieStarCollection: {
      heroHeight: 0,
      railTop: 0.1,
      width: 240,
      height: 360,
      position: { top: titleHeight },
    },
    SerieCollection: {
      heroHeight: 0,
      railTop: 0.1,
      width: 380,
      height: 285,
      position: { top: titleHeight },
    },
    CompetitionCollection: {
      heroHeight: 0,
      railTop: 1,
      width: 240,
      height: 240,
      position: { top: titleHeight },
    },
    TodOriginals: {
      width: 240,
      height: 360,
      heroHeight: 630,
      railTop: 530,
    },
    GroupCard: {
      width: 1080,
      height: 416,
    },
    SearchHistory: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    SearchSports: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    SearchTop: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    SearchMovies: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    SearchTvShows: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
  };

  const getWidth = rails[railType] ? rails[railType].width : defaultWidth;
  const getHeight = rails[railType] ? rails[railType].height : defaultHeight;
  const getPosition = rails[railType] ? rails[railType].position : {};
  const getHeroHeight = rails[railType]
    ? rails[railType].heroHeight
    : defaultHeroHeight;
  const getRailTop = rails[railType] ? rails[railType].railTop : defaultRailTop;
  const getTitle = rails[railType]?.title ?? true;

  return {
    width: getWidth,
    height: getHeight,
    position: getPosition,
    heroHeight: getHeroHeight,
    railTop: getRailTop,
    hasTitle: getTitle,
  };
};

/**
 * Check if the section is full screen
 *
 * @param {string} sectionType - section type
 * @returns {boolean} - return is full screen
 */
export const isFullScreenSection = (sectionType) => {
  const fullScreenSections = [
    'MoreLikeThis',
    'ContentSeriesSeasonsDetail',
    'FootballHero',
    'HeaderHero',
    'ContentSeriesDetail',
    'ContentFilmDetail',
  ];

  return fullScreenSections.includes(sectionType);
};

/**
 * Check if the rail type is valid
 *
 * @param {string} railType - The rail type to check
 * @returns {boolean} - Returns true if the rail type is valid
 */
export const isValidRailType = (railType) => {
  const validRailTypes = [
    'MoreLikeThis',
    'ContentSeriesDetail',
    'ContentFilmDetail',
    'ContentSeriesSeasonsDetail',
    'FootballHero',
    'HeaderHero',
    'FootballLiveStandings',
    'FootballHead2Head',
    'FootballLineups',
    'FootballLiveStats',
    'FootballStats',
    'FootballKeyPlayers',
    'FootballSubstitutes',
    'FootballMatchStats',
    'Tournaments',
    'TournamentRanking',
    'LiveSportsOnNow',
    'WhatsOnNow',
    'Football',
    'TOD360',
    'NewOnTod',
    'Competitions',
  ];

  return validRailTypes.includes(railType);
};

/**
 * Get Organize Text Styles
 *
 * @param {object} originalStyle - style
 * @returns {object} - edited style
 */
export const getOrganizeStyles = (originalStyle) => {
  // A copy was created for the object cannot be extended error
  const style = { ...originalStyle };

  // NOTE: shouldn't set "fontWeight" for Android, changed fontType
  delete style?.fontFamily;

  switch (style?.fontWeight) {
    case 100:
      style.fontFamily = FONT_FAMILY.THIN;
      break;
    case 200:
      style.fontFamily = FONT_FAMILY.EXTRA_LIGHT;
      break;
    case 300:
      style.fontFamily = FONT_FAMILY.LIGHT;
      break;
    case 400:
      style.fontFamily = FONT_FAMILY.REGULAR;
      break;
    case 500:
      style.fontFamily = FONT_FAMILY.MEDIUM;
      break;
    case 600:
      style.fontFamily = FONT_FAMILY.SEMIBOLD;
      break;
    case 700:
      style.fontFamily = FONT_FAMILY.BOLD;
      break;
    case 800:
      style.fontFamily = FONT_FAMILY.EXTRA_BOLD;
      break;
    case 900:
      style.fontFamily = FONT_FAMILY.BLACK;
      break;
    default:
      style.fontFamily = FONT_FAMILY.BLACK;
      break;
  }

  delete style.fontWeight;

  return style;
};

/**
 * Converts values for right-to-left mode
 *
 * @param {number} data - Value to be scaled
 * @returns {number} - Scaled value
 */
export const calculateForRTL = (data) => {
  return data * -1;
};

/**
 * Get links from the response based on the specified criteria.
 *
 * @param {object} response - The response object.
 * @param {string} rel - The rel value to match.
 * @param {string} key - The key to filter the response by.
 * @param {string} value - The value to match for the specified key.
 * @returns {string} - The URL matching the specified criteria.
 */
export const getLink = (response, rel, key, value) => {
  try {
    if (!key) {
      const { href: url, method } = response.links.find(
        (item) => item.rel === rel
      );

      return { url, method };
    }

    const filteredItem = response.find((item) => item[key] === value);
    const { href: url, method } = filteredItem.links.find(
      (item) => item.rel === rel
    );

    return { url, method };
  } catch (error) {
    // unless it can't find anything
    return false;
  }
};

/**
 * Get links from the response based on the specified criteria or only for given object.
 *
 * @param {object} response - The response object.
 * @param {string} key - The key to filter the response by.
 * @param {string} value - The value to match for the specified key.
 * @returns {Array} - The URLs matching the specified criteria.
 */
export const getRelLinks = (response, key, value) => {
  const filteredItem = key
    ? response.find((item) => item[key] === value)
    : response;

  const links = filteredItem.links.map((item) => {
    return { [item.rel]: item.href };
  });

  return links;
};

/**
 * Get the image link from the response based on the specified criteria.
 *
 * @param {object[]} item - The item object.
 * @param {string} railType - The rail type to get the image for.
 * @returns {string} - The URL of the first image matching the specified criteria.
 */
export const getImageLink = (item, railType) => {
  if (!item) return '';

  if (
    item.media &&
    Object.values(item.media)[0]?.hasOwnProperty('noResizeImageUrl')
  ) {
    return urlDecode(Object.values(item.media)[0].noResizeImageUrl);
  }

  if (!item.image) return '';

  if (Object.values(item.image)[0]?.hasOwnProperty('noResizeImageUrl')) {
    return urlDecode(Object.values(item.image)[0].noResizeImageUrl);
  }

  const { width, height } = getRailItemConfigurations(railType);

  const urlObj = item?.image?.hasOwnProperty('thumbnail')
    ? item?.image?.thumbnail
    : Object.values(item?.image)[0];

  const link = `https://tod-preprod.enhance.diagnal.com/resources/images/link/${urlObj.id}/${urlObj.imageId}/${urlObj.timeStamp}/0:0:1000000:1000000/${width}*${height}/${urlObj.imageFullName}`;

  return urlDecode(link);
};

/**
 *
 * @param {data} data - The item object.
 * @returns {object} - The URL of the first image matching the specified criteria.
 */
export const updateDataWithImage = (data) => {
  const updatedData = {
    ...data,
    image: {
      hero_16x9:
        data.hero_5x2 || data.hero_8x2 || data.hero_8x3 || data.hero_3x1,
    },
  };

  return updatedData;
};

let trailerObject = null;

/**
 * Trailer object
 *
 * @returns {object} - get and set method
 */
export const trailer = {
  get: () => trailerObject,
  set: (data) => {
    trailerObject = data;
  },
};

/**
 * Generate a random number between the specified minimum and maximum values.
 *
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} - The generated random number.
 */
export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Show or Hide the score of the match.
 *
 * @param {boolean} spoiler - Spoiler status of the match.
 * @param {number} homeTeam - Home team's score.
 * @param {number} visitorTeam - Visitor team's score.
 * @returns {string} - The score of the match.
 */
export const renderScore = (spoiler, homeTeam, visitorTeam) => {
  const text = `${homeTeam} - ${visitorTeam}`;

  return spoiler ? '-' : text;
};

/**
 * Check if the rail type is a collection
 *
 * @param {string} railType - The rail type to check
 * @returns {boolean} - Returns true if the rail type is a collection
 */
export const railIsCollection = (railType) => {
  return railType?.includes('Collection');
};
