import React, { useEffect } from 'react';
import { View, Image } from 'react-native';
import styles from './styles';
import Text from '../../atoms/Text';
import { FONT_SIZES } from '../../../helpers/Enums';
import { withFocusable } from '@digiturk/react-spatial-navigation';

/**
 * Top Players
 *
 * @param {any} props - props
 * @param {boolean} props.focused - focused
 * @param {Array} props.teams - teams data
 * @param {Function} props.onFocus - onFocus
 * @returns {module:JSX.Element} - JSX.Element
 */
const PlayerStatistics = ({ teams, focused, onFocus }) => {
  useEffect(() => {
    if (focused && onFocus) {
      onFocus();
    }
  }, [focused, onFocus]);

  const style = styles(focused);

  return (
    <View style={style.container}>
      <Text size={FONT_SIZES.HEADING3} text={'Top Passes'} align="center" />
      <View style={style.teamContainer}>
        {teams?.map((item, index) => (
          <View key={index} style={style.teams}>
            <View style={style.teamHeader}>
              <Image source={''} style={style.teamLogo} />
              <Text size={FONT_SIZES.HEADING4} text={item.team} />
            </View>
            <Text
              size={FONT_SIZES.HEADING6}
              text={`Total Passes / Accuracy: ${item.totalPasses} / ${item.accuracy}`}
            />
            <View style={style.playerArea}>
              <Text
                text={item.title}
                size={FONT_SIZES.HEADING6}
                style={style.miniHeader}
              />
              {item.players.map((player, idx) => (
                <View key={idx} style={style.playerContainer}>
                  <Text size={FONT_SIZES.HEADING6} text={player.name} />
                  <Text
                    size={FONT_SIZES.HEADING6}
                    text={`${player.passes} / ${player.accuracy}`}
                  />
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default withFocusable()(PlayerStatistics);
