import React, { memo } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import ContentLoader, { Rect } from 'react-content-loader/native';

import useLocalization from '../../../libs/localization/useLocalization';
import colors from '../../../helpers/Colors';

import styles from './styles';

/**
 * Skeleton Loader
 *
 * @param {object} props - props
 * @returns {module:JSX.Element} - JSX.Element
 */
const Skeleton = memo(() => {
  const { isRTL } = useLocalization();

  return (
    <View style={styles.loading}>
      <ContentLoader
        rtl={isRTL}
        speed={1}
        width={GetScaledValue(1920)}
        height={GetScaledValue(1080)}
        backgroundColor={colors.neutral700}
        foregroundColor={colors.neutral500}>
        <Rect
          x="0"
          y="0"
          rx="0"
          ry="0"
          width={GetScaledValue(1920)}
          height={GetScaledValue(630)}
        />
        <Rect
          x={GetScaledValue(210)}
          y={GetScaledValue(640)}
          rx="0"
          ry="0"
          width={GetScaledValue(200)}
          height={GetScaledValue(40)}
        />
        <Rect
          x={GetScaledValue(210)}
          y={GetScaledValue(705)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(126)}
        />
        <Rect
          x={GetScaledValue(630)}
          y={GetScaledValue(705)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(126)}
        />
        <Rect
          x={GetScaledValue(1050)}
          y={GetScaledValue(705)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(126)}
        />
        <Rect
          x={GetScaledValue(1470)}
          y={GetScaledValue(705)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(126)}
        />
        <Rect
          x={GetScaledValue(1890)}
          y={GetScaledValue(705)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(126)}
        />

        <Rect
          x={GetScaledValue(210)}
          y={GetScaledValue(930)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(160)}
        />
        <Rect
          x={GetScaledValue(630)}
          y={GetScaledValue(930)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(160)}
        />
        <Rect
          x={GetScaledValue(1050)}
          y={GetScaledValue(930)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(160)}
        />
        <Rect
          x={GetScaledValue(1470)}
          y={GetScaledValue(930)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(160)}
        />
        <Rect
          x={GetScaledValue(1890)}
          y={GetScaledValue(930)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(160)}
        />
      </ContentLoader>
    </View>
  );
});

export default Skeleton;
