import PropTypes from 'prop-types';

export const IndexPropTypes = {
  route: PropTypes.object.isRequired,
};

export const PageContentPropTypes = {
  leftFocusKey: PropTypes.string,
};

export const PageRailListPropTypes = {
  content: PropTypes.array,
  onEnterPress: PropTypes.func,
  rightFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leftFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  upFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  downFocusKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
