export default [
  {
    format: 'vtt',
    identifier: 'caption_en',
    isDefault: false,
    isForced: false,
    label: 'English',
    language: 'en',
    url: 'https://bitmovin-a.akamaihd.net/content/sintel/subtitles/subtitles_en.vtt',
  },
  {
    format: 'vtt',
    identifier: 'caption_de',
    isDefault: false,
    isForced: false,
    label: 'Deutsh',
    language: 'de',
    url: 'https://bitmovin-a.akamaihd.net/content/sintel/subtitles/subtitles_de.vtt',
  },
  {
    format: 'vtt',
    identifier: 'caption_es',
    isDefault: false,
    isForced: false,
    label: 'Espanol',
    language: 'es',
    url: 'https://bitmovin-a.akamaihd.net/content/sintel/subtitles/subtitles_es.vtt',
  },
  {
    format: 'vtt',
    identifier: 'caption_fr',
    isDefault: false,
    isForced: false,
    label: 'French',
    language: 'fr',
    url: 'https://bitmovin-a.akamaihd.net/content/sintel/subtitles/subtitles_fr.vtt',
  },

  {
    format: 'vtt',
    identifier: 'settings',
    isDefault: false,
    isForced: false,
    label: 'Settings',
    language: '',
    url: '',
  },
];
