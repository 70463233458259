import React, { useState } from 'react';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { CheckBold } from '../../../assets/icons';
import GenericModal from '../../molecules/GenericModal';
import styles from './styles';
/**
 * ModalLanguageSelection Page
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const ModalLanguageSelection = ({ route }) => {
  const { goBack } = useCustomNavigation();

  const buttonList = route.params?.buttonList;
  const setKeyboardLanguage = route.params?.setSelectedLanguage;
  const [selectedLanguage, setSelectedLanguage] = useState(
    route.params?.selected
  );

  // will be refactored
  const Language = {
    en: 'English (EN)',
    ar: 'العربية (AR)',
  };

  useHardwareBackPress(() => {
    goBack();
  }, true);

  /**
   * Handles the logic when the enter key is pressed.
   * If the selected language matches the item, it goes back and sets the focus to the custom prefix.
   * Otherwise, it updates the keyboard language, sets the selected language, and performs any necessary actions.
   *
   * @param {string} item - The language item that was selected.
   * @returns {void}
   */
  const onEnterPress = (item) => {
    if (item === selectedLanguage) {
      goBack();
      setFocusCustom(route.params?.prefix);
    }

    setKeyboardLanguage({ ...selectedLanguage, lang: item });
    setSelectedLanguage(item);
  };

  // Create button objects for GenericModal
  const buttons = buttonList?.map((item, index) => ({
    text: Language[item],
    onEnterPress: () => onEnterPress(item),
    icon: item === selectedLanguage ? CheckBold : null,
    iconPosition: 'right',
  }));

  return (
    <GenericModal
      title={'Choose Keyboard Language'}
      buttons={buttons}
      pageStyle={styles.page}
      buttonContainerStyle={styles.buttonContainer}
      buttonStyle={styles.button}
      textContainerHeader={styles.textContainer}
      titleOptions={styles.titleOptions}
    />
  );
};

export default ModalLanguageSelection;
