import env from '../middleware/services/config';

export const HTTP_URL = 'http://';
export const HTTPS_URL = 'https://';

export const appModeUrl = {
  DISASTER_ENDPOINT: env.SmartTv.EndpointDisaster,
  TEST_MODE: env.SmartTv.EndpointTest,
  DISASTER_PROD_MODE: env.SmartTv.DisasterInfoProd,
  DISASTER_TEST_MODE: env.SmartTv.DisasterInfoTest,
  DISASTER_REG_MODE: env.SmartTv.DisasterInfoTest,
  PROD_MODE: env.SmartTv.Endpoint,
  REG_MODE: env.SmartTv.EndpointReg,
};

export const collectionRailList = [
  'MovieCollection',
  'MovieStarCollection',
  'SerieCollection',
  'CompetitionCollection',
];
