import React from 'react';
import { View } from 'react-native';

import styles from './styles';
import BackgroundBlur from '../../atoms/BackgroundBlur';

/**
 * Modal wrapper
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
const ModalWrapper = ({ children }) => {
  return (
    <View style={styles.modalWrapper}>
      <BackgroundBlur />
      <View style={styles.childrenContainer}>{children}</View>
    </View>
  );
};

export default ModalWrapper;
