import React, { memo } from 'react';
import { View, Text, Image } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import styleFunction from './style';
/**
 * Live
 *
 * @module components/pages/Live
 * @param {any} channel - channel
 * @returns {module:JSX.Element} -
 */
const LiveChannelItem = ({
  channel,
  size,
  focused,
  circle,
  theme,
  themeFocused,
  themeCustom,
}) => {
  const style = styleFunction(
    size,
    focused,
    circle,
    theme,
    themeFocused,
    themeCustom
  );

  return (
    <View style={[style.container, style && style]}>
      <Image style={style.image} source={{ uri: channel.image }} />
      <View>
        <Text style={style.title}>{channel.name}</Text>
        <Text style={style.subTitle}>{channel.programName}</Text>
        <Text>{channel.nextProgramName}</Text>
      </View>
    </View>
  );
};

export default memo(withFocusable()(LiveChannelItem));
