import React from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import Text from '../../../atoms/Text';
import Icon from '../../../atoms/Icon';

import styleFunction from './styles';
import PropList from './proptypes';
import { FONT_SIZES } from '../../../../helpers/Enums';
import colors from '../../../../helpers/Colors';

/**
 * ToolTipButton
 *
 * @param {object} props - props
 * @param {string} props.size - size
 * @param {string} props.text - text
 * @param {boolean} props.focused - focused
 * @param {string} props.icon - icon
 * @param {boolean} props.circle - circle
 * @param {string} props.theme - theme
 * @param {boolean} props.isOpen - isOpen
 * @param {string} props.themeFocused - themeFocused
 * @param {string} props.themeCustom - themeCustom
 * @param {object} props.style - style
 * @param {object} props.textAreaStyle - textAreaStyle
 * @returns {module:JSX.Element} - JSX.Element
 */
const ToolTipButton = ({
  size,
  text,
  focused,
  icon,
  circle,
  theme,
  isOpen,
  themeFocused,
  themeCustom,
  style,
  textAreaStyle,
}) => {
  const styles = styleFunction(
    size,
    focused,
    circle,
    theme,
    themeFocused,
    themeCustom
  );

  return (
    <View style={[styles.container, style && style]}>
      {focused && !isOpen && (
        <View style={textAreaStyle}>
          <Text
            text={text}
            color={colors.shades00}
            size={FONT_SIZES.HEADING4}
          />
        </View>
      )}
      {icon && (
        <View style={styles.iconArea}>
          <Icon icon={icon} {...styles.icon} />
        </View>
      )}
    </View>
  );
};

ToolTipButton.whyDidYouRender = false;
ToolTipButton.propTypes = PropList;

export default withFocusable()(ToolTipButton);
