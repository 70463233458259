import { StyleSheet, View } from 'react-native';
import React from 'react';
import { GetScaledValue } from '@digiturk/screen-size';
import Digit from '../../atoms/Digit';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';

/**
 *
 * @param {object} props - props
 * @param {number} props.digitCount - digitCount
 * @param {boolean} props.hasTitle - hasTitle
 * @param {string} props.pin - pin
 * @param {object} props.containerStyle - containerStyle
 * @param {boolean} props.isWrong - isWrong
 * @returns {module:JSX.Element} -
 */
const PINCode = ({ digitCount, hasTitle, pin, containerStyle, isWrong }) => {
  return (
    <View style={containerStyle}>
      {hasTitle && (
        <Text
          text={'PIN Code'}
          size={FONT_SIZES.HEADING2}
          fontFamily={FONT_FAMILY.REGULAR}
        />
      )}
      <View style={[styles.digitContainer]}>
        {new Array(digitCount).fill(0).map((_, index) => (
          <Digit
            key={index}
            isWrong={isWrong}
            value={pin[index]}
            focused={pin.length === index}
          />
        ))}
      </View>
    </View>
  );
};

export default PINCode;

const styles = StyleSheet.create({
  digitContainer: {
    flexDirection: 'row',
    gap: GetScaledValue(10),
  },
});
