import React, { useState, useCallback, useEffect } from 'react';
import { ImageBackground } from 'react-native';

import urlDecode from '../../../libs/urlDecode';

import productPlaceHolder from '../../../assets/placeholders/product.png';

/**
 * Basic Poster
 *
 * @param {object} props - props
 * @param {string} props.source - source
 * @param {object} props.containerStyle - containerStyle
 * @param {object} props.imageStyle - imageStyle
 * @param {number} props.blurRadius - blurRadius
 * @param {module:JSX.Element} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
const Background = ({
  source,
  containerStyle,
  imageStyle,
  blurRadius = 0,
  children,
}) => {
  const [image, setImage] = useState(productPlaceHolder);

  const decodeImageUrl = urlDecode(source);

  useEffect(() => {
    decodeImageUrl && setImage({ uri: decodeImageUrl });
  }, [decodeImageUrl]);

  /**
   * on load end callback
   */
  const onError = useCallback(() => setImage(productPlaceHolder), []);

  if (image === null) return null;

  return (
    <ImageBackground
      source={image}
      blurRadius={blurRadius}
      style={containerStyle}
      imageStyle={imageStyle}
      onError={onError}>
      {children}
    </ImageBackground>
  );
};

export default React.memo(Background);
