import React from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import Text from '../../../atoms/Text';
import { FONT_SIZES, FONT_FAMILY } from '../../../../helpers/Enums';
import styleFunction from './styles';
import PropList from './proptypes';

/**
 * SelectedButton
 *
 * @param {object} props - props
 * @param {string} props.size - size
 * @param {string} props.text - text
 * @param {boolean} props.focused - focused
 * @param {boolean} props.isSelected - isSelected
 * @param {string} props.theme - theme
 * @param {string} props.themeFocused - theme focus
 * @param {any} props.style - style
 * @returns {module:JSX.Element} - JSX.Element
 */
const SelectedButton = ({
  size,
  text,
  focused,
  isSelected,
  theme,
  themeFocused,
  style,
}) => {
  const styles = styleFunction(size, focused, isSelected, theme, themeFocused);

  return (
    <View style={[styles.container, style && style]}>
      <Text
        text={text}
        style={styles.textUp}
        size={FONT_SIZES.HEADING3}
        fontFamily={FONT_FAMILY.SEMIBOLD}
      />
    </View>
  );
};

SelectedButton.whyDidYouRender = false;
SelectedButton.propTypes = PropList;

export default withFocusable()(SelectedButton);
