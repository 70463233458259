import TYPES from './types';

/**
 * Profile Reducer
 *
 * @param {object} state - state
 * @param {object} action - action type
 * @param {string} action.type - action type
 * @param {object} action.payload - action payload
 * @returns {object} - reducer object
 */
const reducer = (state, { type, payload }) => {
  switch (type) {
    case TYPES.INIT:
      return {
        ...state,
        profileState: {
          ...state.profileState,
          isInitiated: true,
        },
      };

    case TYPES.SET_PROFILES:
      return {
        ...state,
        profileState: {
          ...state.profileState,
          profiles: payload.userProfileList,
          profileLimit: payload.userProfileLimit,
          links: payload.links,
        },
      };

    case TYPES.SET_SELECTED_PROFILE:
      return {
        ...state,
        profileState: {
          ...state.profileState,
          selectedProfile: payload,
        },
      };

    case TYPES.UPDATE_PROFILE:
      const isSelectedProfile =
        state.profileState.selectedProfile.userProfileId ===
        payload.userProfileId;

      return {
        ...state,
        profileState: {
          ...state.profileState,
          selectedProfile: isSelectedProfile
            ? payload
            : state.profileState.selectedProfile,
          profiles: state.profileState.profiles.map((profile) =>
            profile.userProfileId === payload.userProfileId ? payload : profile
          ),
        },
      };

    case TYPES.DELETE_PROFILE:
      return {
        ...state,
        profileState: {
          ...state.profileState,
          profiles: state.profileState.profiles.filter(
            (p) => p.userProfileId !== payload
          ),
          selectedProfile:
            state.profileState.selectedProfile?.userProfileId === payload
              ? null
              : state.profileState.selectedProfile,
        },
      };

    case TYPES.ADD_PROFILE:
      return {
        ...state,
        profileState: {
          ...state.profileState,
          profiles: [...state.profileState.profiles, payload],
        },
      };

    case TYPES.ERROR:
      return {
        ...state,
        profileState: {
          ...state.profileState,
          error: payload,
        },
      };

    default:
      throw new Error();
  }
};

export default reducer;
