import React from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import Text from '../../../atoms/Text';
import Icon from '../../../atoms/Icon';

import styleFunction from './styles';
import PropList from './proptypes';

/**
 * Key Button With Icon Text
 *
 * @param {object} props - props
 * @param {string} props.text - text
 * @param {boolean} props.focused - focused
 * @param {any} props.icon - icon
 * @param {object} props.iconSize - icon size
 * @param {object} props.textSize - text size
 * @param {string} props.theme - theme
 * @param {string} props.themeFocused - color focus
 * @param {object} props.style - style
 * @returns {module:JSX.Element} - JSX.Element
 */
const KeyButton = ({
  text,
  focused,
  icon,
  iconSize,
  textSize,
  theme,
  themeFocused,
  style,
}) => {
  const styles = styleFunction(
    focused,
    iconSize,
    textSize,
    theme,
    themeFocused
  );

  return (
    <View style={[styles.container, style && style]}>
      {icon && (
        <View style={styles.iconArea}>
          <Icon icon={icon} {...styles.icon} />
        </View>
      )}
      {text && (
        <View style={styles.textArea}>
          <Text text={text} style={[styles.text]} />
        </View>
      )}
    </View>
  );
};

KeyButton.whyDidYouRender = false;
KeyButton.propTypes = PropList;

export default withFocusable()(KeyButton);
