import { StyleSheet } from 'react-native';
import {
  GetWindowWidth,
  GetWindowHeight,
  GetScaledValue,
} from '@digiturk/screen-size';

/**
 * PlayerMenuProperties styles
 *
 * @param {object} props - props
 * @param {boolean} props.userActivity - userActivity
 * @param {boolean} props.isPaused - isPaused
 * @returns {object} - styles
 */
const style = ({ userActivity, isPaused }) => {
  return StyleSheet.create({
    page: {
      position: 'absolute',
      justifyContent: 'flex-end',
      width: GetWindowWidth(),
      height: GetWindowHeight(),
    },
    playerMenuContainer: {
      position: 'absolute',
      bottom: GetScaledValue(80),
      left: GetScaledValue(80),
      right: GetScaledValue(80),
      zIndex: 1,
    },
    playerController: {
      marginStart: GetScaledValue(107),
      backgroundColor: 'blue',
    },
    playerProgressBarContainer: {
      marginStart: GetScaledValue(107),
      marginBottom: GetScaledValue(66),
    },
    seasonAndEpisodeTextContainer: {
      flexDirection: 'row',
      position: 'relative',
      gap: 10,
      marginBottom: GetScaledValue(40),
    },
    playerControllerTopBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    controllerButtonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignSelf: 'flex-end',
      gap: GetScaledValue(10),
    },
    backButtonContainer: {
      paddingTop: GetScaledValue(40),
      position: 'relative',
      top: GetScaledValue(40),
    },
    titleTextContainer: {
      position: 'absolute',
    },
    container: {
      flex: 1,
      opacity: userActivity ? 1 : 0,
    },
    nextButtonContainer: {
      marginTop: GetScaledValue(40),
    },
    fourkbutton: {
      gap: GetScaledValue(16),
    },
  });
};

export default style;
