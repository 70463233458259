import { StyleSheet } from 'react-native';

import { themeConfig } from '../configs';
import { FONT_FAMILY } from '../../../../helpers/Enums';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * ProgressBar styles
 *
 * @param {boolean} focused - focused
 * @param {object} iconSize - icon size
 * @param {object} textSize - text style
 * @param {string} theme - theme
 * @param {string} themeFocused - theme focus
 * @returns {object} - styles
 */
const style = (
  focused,
  iconSize,
  textSize,
  theme = 'transparent16',
  themeFocused = theme
) => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      backgroundColor: focused
        ? themeConfig[themeFocused].background
        : themeConfig[theme].background,
      borderRadius: GetScaledValue(12),
      marginBottom: GetScaledValue(4),
    },
    icon: {
      width: iconSize?.width || 32,
      height: iconSize?.height || 32,
      color: focused ? themeConfig[themeFocused].icon : themeConfig[theme].icon,
    },
    text: {
      fontFamily: FONT_FAMILY.REGULAR,
      fontSize: textSize ? textSize.fontSize : GetScaledValue(32),
      lineHeight: textSize ? textSize.lineHeight : GetScaledValue(48),
      marginStart: textSize ? textSize.marginStart : 0,
      color: focused ? themeConfig[themeFocused].text : themeConfig[theme].text,
    },
  });
};

export default style;
