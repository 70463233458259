import { View } from 'react-native';
import React, { useEffect } from 'react';
import Text from '../../atoms/Text';
import PropList from './proptypes';
import styles from './styles';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import Animated, {
  runOnUI,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { isAnimationsDisabled } from '../../../helpers/CommonHelper';

/**
 * AgeContentRating Component
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const AgeContentRating = () => {
  const ratingText =
    'We advise parental guidance.Some content may not be suitable for children';
  const formattedRatingText = ratingText.replaceAll('.', '.\n');

  const opacity = useSharedValue(1);

  useEffect(() => {
    let timeout;

    if (isAnimationsDisabled) {
      timeout = setTimeout(() => {
        opacity.value = 0;
      }, 5000);
    } else {
      runOnUI(() => {
        opacity.value = 0;
      })();
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimationsDisabled]);

  const animatedStyle = useAnimatedStyle(() => {
    const _opacity = isAnimationsDisabled
      ? opacity.value
      : withTiming(opacity.value, { duration: 5000 });

    return { opacity: _opacity };
  }, [opacity]);

  return (
    <Animated.View style={[styles.container, animatedStyle]}>
      <Text
        fontFamily={FONT_FAMILY.SEMIBOLD}
        size={FONT_SIZES.HEADING1}
        text="PG"
      />
      <View style={styles.verticalLine} />

      <View style={styles.ratintText}>
        <Text
          fontFamily={FONT_FAMILY.SEMIBOLD}
          numberOfLines={2}
          size={FONT_SIZES.HEADING6}
          text={formattedRatingText}
        />
      </View>
    </Animated.View>
  );
};

AgeContentRating.propTypes = PropList;
export default React.memo(AgeContentRating);
