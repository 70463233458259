import { View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import ProfileWrapper from '../../templates/ProfileWrapper';
import { ApiRels, ProfileTypes, SectionTypes } from '../../../helpers/Enums';
import SelectedChildren from './SelectedChildren';
import Language from './language';
import { translate } from '@digiturk/i18n';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { profileActions, useProfile } from '../../../context';
import styles from './styles';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { getLink } from '../../../helpers/CommonHelper';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * Add New Profile
 *
 * @returns {module:JSX.Element} -
 */
const AddProfile = () => {
  const { navigate } = useCustomNavigation();
  const { profileState, profileDispatch } = useProfile();
  const [profile, setProfile] = useState({
    profileName: 'New Profile',
    profileType: ProfileTypes.STANDART,
    hideScores: false,
  });
  const [selectedComponent, setSelectedComponent] = useState(
    SectionTypes.Default
  );

  useHardwareBackPress(() => {
    if (selectedComponent === SectionTypes.Main) {
      navigate(NavigationRoutes.profiles, {}, 'profile-0');
    } else setSelectedComponent(SectionTypes.Main);
  }, true);

  const Children = useMemo(
    () => SelectedChildren[selectedComponent],
    [selectedComponent]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSelectedComponent(SectionTypes.Main);
    }, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onEnterPress = useCallback(async () => {
    const { url, method } = getLink(profileState, ApiRels.PROFILE.SAVE_PROFILE);

    const response = await makeApiCall({
      url,
      method,
      body: profile,
    });

    if (response) {
      const { url: nextLink } = getLink(response, ApiRels.PROFILE.GET_PROFILES);

      profileDispatch(
        profileActions.addProfile({
          ...profile,
          userProfileId: response.userProfileId,
        })
      );

      navigate(NavigationRoutes.profiles, { url: nextLink }, 'profile-0');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const onSelectHandler = useCallback((_selectedComponent, updatedObject) => {
    if (updatedObject) {
      setProfile((prev) => ({
        ...prev,
        ...updatedObject,
      }));
    }

    _selectedComponent && setSelectedComponent(_selectedComponent);
  }, []);

  return (
    <View style={styles.container}>
      <ProfileWrapper profile={profile}>
        <Children
          isEdit={false}
          prefix={'add'}
          currentProfile={profile}
          onSelectHandler={onSelectHandler}
        />
      </ProfileWrapper>
      {selectedComponent === SectionTypes.Main && (
        <BasicButton
          text={translate(Language.ADD_NEW_PROFILE_ADD_BUTTON)}
          size={buttonSize.big}
          focusKey="add-new-profile-button"
          upFocusKey={'add-button-3'}
          theme={buttonThemes.neutral800}
          themeFocused={buttonThemes.primary}
          style={styles.addButton}
          onEnterPress={onEnterPress}
        />
      )}
    </View>
  );
};

export default AddProfile;
