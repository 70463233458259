import { StyleSheet, View } from 'react-native';
import React from 'react';
import { Pencil } from '../../../assets/icons';
import { GetScaledValue } from '@digiturk/screen-size';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import Icon from '../../atoms/Icon';
import colors from '../../../helpers/Colors';

/**
 *
 * @param {object} props -
 * @param {boolean} props.focused -
 * @param {boolean} props.isAddProfile -
 * @returns {module:JSX.Element} -
 */
const EditIcon = ({ focused, isAddProfile }) => {
  const backgroundColor = focused ? colors.shades00 : colors.neutral800;

  if (isAddProfile) return null;

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <Icon icon={Pencil} width={30} height={30} />
    </View>
  );
};

export default withFocusable()(EditIcon);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: GetScaledValue(60),
    width: GetScaledValue(60),
    bottom: GetScaledValue(-30),
    alignSelf: 'center',
    borderRadius: GetScaledValue(10),
  },
});
