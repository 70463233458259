import PropTypes from 'prop-types';

export const PageContentPropTypes = {
  contentSource: PropTypes.object,
  prefix: PropTypes.string,
  apiKey: PropTypes.string,
  leftFocusKey: PropTypes.string,
};

export const ItemPropTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  railType: PropTypes.string,
  focused: PropTypes.bool,
  posterWidth: PropTypes.number,
  posterHeight: PropTypes.number,
};
