import { StyleSheet } from 'react-native';
import { GetScaledValue, GetWindowWidth } from '@digiturk/screen-size';
import { SPACING_VALUES } from '../../../helpers/Enums';
import {
  padding,
  sidebarWidth,
  titleSpace,
} from '../../../helpers/CommonHelper';

/**
 * carousel list style
 *
 * @param {object} railConfig - railConfig
 * @returns {object} - object
 */
export const carouselStyles = (railConfig) => {
  const topPosition = railConfig?.position?.top || 0;

  return StyleSheet.create({
    carouselArea: {},
    listStyle: {
      paddingVertical: padding,
      paddingStart: sidebarWidth + padding,
      width: GetWindowWidth(),
    },
    railContainer: {
      height: GetScaledValue(railConfig?.height) + topPosition + titleSpace,
    },
  });
};

/**
 * carousel poster style
 *
 * @param {boolean} focused - focus
 * @param {number} posterWidth - poster width
 * @param {number} posterHeight - poster height
 * @returns {object} - object
 */
export const posterStyles = (focused, posterWidth, posterHeight) =>
  StyleSheet.create({
    posterArea: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      zIndex: 1,
    },
    posterItem: {
      width: GetScaledValue(posterWidth),
      height: GetScaledValue(posterHeight),
      borderRadius: GetScaledValue(SPACING_VALUES.SBIG),
    },
  });
