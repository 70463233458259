import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import { isWeb } from '../../../helpers/CommonHelper';

/**
 * ProgressBar styles
 *
 * @param {number} bufferProgress - bufferProgress
 * @param {number} progress - progress
 * @param {string} color - color
 * @param {string} backgroundColor - backgroundColor
 * @param {number} height - height
 * @param {number} borderRadius - borderRadius
 * @param {boolean} isRTL - is RTL
 * @returns {object} - styles
 */
const style = (
  bufferProgress,
  progress,
  color,
  backgroundColor,
  height,
  borderRadius,
  isRTL
) => {
  return StyleSheet.create({
    floor: {
      flexDirection: isRTL ? 'row-reverse' : 'row',
      justifyContent: 'center',
      height: height ? GetScaledValue(height) : GetScaledValue(3),
      backgroundColor: backgroundColor || colors.gray140,
      borderRadius: GetScaledValue(borderRadius) || 0,
    },
    fill: {
      flexGrow: progress || 0,
      backgroundColor: color || colors.error,
      borderRadius: GetScaledValue(borderRadius) || 0,
    },
    buffer: {
      width: bufferProgress ? `${bufferProgress}%` : 0,
      height: height ? GetScaledValue(height) : GetScaledValue(3),
      backgroundColor: colors.neutral200,
    },
    space: {
      flexGrow: progress ? GetScaledValue(100 - progress) : 100,
    },
    controller: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      zIndex: 1,
    },
    thumbnailView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      backgroundColor: colors.neutral700,
      borderRadius: GetScaledValue(20),
      borderColor: colors.neutral400,
      borderWidth: 1,
      width: GetScaledValue(150),
      height: GetScaledValue(60),
      justifyContent: 'center',
      position: 'absolute',
      bottom: GetScaledValue(30),
    },
    point: {
      position: 'absolute',
      height: GetScaledValue(30),
      width: GetScaledValue(30),
      backgroundColor: colors.shades100,
      borderColor: colors.primary,
      borderWidth: isWeb ? GetScaledValue(9) : GetScaledValue(4),
      borderRadius: GetScaledValue(65),
      bottom: isWeb ? GetScaledValue() : GetScaledValue(-10),
    },
  });
};

export default style;
