import React, { memo, Suspense, useMemo } from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import posterComponentsMap from '../../molecules/SelectedComponent/poster';
import Text from '../../atoms/Text';

import { ItemPropTypes } from './proptypes';
import { posterStyles } from './styles';

/**
 * Render Item
 *
 * @param {object} props - props
 * @param {object} props.item - item
 * @param {number} props.index - index
 * @param {string} props.railType - railType
 * @param {boolean} props.focused - focused
 * @param {number} props.posterWidth - poster width
 * @param {number} props.posterHeight - poster height
 * @returns {module:JSX.Element} - JSX.Element
 */
const Item = ({
  item,
  index,
  railType,
  focused,
  posterWidth,
  posterHeight,
}) => {
  const SelectedComponent = useMemo(
    () => posterComponentsMap[railType] || posterComponentsMap.default,
    [railType]
  );

  const style = posterStyles(posterWidth, posterHeight);

  return (
    <View style={style.posterArea}>
      <Suspense fallback={<Text text={'loading'} />}>
        <SelectedComponent
          item={item}
          index={index}
          posterWidth={posterWidth}
          posterHeight={posterHeight}
          imageStyle={style.posterItem}
        />
      </Suspense>
    </View>
  );
};

Item.propTypes = ItemPropTypes;
Item.whyDidYouRender = false;

export default memo(withFocusable()(Item));
