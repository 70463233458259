import React, { useEffect, useState, useCallback } from 'react';
import { View } from 'react-native';
import { ArrowDownBold } from '../../../assets/icons';

import EventBoxes from '../../organisms/EventBoxes';
import {
  isLongPressActive,
  setFocusCustom,
} from '../../../helpers/FocusHelper';

import LivePlayerProgressBar from '../../organisms/LivePlayerProgressBar';

import PlayerTopContainer from '../../molecules/PlayerTopContainer';
import Overlay from '../PlayerMenuProperties/Overlay';
import ControllerContainer from '../PlayerMenuProperties/ControllerContainer';
import styles from './styles';
import PauseButton from '../PlayerMenuProperties/PauseButton';
import { BasicButton, buttonThemes } from '../../molecules/buttons';
import axios from 'axios';
import { SEEK_VALUE } from '../../../helpers/CommonHelper';

const channelListData = [
  {
    id: '1',
    name: 'Bein HD',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: true,
  },
  {
    id: '2',
    name: 'Bein Sports',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: false,
  },
  {
    id: '3',
    name: 'Bein Sports 2',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: false,
  },
  {
    id: '4',
    name: 'Bein Sports 2',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: false,
  },
];

/**
 * Property Element List
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {object} props.refPlayer - reference to player
 * @param {boolean} props.isPaused - isPaused
 * @param {object} props.userActivity - userActivity
 * @param {Array} props.audioList - audio list
 * @param {object} props.contentVod - audio list
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {boolean} props.isTrackLive - isTrackLive
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.setShowStatisticsCallback - setShowStatisticsCallback
 * @returns {module:JSX.Element} - JSX.Element
 */
const ElementList = ({
  videoType,
  totalTime,
  currentTime,
  refPlayer,
  isPaused,
  userActivity,
  audioList,
  contentVod,
  subtitleSettingsCallback,
  isTrackLive,
  onEnterPress,
  setShowStatisticsCallback,
}) => {
  const opacity = userActivity ? 1 : 0;
  const buttonPrefix = 'live-player-activity-button-';

  const [commentaries, setCommentaries] = useState([]);
  const [mutatedCurrentTime, setMutatedCurrentTime] = useState(currentTime);

  useEffect(() => {
    setFocusCustom('progress-bar');
  }, []);

  /**
   * Add index and focus key
   *
   * @param {any} data - index
   * @returns {object} - object
   */
  const addIndexAndFocusKey = useCallback((data) => {
    return data.map((comment, index) => ({
      ...comment,
      index: index,
      focusKey: `button${index}`,
    }));
  }, []);

  useEffect(() => {
    axios
      .get('https://caladan.tod2-test.beiniz.biz/api/v2/play/commentary')
      .then((response) => {
        setCommentaries(addIndexAndFocusKey(response.data.data.CommentInfo));
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEnterGoLiveButton = useCallback(() => {
    (async () => {
      setMutatedCurrentTime(0);
      await refPlayer.current.timeShift(0);
      setFocusCustom('hidden-focus-placeholder');
    })();
  }, [refPlayer]);

  const seekForward = useCallback(() => {
    if (userActivity) {
      setMutatedCurrentTime((prev) => prev + Math.floor(SEEK_VALUE));
    }
  }, [userActivity]);

  useEffect(() => {
    (async () => {
      if (!isLongPressActive) {
        // TODO: Block when requested time higher or lower than stream limits
        refPlayer?.current?.timeShift(mutatedCurrentTime);
      }
    })();
  }, [refPlayer, mutatedCurrentTime]);

  const seekBackWard = useCallback(() => {
    if (userActivity) {
      setMutatedCurrentTime((prev) => prev - Math.floor(SEEK_VALUE));
    }
  }, [userActivity]);

  return (
    <View style={[styles.container, { opacity }]}>
      <PlayerTopContainer
        videoType={videoType}
        channelList={channelListData}
        handleUpgradeNavigation={() => {}}
        downFocusKey={buttonPrefix + '0'}
      />

      <View style={styles.playerMenuContainer}>
        <ControllerContainer
          videoType={videoType}
          downFocusKey="progress-bar"
          buttonPrefix={buttonPrefix}
          contentInfo={contentVod}
          audioList={audioList}
          qualityList={null}
          setSelectedSubtitle={() => null}
          handleAudioSelect={() => null}
          handleMoreContentNavigation={() => null}
          subtitleSettingsCallback={subtitleSettingsCallback}
          userActivity={userActivity}
          showSkipButton={false}
          isTrackLive={isTrackLive}
          onEnterGoLiveButton={onEnterGoLiveButton}
        />

        <LivePlayerProgressBar
          videoType={videoType}
          refPlayer={refPlayer}
          totalTime={totalTime}
          currentTime={currentTime + mutatedCurrentTime}
          isPaused={isPaused}
          onEnterPress={onEnterPress}
          focusKey="progress-bar"
          upFocusKey={isTrackLive ? buttonPrefix + '0' : 'goLive'}
          downFocusKey="event-boxes"
          rightFocusKey={{ action: seekForward }}
          leftFocusKey={{ action: seekBackWard }}
          isTrackLive={isTrackLive}
        />

        <EventBoxes focusKey="event-boxes" commentaries={commentaries} />

        <BasicButton
          icon={ArrowDownBold}
          theme={buttonThemes.ghostWhite}
          themeFocused={buttonThemes.neutralPrimary}
          text={'Statistics & Commentary'}
          focusKey="statistics-screen"
          upFocusKey={'event-boxes'}
          onEnterPress={() => setShowStatisticsCallback()}
        />
      </View>

      {isPaused && <PauseButton />}
      <Overlay userActivity={userActivity} />
    </View>
  );
};

/**
 * Property Main
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {object} props.refPlayer - reference to player
 * @param {object} props.seekRef - seekRef reference
 * @param {boolean} props.isPaused - isPaused
 * @param {Function} props.setIsPaused - set isPaused
 * @param {object} props.userActivity - userActivity
 * @param {object} props.audioList - playerAudio Data
 * @param {object} props.contentVod - playerAudio Data
 * @param {Function} props.navigate - navigate
 * @param {object} props.subtitleList - subtitleList
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {boolean} props.isTrackLive - isTrackLive
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.setShowStatisticsCallback - setShowStatisticsCallback
 * @returns {module:JSX.Element} - JSX.Element
 */
const PropertyMain = ({
  videoType,
  totalTime,
  currentTime,
  refPlayer,
  seekRef,
  isPaused,
  setIsPaused,
  userActivity,
  audioList,
  navigate,
  contentVod,
  subtitleList,
  subtitleSettingsCallback,
  isTrackLive,
  onEnterPress,
  setShowStatisticsCallback,
}) => {
  return (
    <ElementList
      videoType={videoType}
      totalTime={totalTime}
      currentTime={currentTime}
      refPlayer={refPlayer}
      seekRef={seekRef}
      isPaused={isPaused}
      setIsPaused={setIsPaused}
      userActivity={userActivity}
      audioList={audioList}
      navigate={navigate}
      contentVod={contentVod}
      subtitleList={subtitleList}
      subtitleSettingsCallback={subtitleSettingsCallback}
      isTrackLive={isTrackLive}
      onEnterPress={onEnterPress}
      setShowStatisticsCallback={setShowStatisticsCallback}
    />
  );
};

export default React.memo(PropertyMain);
