import Avatar from './Avatar';
import Name from './Name';
import PinCode from './PinCode';
import ProfileType from './ProfileType';
import Main from './Main';
import Default from './Default';

export default {
  default: Default,
  Main: Main,
  Name: Name,
  Avatar: Avatar,
  ProfileType: ProfileType,
  PinCode: PinCode,
};
