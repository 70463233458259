import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import * as ICONS from '../../../assets/icons';
import colors from '../../../helpers/Colors';
import Icon from '../../atoms/Icon';
import { MenuButton } from '../../molecules/buttons';
import PropList from './proptypes';
import styles from './styles';
import Colors from '../../../helpers/Colors';
import AvatarWithBorder from '../../molecules/AvatarWithBorder';
import LinearGradient from 'react-native-linear-gradient';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import useLocalization from '../../../libs/localization/useLocalization';
import { getLink, isAnimationsDisabled } from '../../../helpers/CommonHelper';
import { useMenu } from '../../../context';
import { ApiRels } from '../../../helpers/Enums';

/**
 * Menu
 *
 * @param {object} props - props
 * @param {boolean} props.isSidebarOpened - isSidebarOpened
 * @param {string} props.rightFocusKey - rightFocusKey
 * @param {Function} props.onEnterPress - onEnterPress
 * @returns {module:JSX.Element} - JSX.Element
 */
const Menu = ({ isSidebarOpened, rightFocusKey, onEnterPress }) => {
  const prefix = 'sidebar-menu';
  const style = styles(isSidebarOpened);

  const { isRTL } = useLocalization();
  const { menuState } = useMenu();

  const menuItems = menuState?.data?.items || [];

  const profileUrl = useMemo(
    () => getLink(menuState?.data, ApiRels.PROFILE.GET_PROFILES)?.url,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuState?.data]
  );

  const bottomMenuItems = [
    {
      id: 'LANGUAGE',
      title: 'Language',
      icon: ICONS.Language,
    },
    {
      id: 'SETTINGS',
      title: 'Settings',
      icon: ICONS.Settings,
    },
  ];

  return (
    <LinearGradient
      start={{ x: isRTL ? 1 : 0.35, y: 1 }}
      end={{ x: isRTL ? 0.35 : 1, y: 1 }}
      colors={[colors.background, colors.transparent]}
      style={style.external}>
      <View style={style.container}>
        <View style={style.topContainer}>
          {!isSidebarOpened ? (
            <View style={style.brand}>
              <Icon
                icon={ICONS.TODLogoPNG}
                width={96}
                height={40}
                color={Colors.primary}
              />
            </View>
          ) : (
            <View style={style.avatar}>
              <AvatarWithBorder
                id={'PROFILES'}
                url={profileUrl}
                focusKey={'avatar'}
                upFocusKey={false}
                onEnterPress={onEnterPress}
                downFocusKey={`${prefix}-1`}
                rightFocusKey={rightFocusKey}
              />
            </View>
          )}
        </View>
        <View style={style.outerContainer}>
          {menuItems.map((item, index) => (
            <MenuButton
              text={item.title}
              id={item.urlSlug}
              icon={ICONS[item.icon]}
              url={getLink(item, ApiRels.SELF)?.url}
              onlyIcon={!isSidebarOpened}
              style={style.menuItem}
              key={`${prefix}-${index + 1}`}
              focusKey={`${prefix}-${index + 1}`}
              rightFocusKey={rightFocusKey ? rightFocusKey : false}
              upFocusKey={index === 0 ? 'avatar' : `${prefix}-${index}`}
              downFocusKey={
                index === menuItems.length - 1
                  ? `sidebar-bottom-0`
                  : `${prefix}-${index + 2}`
              }
              leftFocusKey={false}
              onEnterPress={onEnterPress}
              menu={item.urlSlug}
            />
          ))}
        </View>
        {isSidebarOpened && (
          <View style={style.bottomMenu}>
            {bottomMenuItems.map((item, index) => {
              return (
                <View
                  style={style.bottomMenuContainer}
                  key={`sidebar-bottom-${index}`}>
                  <MenuButton
                    text={item?.title}
                    id={item?.id}
                    icon={item?.icon}
                    onlyIcon={false}
                    selected={false}
                    style={style.menuItem}
                    focusKey={`sidebar-bottom-${index}`}
                    rightFocusKey={rightFocusKey ? rightFocusKey : false}
                    upFocusKey={
                      index === 0
                        ? `${prefix}-7`
                        : `sidebar-bottom-${index - 1}`
                    }
                    downFocusKey={
                      index === bottomMenuItems.length - 1
                        ? false
                        : `sidebar-bottom-${index + 1}`
                    }
                    leftFocusKey={false}
                    onEnterPress={onEnterPress}
                    menu={item.id}
                  />
                </View>
              );
            })}
          </View>
        )}
      </View>
      {isSidebarOpened && (
        <Animated.View
          style={style.bigLogo}
          entering={!isAnimationsDisabled && FadeIn.duration(500)}
          exiting={!isAnimationsDisabled && FadeOut.duration(500)}>
          <Icon icon={ICONS.TODLogoPNG} width={240} height={100} />
        </Animated.View>
      )}
    </LinearGradient>
  );
};

Menu.propTypes = PropList;
Menu.whyDidYouRender = false;

export default memo(Menu);
