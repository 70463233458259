import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: GetScaledValue(20),
    marginVertical: GetScaledValue(40),
  },
});

export default styles;
