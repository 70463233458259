import { View } from 'react-native';
import React from 'react';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import Text from '../../atoms/Text';
import { FONT_SIZES, FONT_FAMILY, ProfileTypes } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import styles from './styles';
import Avatar from '../../atoms/Avatar';
import { useProfile } from '../../../context';

/**
 * @param {object} props - props
 * @param {boolean} props.focused - focused
 * @returns {module:JSX.Element} -
 */
const AvatarWithBorder = ({ focused }) => {
  const { profileState } = useProfile();

  const selectedProfile = profileState?.selectedProfile;
  const borderColor = focused ? colors.primary500 : colors.transparent;

  return (
    <View style={styles.container}>
      <View style={[styles.focusView, { borderColor }]}>
        <Avatar
          source={selectedProfile?.iconPath}
          style={styles.avatar}
          iconWidth={50}
          isKid={selectedProfile?.profileType === ProfileTypes.KID}
        />
      </View>
      <View>
        <Text
          bold
          text={selectedProfile?.profileName}
          size={FONT_SIZES.HEADING3}
          color={colors.text}
          fontFamily={FONT_FAMILY.SEMIBOLD}
        />
        <Text
          text={'Switch Profile'}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.REGULAR}
          color={focused ? colors.primary : colors.neutral300}
        />
      </View>
    </View>
  );
};

export default withFocusable()(AvatarWithBorder);
