import LangAr from './languages/ar.json';
import I18n from 'i18n-js';

/**
 * digiturkI18n
 *
 * @param {string} defaultLocale - defaultLocale
 * @param {string} locale - locale
 * @param {object} langEn - langEn
 * @param {object} langTr - langTr
 */
const digiturkI18n = (defaultLocale, locale, langEn, langTr) => {
  // default locale
  I18n.defaultLocale = defaultLocale;

  // Enable fallbacks  to `en`
  I18n.fallbacks = true;

  // current locale
  I18n.locale = locale;

  /**
   * Translation Language
   */
  I18n.translations = {
    en: langEn.default,
    tr: langTr.default,
    ar: LangAr,
  };
};

export default digiturkI18n;
