import PropTypes from 'prop-types';

export default {
  size: PropTypes.string,
  text: PropTypes.string,
  focused: PropTypes.bool,
  icon: PropTypes.any,
  circle: PropTypes.bool,
  theme: PropTypes.string,
  themeFocused: PropTypes.string,
  themeCustom: PropTypes.any,
  style: PropTypes.any,
};
