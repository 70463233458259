import { StyleSheet } from 'react-native';

import { sizeConfig, themeConfig } from '../configs';
import { FONT_FAMILY } from '../../../../helpers/Enums';

/**
 * ZippyButton styles
 *
 * @param {string} size - size
 * @param {boolean} focused - focused
 * @param {string} theme - theme
 * @param {string} themeFocused - theme focus
 * @returns {object} - styles
 */
const style = (
  size = 'big',
  focused,
  theme = 'transparent16',
  themeFocused = theme
) => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      borderRadius: sizeConfig[size].container.borderRadius,
      paddingHorizontal: focused
        ? sizeConfig[size].container.paddingHorizontal
        : sizeConfig[size].container.paddingVertical,
      height: sizeConfig[size].container.height,
      backgroundColor: focused
        ? themeConfig[themeFocused].background
        : themeConfig[theme].background,
    },
    iconArea: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      // TOBE: refactor this field
      width: sizeConfig[size].icon.width,
      height: sizeConfig[size].icon.height,
      color: focused ? themeConfig[themeFocused].icon : themeConfig[theme].icon,
    },
    textArea: {
      alignItems: 'center',
      justifyContent: 'center',
      marginStart: sizeConfig[size].textArea.marginStart,
    },
    text: {
      fontFamily: FONT_FAMILY.SEMIBOLD,
      fontSize: sizeConfig[size].text.fontSize,
      lineHeight: sizeConfig[size].text.lineHeight,
      color: focused ? themeConfig[themeFocused].text : themeConfig[theme].text,
    },
  });
};

export default style;
