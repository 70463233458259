import React, { useRef } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';
import Lineup from '../../molecules/Lineup';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Teams
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Teams = () => {
  const flatListRef = useRef(null);

  const data = Array.from({ length: 2 }, (_, i) => ({
    key: `focusKey-${i + 1}`,
    focusKey: `focusKey-${i + 1}`,
    downFocusKey: `focusKey-${i + 2}`,
    upFocusKey: i > 0 ? `focusKey-${i}` : 'Commentary',
    teams: teams[i],
  }));

  /**
   * Flat list scroll controller
   *
   * @param {number} index - index
   */
  const handleFocus = (index) => {
    if (flatListRef.current) {
      flatListRef.current.scrollToIndex({ index, animated: true });
    }
  };

  /**
   * Flat list render
   *
   * @param {object} props - props
   * @param {object} props.item - render item
   * @param {number} props.index - index
   * @returns {React.JSX.Element} - returns flatlist items
   */
  const renderItem = ({ item, index }) => (
    <Lineup {...item} onFocus={() => handleFocus(index)} />
  );

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        ref={flatListRef}
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item.key}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.statisticsArea}
        scrollEnabled={true}
        onLayout={() => {
          flatListRef.current?.scrollToIndex({ index: 0, animated: false });
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  statisticsArea: {
    paddingVertical: GetScaledValue(10),
  },
});

const teams = [
  {
    name: 'Barcelona',
    logo: '',
    players: [
      { number: '1', name: 'Marc-André ter Stegen GK', icons: [] },
      { number: '2', name: 'Sergiño Dest', icons: [] },
      { number: '3', name: 'Gerard Piqué', icons: [] },
      { number: '4', name: 'Ronald Araújo', icons: [] },
      { number: '5', name: 'Sergio Busquets', icons: [] },
      { number: '6', name: 'Riqui Puig', icons: [] },
      { number: '7', name: 'Antoine Griezmann', icons: [] },
      { number: '8', name: 'Miralem Pjanić', icons: [] },
      { number: '9', name: 'Martin Braithwaite', icons: [] },
      { number: '10', name: 'Lionel Messi', icons: [] },
      { number: '11', name: 'Ousmane Dembélé', icons: [] },
    ],
    substituted: [
      {
        icon: '',
        number: '1',
        name: 'Marc-André ter Stegen',
        subName: 'Lionel Messi',
        subNum: '2',
      },
      {
        icon: '',
        number: '2',
        name: 'Sergiño Dest',
        subName: 'Lionel Messi',
        subNum: '2',
      },
      {
        icon: '',
        number: '3',
        name: 'Gerard Piqué',
        subName: 'Lionel Messi',
        subNum: '2',
      },
      {
        icon: '',
        number: '4',
        name: 'Ronald Araújo',
        subName: 'Lionel Messi',
        subNum: '2',
      },
    ],
  },
  {
    name: 'Real Madrid',
    logo: '',
    players: [
      { number: '1', name: 'Thibaut Courtois GK', icons: [] },
      { number: '2', name: 'Dani Carvajal', icons: [] },
      { number: '3', name: 'Éder Militão', icons: [] },
      { number: '4', name: 'Sergio Ramos', icons: [] },
      { number: '5', name: 'Raphaël Varane', icons: [] },
      { number: '6', name: 'Nacho Fernández', icons: [] },
      { number: '7', name: 'Eden Hazard', icons: [] },
      { number: '8', name: 'Toni Kroos', icons: [] },
      { number: '9', name: 'Karim Benzema', icons: [] },
      { number: '10', name: 'Luka Modrić', icons: [] },
      { number: '11', name: 'Marco Asensio', icons: [] },
    ],
    substituted: [
      {
        icon: '',
        number: '1',
        name: 'Thibaut Courtois',
        subName: 'Lionel Messi',
        subNum: '2',
      },
      {
        icon: '',
        number: '2',
        name: 'Dani Carvajal',
        subName: 'Lionel Messi',
        subNum: '2',
      },
      {
        icon: '',
        number: '3',
        name: 'Éder Militão',
        subName: 'Lionel Messi',
        subNum: '2',
      },
      {
        icon: '',
        number: '4',
        name: 'Sergio Ramos',
        subName: 'Lionel Messi',
        subNum: '2',
      },
    ],
  },
];

export default Teams;
