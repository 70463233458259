import React from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import Text from '../../../atoms/Text';
import Icon from '../../../atoms/Icon';

import styleFunction from './styles';
import { GetScaledValue } from '@digiturk/screen-size';
import { CheckStroke } from '../../../../assets/icons';
import colors from '../../../../helpers/Colors';

/**
 * Basic Button With Icon Text
 *
 * @param {object} props - props
 * @param {string} props.size - size
 * @param {string} props.text - text
 * @param {boolean} props.focused - focused
 * @param {boolean} props.circle - circle
 * @param {string} props.theme - theme
 * @param {string} props.themeFocused - color focus
 * @param {any} props.themeCustom - color custom
 * @param {any} props.style - style
 * @param {any} props.textAreaStyle - textAreaStyle
 * @param {any} props.leftIcon - leftIcon
 * @param {any} props.rightIcon - rightIcon
 * @param {boolean} props.isSelected - isSelected
 * @returns {module:JSX.Element} - JSX.Element
 */
const SelectedButtonWithIcons = ({
  size,
  text,
  focused,
  circle,
  theme,
  themeFocused,
  themeCustom,
  style,
  textAreaStyle,
  leftIcon,
  rightIcon,
  isSelected,
}) => {
  const styles = styleFunction(
    size,
    focused,
    circle,
    theme,
    themeFocused,
    themeCustom
  );

  const condition = focused
    ? isSelected
      ? colors.shades100
      : colors.shades100
    : isSelected
    ? colors.primary500
    : colors.shades00;

  return (
    <View style={[styles.container, style && style]}>
      <View style={{ flex: 1, flexDirection: 'row', gap: GetScaledValue(10) }}>
        {leftIcon && (
          <View style={styles.iconArea}>
            <Icon
              {...styles.icon}
              icon={leftIcon.icon}
              width={leftIcon.width}
              height={leftIcon.height}
              color={condition}
            />
          </View>
        )}
        {text && (
          <View style={[styles.textArea, textAreaStyle]}>
            <Text
              text={text}
              style={[
                styles.text,
                {
                  color: condition,
                },
              ]}
            />
          </View>
        )}
        {rightIcon && (
          <View style={styles.iconArea}>
            <Icon
              {...styles.icon}
              icon={rightIcon.icon}
              width={rightIcon.width}
              height={rightIcon.height}
              color={condition}
            />
          </View>
        )}
      </View>

      {isSelected && (
        <View style={styles.iconArea}>
          <Icon icon={CheckStroke} {...styles.icon} color={condition} />
        </View>
      )}
    </View>
  );
};

SelectedButtonWithIcons.whyDidYouRender = false;

export default withFocusable()(SelectedButtonWithIcons);
