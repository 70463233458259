import PropTypes from 'prop-types';

export default {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onEnterPress: PropTypes.func.isRequired,
    })
  ).isRequired,
  focusKey: PropTypes.string,
  highlightedText: PropTypes.string,
  pageStyle: PropTypes.object,
  buttonContainerStyle: PropTypes.object,
  textContainerHeader: PropTypes.object,
  textContainerContent: PropTypes.object,
  buttonStyle: PropTypes.object,
  highlightedTextStyle: PropTypes.object,
  titleOptions: PropTypes.object,
  contentOptions: PropTypes.object,
  highlightedTextOptions: PropTypes.object,
};
