import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { sizeConfig, themeConfig } from '../configs';

/**
 * ProgressBar styles
 *
 * @param {string} size - size
 * @param {boolean} focused - focused
 * @param {boolean} isSelected - isSelected
 * @param {string} selected - selected
 * @param {string} theme - theme
 * @param {string} themeFocused - theme focus
 * @returns {object} - styles
 */
const style = (
  size = 'big',
  focused,
  isSelected,
  selected = 'blackWhite',
  theme = 'transparent16',
  themeFocused = 'primary'
) =>
  StyleSheet.create({
    container: {
      width: GetScaledValue(400),
      height: GetScaledValue(400),
      padding: GetScaledValue(10),
      borderRadius: sizeConfig[size].container.borderRadius,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: focused
        ? themeConfig[themeFocused].background
        : isSelected
        ? themeConfig[selected].background
        : themeConfig[theme].background,
    },
    textUp: {
      fontSize: GetScaledValue(28),
      color: focused
        ? themeConfig[themeFocused].text
        : isSelected
        ? themeConfig[selected].text
        : themeConfig[theme].text,
    },
  });

export default style;
