/* eslint-disable require-jsdoc */
import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  lastFocusKey,
  getCurrentFocusKey,
  setFocusCustom,
} from '../helpers/FocusHelper';

/**
 * Configuration
 */
const config = {
  isVisibleLogs: false,
};

// TODO: Navigation depth
const useCustomNavigation = () => {
  const navigation = useNavigation();

  /**
   * Log writer
   */
  const stateLogger = useCallback(() => {
    if (navigation.getState().routes?.length) {
      console.info(`* NAVIGATION STATE`);
      navigation.getState().routes.map((item, index) => {
        console.info(index, ' - ', JSON.stringify(item));
      });
    } else {
      console.info('* NAVIGATION STATE is NULL');
    }
  }, []);

  // navigate
  const navigate = useCallback((route, params, nextFocus) => {
    lastFocusKey.set(getCurrentFocusKey());
    navigation.navigate(route, params);

    nextFocus && setFocusCustom(nextFocus);

    if (config.isVisibleLogs) stateLogger();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // goBack
  const goBack = useCallback((nextFocus) => {
    setFocusCustom(lastFocusKey.get());
    navigation.goBack();
    // Temporary solution for focus
    nextFocus && setFocusCustom(nextFocus);

    if (config.isVisibleLogs) stateLogger();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // replace
  const replaceNavigate = useCallback((route, params) => {
    lastFocusKey.set(getCurrentFocusKey());
    navigation.replace(route, params);

    if (config.isVisibleLogs) stateLogger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { navigate, goBack, replaceNavigate };
};

export default useCustomNavigation;
