import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';

/**
 *
 * @param {boolean} focused -
 * @returns {module:StyleSheet} -
 */
const styles = (focused) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: GetScaledValue(260),
    },
    textContainer: {
      marginTop: GetScaledValue(20),
      justifyContent: 'center',
      alignItems: 'center',
      width: GetScaledValue(260),
    },
    avatar: {
      width: focused ? GetScaledValue(260) : GetScaledValue(200),
      height: focused ? GetScaledValue(260) : GetScaledValue(200),
      borderRadius: GetScaledValue(16),
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

export default styles;
