import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import {
  getCurrentFocusKey,
  setFocusCustom,
} from '../../../helpers/FocusHelper';
import { getId, resetId, exitApp } from '../../../helpers/CommonHelper';
import useLocalization from '../../../libs/localization/useLocalization';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';

import FocusableSidebar from '../../templates/Sidebar';
import GridView from '../../templates/GridView';

import { IndexPropTypes } from './proptypes';
import styles from './styles';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * ViewAll screen
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const ViewAll = ({ route }) => {
  const lastFocusKey = useRef();

  const { languageDirection } = useLocalization();

  const [date, setDate] = useState(0);
  const [contentSource, setContentSource] = useState({
    isReady: false,
    railType: '',
    url: '',
  });

  useEffect(() => {
    if (route?.params?.url) {
      (async () => {
        const railList = await makeApiCall({ url: route?.params?.url });

        setContentSource({
          isReady: true,
          railType: railList?.rails[0]?.railType,
          url: railList?.rails[0]?.links[0]?.href,
        });

        // loading finish
        // setTimeout(() => setIsLoading(false), 1000);
      })();
    }

    if (route?.params?.date) {
      setDate(route?.params?.date);
    }
  }, [route]);

  /**
   * on navigate back method
   */
  const onNavigateBack = () => {
    if (getCurrentFocusKey()?.indexOf('sidebar') === -1) {
      const currentId = getId(getCurrentFocusKey());

      if (currentId != null) {
        if (parseInt(currentId, 10) > 0) {
          lastFocusKey.current = resetId(getCurrentFocusKey());
          setFocusCustom(lastFocusKey.current);
        } else {
          setFocusCustom('sidebar');
        }
      } else {
        setFocusCustom('sidebar');
      }
    } else exitApp();
  };

  useHardwareBackPress(() => {
    onNavigateBack();
  }, true);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`viewall-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'viewall-content'}
        onNavigateBack={onNavigateBack}
      />
      <GridView
        contentSource={contentSource}
        key={'viewall'}
        apiKey={'ViewAll'}
        prefix={'viewall-raillist'}
        focusKey={'viewall-content'}
        leftFocusKey={'sidebar'}
        initialFocus={'viewall-raillist-0'}
      />
    </View>
  );
};

ViewAll.propTypes = IndexPropTypes;
ViewAll.whyDidYouRender = false;

export default ViewAll;
