import React, { useEffect, memo } from 'react';
import { View } from 'react-native';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import ModalWrapper from '../../organisms/ModalWrapper';
import Text from '../../atoms/Text';
import PropList from './proptypes';
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup';
import style from './styles';
import { buttonSize } from '../buttons';
/**
 * Generic Modal Page
 *
 * @param {object} props - Props object
 * @param {string} props.title - The title of the modal
 * @param {string} props.message - The optional message to display in the modal
 * @param {Array} props.buttons - List of button objects with { text, action } properties
 * @param {string} props.focusKey - Initial focus key for the modal
 * @param {string} props.highlightedText - The highlighted text inside the title
 * @param {object} props.pageStyle - pageStyle
 * @param {object} props.buttonContainerStyle - buttonContStyle
 * @param {object} props.textContainerHeader - textContainerHeader
 * @param {object} props.textContainerContent - textContainerContent
 * @param {object} props.buttonStyle - buttonStyle
 * @param {object} props.highlightedTextStyle - buttonStyle
 * @param {object} props.titleOptions - titleOptions
 * @param {object} props.contentOptions - contentOptions
 * @param {object} props.highlightedTextOptions - highlightedTextOptions
 * @returns {module:JSX.Element} - JSX.Element
 */
const GenericModal = ({
  title = '',
  message = '',
  buttons = [],
  focusKey = 'modal-button-0',
  highlightedText = null,
  pageStyle = {},
  buttonContainerStyle = {},
  textContainerHeader = {},
  textContainerContent = {},
  buttonStyle = {},
  highlightedTextStyle = {},
  titleOptions = {},
  contentOptions = {},
  highlightedTextOptions = {},
}) => {
  const { goBack } = useCustomNavigation();

  useHardwareBackPress(() => {
    goBack();
  }, true);

  useEffect(() => {
    setFocusCustom(focusKey);
  }, [focusKey]);

  /**
   * render Title With Highlight
   *
   * @returns {module:JSX.Element} - JSX.Element
   */
  const renderTitleWithHighlight = () => {
    if (!highlightedText) return title;
    const parts = title.split(highlightedText);

    return (
      <>
        {parts[0]}
        <Text
          color={highlightedTextOptions?.color}
          size={highlightedTextOptions?.size}
          fontFamily={highlightedTextOptions?.fontFamily}
          text={highlightedText}
          style={highlightedTextStyle}
        />
        {parts[1]}
      </>
    );
  };

  const buttonsWithFocusKeys = buttons.map((button, index) => ({
    ...button,

    style: buttonStyle,
  }));

  return (
    <ModalWrapper>
      <View style={pageStyle}>
        <Text
          fontFamily={titleOptions?.fontFamily}
          text={renderTitleWithHighlight()}
          size={titleOptions?.size}
          style={textContainerHeader}
        />
        {message && (
          <Text
            fontFamily={contentOptions?.fontFamily}
            text={message}
            size={contentOptions?.size}
            style={textContainerContent}
            color={contentOptions?.color}
            multiline
            numberOfLines={3}
          />
        )}
        <ButtonsGroup
          prefix="modal-button"
          buttons={buttonsWithFocusKeys}
          containerStyle={style.container}
          size={buttonSize.medium}
        />
      </View>
    </ModalWrapper>
  );
};

GenericModal.propTypes = PropList;
GenericModal.whyDidYouRender = false;
export default memo(GenericModal);
