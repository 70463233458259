import { StyleSheet } from 'react-native';
import React from 'react';
import { GetScaledValue } from '@digiturk/screen-size';
import { SPACING_VALUES } from '../../../helpers/Enums';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import colors from '../../../helpers/Colors';
import {
  borderWidth,
  isAnimationsDisabled,
  padding,
} from '../../../helpers/CommonHelper';

/**
 *
 * @param {object} props - props
 * @param {number} props.width - width
 * @param {number} props.height - height
 * @param {object} props.animatedStyle - animatedStyle
 * @param {object} props.borderPosition - borderPosition
 * @returns {module:JSX.Element} -
 */
const AnimatedBorder = ({ width, height, animatedStyle, borderPosition }) => {
  return (
    <Animated.View
      entering={!isAnimationsDisabled && FadeIn.duration(350)}
      exiting={!isAnimationsDisabled && FadeOut.duration(350)}
      style={[
        styles.container,
        animatedStyle,
        {
          width: GetScaledValue(width) + padding * 2,
          height: GetScaledValue(height) + padding * 2,
        },
        borderPosition,
      ]}
    />
  );
};

export default AnimatedBorder;

const styles = StyleSheet.create({
  container: {
    borderWidth,
    borderColor: colors.primary500,
    borderRadius: GetScaledValue(SPACING_VALUES.SXXXBIG),
    position: 'absolute',
    zIndex: 99,
  },
});
