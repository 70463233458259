import React, { memo, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { getMenus } from '../../../middleware/menus';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import Icon from '../../atoms/Icon';
import Loader from '../../../assets/icons/loader.gif';
import { setAuth } from '../../../middleware/services/api';
import styles from './styles';
import { menuActions, useMenu } from '../../../context/Menu';

import { getLink } from '../../../helpers/CommonHelper';
import { ApiRels } from '../../../helpers/Enums';

import { useAuth } from '../../../context/Auth';

/**
 * Splash screen
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Splash = () => {
  const { menuState, menuDispatch } = useMenu();
  const { authState } = useAuth();

  const navigation = useNavigation();

  const [isError, setIsError] = useState(false);

  // page change listener for stop .gif animations
  useEffect(() => {
    const pageBlurEvent = navigation.addListener('blur', () => {
      setIsError(true);
    });

    return pageBlurEvent;
  }, [navigation]);

  useEffect(() => {
    (async () => {
      if (menuState.isInitiated) {
        await checkAuth();
      }
    })();
  }, [authState, menuState]);


  /**
   * Checks if the user is authenticated by retrieving the auth item from AsyncStorage.
   *
   * @returns {void}
   */
  const checkAuth = async () => {
    if (authState.at) {
      setAuth(`Bearer ${authState.at}`);

      const { url: nextLink } = getLink(
        menuState?.data,
        ApiRels.PROFILE.GET_PROFILES
      );

      navigation.replace(NavigationRoutes.profiles, { url: nextLink });
    } else {
      navigation.navigate(NavigationRoutes.landing);
    }
  };

  useEffect(
    () => {
      (async () => {
        await fetchMenus();
      })();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  /**
   * Fetches menus and updates the menu response state.
   */
  const fetchMenus = async () => {
    const menus = await getMenus();

    await menuDispatch(menuActions.setMenu(menus));
  };



  return (
    <View style={styles.pageArea}>
      <View style={styles.logo}>
        {!isError && <Icon icon={Loader} width={260} height={260} />}
      </View>
    </View>
  );
};

Splash.whyDidYouRender = false;

export default memo(Splash);
