import PropTypes from 'prop-types';

export default {
  text: PropTypes.string,
  icon: PropTypes.any,
  onlyIcon: PropTypes.bool,
  focused: PropTypes.bool,
  selected: PropTypes.bool,
  style: PropTypes.any,
};
