import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

/**
 * InfoBubble styles
 *
 * @param {boolean} focused - focused
 * @returns {object} - styles
 */
const styles = (focused) => {
  return StyleSheet.create({
    container: {
      backgroundColor: '#000',
      width: GetScaledValue(720),
      border: `5px solid ${
        focused ? colors.primary : colors.whiteTransparent16
      }`,
      borderRadius: GetScaledValue(20),
      padding: GetScaledValue(40),
      marginTop: GetScaledValue(20),
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      height: GetScaledValue(60),
      justifyContent: 'center',
    },
    lineupContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    playersList: {
      flex: 2,
      marginRight: GetScaledValue(20),
    },
    playerRow: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: GetScaledValue(10),
    },
    playerNumber: {
      color: '#fff',
      marginRight: GetScaledValue(10),
    },
    playerName: {
      color: '#fff',
    },
    substitutedContainer: {
      flex: 1,
      width: GetScaledValue(252),
    },
    substitutedHeader: {
      color: '#fff',
      fontWeight: 'bold',
      marginVertical: GetScaledValue(10),
    },
    substitutedRow: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: GetScaledValue(10),
    },
    subArea: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'space-around',
    },
    iconArea: {
      justifyContent: 'center',
      width: GetScaledValue(40),
    },
  });
};

export default styles;
