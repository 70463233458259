import React, { Suspense, memo, useMemo } from 'react';

import railComponentsMap from '../../molecules/SelectedComponent/Rails';
import useLocalization from '../../../libs/localization/useLocalization';

import styles from './styles';
import { withFocusable } from '@digiturk/react-spatial-navigation';

/**
 * Page Horizontal Rail Item
 *
 * @param {object} props - props
 * @param {object} props.item - item
 * @param {number} props.index - index
 * @param {string} props.prefix - prefix
 * @param {string} props.leftFocusKey - leftFocusKey
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.contentLength - contentLength
 * @returns {module:JSX.Element} -
 */
const RailItem = ({
  item,
  index,
  prefix,
  contentLength,
  leftFocusKey,
  onEnterPress,
}) => {
  const { isRTL } = useLocalization();

  const SelectedComponent = useMemo(
    () => railComponentsMap[item.railType] || railComponentsMap.Default,
    [item.railType]
  );

  return (
    <Suspense>
      <SelectedComponent
        moduleIndex={index}
        railType={item.railType}
        dataURL={item?.links[0]?.href}
        distanceFromEdge={0}
        listStyle={styles.listStyle}
        contentContainerStyle={
          isRTL ? styles.contentContainerStyleRTL : styles.contentContainerStyle
        }
        onEnterPress={onEnterPress}
        prefix={prefix}
        focusKey={`${prefix}-${index}`}
        focusKeyPrefix={`${prefix}-${index}`}
        leftFocusKey={leftFocusKey}
        upFocusKey={index === 0 ? false : `${prefix}-${index - 1}`}
        downFocusKey={
          contentLength - 1 === index ? false : `${prefix}-${index + 1}`
        }
        setGlobalFocusedItem={() => false}
        railOffsetStartOfPage={620}
        railOffsetInlineStart={0}
        railWidth={1300}
        railItemGap={0}
      />
    </Suspense>
  );
};

export default memo(withFocusable({ trackChildren: true })(RailItem));
