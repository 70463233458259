import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { isWeb, padding } from '../../../helpers/CommonHelper';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginStart: GetScaledValue(200),
    paddingTop: GetScaledValue(40),
    zIndex: 98,
  },
  leftArea: {
    flexDirection: 'column',
    width: GetScaledValue(380),
    marginEnd: GetScaledValue(40),
  },
  keyboardContainer: {
    marginBottom: GetScaledValue(40),
  },
  rightArea: {
    flex: 1,
  },
  inputArea: {
    flexDirection: 'row',
    marginBottom: GetScaledValue(40),
  },
  icon: {
    marginTop: GetScaledValue(5),
    marginEnd: GetScaledValue(20),
  },
  listStyle: {
    paddingVertical: padding,
    paddingStart: padding,
    overflow: isWeb ? undefined : 'visible',
    marginBottom: GetScaledValue(40),
  },
  buttonListItems: {
    marginBottom: GetScaledValue(4),
    width: GetScaledValue(380),
    height: GetScaledValue(70),
  },
  contentContainerStyle: {
    paddingEnd: isWeb ? GetScaledValue(108) : GetScaledValue(120),
    gap: GetScaledValue(20),
  },
  contentContainerStyleRTL: {
    paddingStart: isWeb ? 0 : padding,
    paddingEnd: GetScaledValue(108),
    gap: GetScaledValue(20),
  },
  suggestions: {
    height: GetScaledValue(412),
  },
  railListContainer: {
    flex: 1,
    width: GetScaledValue(1300),
  },
  gradientVertical: {
    flex: 1,
    position: 'absolute',
    zIndex: 99,
    bottom: 0,
    height: GetScaledValue(100),
    width: GetScaledValue(380),
  },
  loading: {
    position: 'absolute',
    backgroundColor: 'black',
    zIndex: 199,
    start: 0,
    top: 0,
    end: 0,
    bottom: 0,
  },
});

export default styles;
