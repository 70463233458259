import React from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';

/**
 * AccountDetails Settings Component
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const AccountDetails = () => {
  return (
    <View style={styles.container}>
      <Text
        text={'Account Details'}
        size={FONT_SIZES.HEADING3}
        fontFamily={FONT_FAMILY.SEMIBOLD}
      />
      <View style={styles.name}>
        <Text
          text={'Username'}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          style={styles.text}
        />
        <Text
          text={'matthew.norris'}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
      </View>
      <View style={styles.contact}>
        <Text
          text={'E-mail'}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          style={styles.text}
        />
        <Text
          text={'matthew.norris@outlook.com'}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
      </View>
    </View>
  );
};

export default AccountDetails;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral800,
    padding: GetScaledValue(40),
    width: GetScaledValue(640),
    height: GetScaledValue(398),
    borderRadius: GetScaledValue(20),
  },
  name: {
    flexDirection: 'row',
    marginTop: GetScaledValue(30),
  },
  contact: {
    flexDirection: 'row',
    marginTop: GetScaledValue(20),
  },
  text: {
    minWidth: GetScaledValue(160),
  },
});
