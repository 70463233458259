import React, { useCallback, useEffect, useState, useRef } from 'react';
import { View } from 'react-native';
import PlayerProgressBar from '../../organisms/PlayerProgressBar';
import style from './styles';
import PauseButton from './PauseButton';
import Overlay from './Overlay';
import ControllerContainer from './ControllerContainer';
import { SEEK_VALUE, isWeb } from '../../../helpers/CommonHelper';
import PlayerTopContainer from '../../molecules/PlayerTopContainer';
import { isLongPressActive } from '../../../helpers/FocusHelper';

/**
 * Property Element List
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {object} props.refPlayer - reference to player
 * @param {boolean} props.isPlayerReady - isPlayerReady
 * @param {boolean} props.isPaused - isPaused
 * @param {Function} props.setIsPaused - set isPaused
 * @param {Function} props.playPauseCallback - playPauseHandler
 * @param {object} props.userActivity - userActivity
 * @param {Array} props.audioList - audio list
 * @param {object} props.contentVod - contentVod
 * @param {Function} props.setSelectedSubtitle - setSelectedSubtitle
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {Function} props.handleModal - handleModal
 * @param {Array} props.qualityList - qualityList
 * @returns {module:JSX.Element} - JSX.Element
 */
const ElementList = ({
  videoType,
  totalTime,
  currentTime,
  refPlayer,
  isPlayerReady,
  isPaused,
  setIsPaused,
  playPauseCallback,
  userActivity,
  audioList,
  contentVod,
  subtitleSettingsCallback,
  qualityList,
  setSelectedSubtitle,
  handleModal,
}) => {
  const buttonPrefix = 'player-activity-button-';
  const styles = style({ userActivity, isPaused });

  const [showSkipButton, setShowSkipButton] = useState(false);
  const [mutatedCurrentTime, setMutatedCurrentTime] = useState(currentTime);
  const lastSeekTimeRef = useRef(currentTime);
  const isSeekingRef = useRef(false);

  useEffect(() => {
    if (totalTime - 10 >= currentTime) setShowSkipButton(true);
    else setShowSkipButton(false);
  }, [currentTime, totalTime]);

  useEffect(() => {
    if (!isSeekingRef.current) {
      setMutatedCurrentTime(currentTime);
      lastSeekTimeRef.current = currentTime;
    }
  }, [currentTime]);

  /**
   * Handles the selection of an audio option.
   *
   * @param {object} option - The selected audio option.
   * @returns {void}
   */
  const handleAudioSelect = (option) => {
    isWeb
      ? refPlayer.current.setAudio(option.id)
      : refPlayer.current.setAudioTrack(option.identifier);
  };

  /**
   * Handles the selection of an audio option.
   *
   * @param {object} option - The selected quality option.
   * @returns {void}
   */
  const handleVideoQuality = (option) => {
    isWeb
      ? refPlayer.current.setVideoQuality(option.id)
      : refPlayer.current.setMaxSelectableBitrate(option.bitrate);
  };

  const seekForward = useCallback(() => {
    if (currentTime >= totalTime || mutatedCurrentTime >= totalTime) {
      return;
    }

    if (isLongPressActive && mutatedCurrentTime + SEEK_VALUE >= totalTime) {
      return;
    }

    if (userActivity) {
      isSeekingRef.current = true;
      setMutatedCurrentTime((prev) => {
        const newTime = prev + SEEK_VALUE;

        return Math.min(newTime, totalTime);
      });
    }
  }, [currentTime, totalTime, mutatedCurrentTime, userActivity]);

  useEffect(() => {
    if (!isLongPressActive && mutatedCurrentTime !== lastSeekTimeRef.current) {
      refPlayer?.current?.seek(mutatedCurrentTime);
      lastSeekTimeRef.current = mutatedCurrentTime;
      isSeekingRef.current = false;
    }
  }, [mutatedCurrentTime, refPlayer]);

  const seekBackWard = useCallback(() => {
    if (currentTime === 0) {
      return;
    }

    if (userActivity) {
      isSeekingRef.current = true;
      setMutatedCurrentTime((prev) => {
        const newTime = Math.max(prev - SEEK_VALUE, 0);

        return newTime;
      });
    }
  }, [currentTime, userActivity]);

  return (
    <View style={styles.container}>
      <PlayerTopContainer
        handleModal={handleModal}
        downFocusKey={buttonPrefix + '0'}
      />

      <View style={styles.playerMenuContainer}>
        <ControllerContainer
          downFocusKey="progress-bar"
          buttonPrefix={buttonPrefix}
          contentInfo={contentVod}
          audioList={audioList}
          qualityList={qualityList}
          setSelectedSubtitle={setSelectedSubtitle}
          handleAudioSelect={handleAudioSelect}
          handleModal={handleModal}
          subtitleSettingsCallback={subtitleSettingsCallback}
          userActivity={userActivity}
          showSkipButton={showSkipButton}
          handleVideoQuality={handleVideoQuality}
        />

        <PlayerProgressBar
          videoType={videoType}
          totalTime={totalTime}
          currentTime={isLongPressActive ? mutatedCurrentTime : currentTime}
          focusKey="progress-bar"
          upFocusKey={'player-activity-button-0'}
          refPlayer={refPlayer}
          isPlayerReady={isPlayerReady}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          rightFocusKey={{ action: seekForward }}
          leftFocusKey={{ action: seekBackWard }}
          onEnterPress={playPauseCallback}
        />
      </View>
      {isPaused && <PauseButton />}
      <Overlay userActivity={userActivity} />
    </View>
  );
};

/**
 * Property Main
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {object} props.refPlayer - reference to player
 * @param {boolean} props.isPlayerReady - isPlayerReady
 * @param {boolean} props.isPaused - isPaused
 * @param {Function} props.setIsPaused - set isPaused
 * @param {Function} props.setSelectedSubtitle - set setSelectedSubtitle
 * @param {Function} props.playPauseCallback - playPauseCallback
 * @param {Function} props.handleModal - handleModal
 * @param {object} props.userActivity - userActivity
 * @param {object} props.audioList - playerAudio Data
 * @param {object} props.contentVod - playerAudio Data
 * @param {Function} props.chooseAudioTrack - chooseAudioTrack
 * @param {object} props.subtitleList - subtitleList
 * @param {object} props.qualityList - qualityList
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {Function} props.handleVideoQuality - handleVideoQuality
 * @returns {module:JSX.Element} - JSX.Element
 */
const PropertyMain = ({
  videoType,
  totalTime,
  currentTime,
  refPlayer,
  isPlayerReady,
  isPaused,
  setIsPaused,
  playPauseCallback,
  userActivity,
  audioList,
  contentVod,
  subtitleList,
  qualityList,
  subtitleSettingsCallback,
  chooseAudioTrack,
  setSelectedSubtitle,
  handleModal,
  handleVideoQuality,
}) => {
  return (
    <ElementList
      videoType={videoType}
      totalTime={totalTime}
      currentTime={currentTime}
      refPlayer={refPlayer}
      isPlayerReady={isPlayerReady}
      isPaused={isPaused}
      setIsPaused={setIsPaused}
      playPauseCallback={playPauseCallback}
      userActivity={userActivity}
      audioList={audioList}
      contentVod={contentVod}
      subtitleList={subtitleList}
      subtitleSettingsCallback={subtitleSettingsCallback}
      chooseAudioTrack={chooseAudioTrack}
      qualityList={qualityList}
      setSelectedSubtitle={setSelectedSubtitle}
      handleModal={handleModal}
      handleVideoQuality={handleVideoQuality}
    />
  );
};

export default React.memo(PropertyMain);
