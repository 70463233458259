import { StyleSheet, View } from 'react-native';
import React from 'react';

/**
 * Default component
 *
 * @returns {module:JSX.Element}-
 */
const Default = () => {
  return <View style={styles.container} />;
};

export default Default;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
