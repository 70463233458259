import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';
import { SPACING_VALUES } from '../../../helpers/Enums';

/**
 * Avatar Organism styles
 *
 * @returns {object} - object
 */
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: GetScaledValue(20),
  },
  focusView: {
    borderWidth: GetScaledValue(5),
    borderRadius: GetScaledValue(SPACING_VALUES.SMD),
    borderColor: colors.primary500,
    justifyContent: 'center',
    alignItems: 'center',
    padding: GetScaledValue(1),
    overflow: 'hidden',
  },
  avatar: {
    width: GetScaledValue(80),
    height: GetScaledValue(80),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
