import crypto from 'crypto-js';
import { randomNumber } from '../../helpers/CommonHelper';

/**
 * Encrypts the API key.
 *
 * @param {string} apiKey - The API key to be encrypted. contextten alinacak bu.
 * @returns {string} - The encrypted API key.
 */
export const encryptKey = (apiKey) => {
  let plainText = Date.now() + '';
  let secret = apiKey;

  let key = crypto.enc.Utf8.parse(secret);
  let iv = crypto.enc.Utf8.parse(secret);

  let encryptedBytes = crypto.AES.encrypt(plainText, key, { iv: iv });
  let encryptedString = encryptedBytes.toString();

  encryptedString =
    randomNumber(100, 999) + encryptedString + randomNumber(100, 999);

  return encryptedString;
};
