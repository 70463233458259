import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  pageArea: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  logo: {
    alignItems: 'center',
  },
});

export default styles;
