import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  pageArea: {
    flex: 1,
    backgroundColor: 'black',
  },
});

export default styles;
