import regular from 'shared/assets/fonts/Alexandria-Regular.ttf';
import medium from 'shared/assets/fonts/Alexandria-Medium.ttf';
import semiBold from 'shared/assets/fonts/Alexandria-SemiBold.ttf';
import bold from 'shared/assets/fonts/Alexandria-Bold.ttf';
import extraBold from 'shared/assets/fonts/Alexandria-ExtraBold.ttf';
import light from 'shared/assets/fonts/Alexandria-Light.ttf';
import thin from 'shared/assets/fonts/Alexandria-Thin.ttf';
import extraLight from 'shared/assets/fonts/Alexandria-ExtraLight.ttf';
import black from 'shared/assets/fonts/Alexandria-Black.ttf';
import { FONT_FAMILY } from 'shared/src/helpers/Enums';
const regularFontStyles = `@font-face {
  src: url(${regular});
  font-family: ${FONT_FAMILY.REGULAR};
}`;
const mediumFontStyles = `@font-face {
  src: url(${medium});
  font-family: ${FONT_FAMILY.MEDIUM};
}`;
const semiFontStyles = `@font-face {
  src: url(${semiBold});
  font-family: ${FONT_FAMILY.SEMIBOLD};
}`;
const thinFontStyles = `@font-face {
  src: url(${thin});
  font-family: ${FONT_FAMILY.THIN};
}`;
const boldFontStyles = `@font-face {
  src: url(${bold});
  font-family: ${FONT_FAMILY.BOLD};
}`;
const extraBoldFontStyles = `@font-face {
  src: url(${extraBold});
  font-family: ${FONT_FAMILY.EXTRA_BOLD};
}`;
const extraLightFontStyles = `@font-face {
  src: url(${extraLight});
  font-family: ${FONT_FAMILY.EXTRA_LIGHT};
}`;
const lightFontStyles = `@font-face {
  src: url(${light});
  font-family: ${FONT_FAMILY.LIGHT};
}`;
const blackFontStyles = `@font-face {
  src: url(${black});
  font-family: ${FONT_FAMILY.BLACK};
}`;

/**
 * Loads Fonts for Web Platform
 *
 */
export const loadFontsForWeb = () => {
  // Create stylesheet
  const style = document.createElement('style');

  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = regularFontStyles;
  } else {
    style.appendChild(document.createTextNode(regularFontStyles));
    style.appendChild(document.createTextNode(mediumFontStyles));
    style.appendChild(document.createTextNode(semiFontStyles));
    style.appendChild(document.createTextNode(thinFontStyles));
    style.appendChild(document.createTextNode(blackFontStyles));
    style.appendChild(document.createTextNode(boldFontStyles));
    style.appendChild(document.createTextNode(extraBoldFontStyles));
    style.appendChild(document.createTextNode(lightFontStyles));
    style.appendChild(document.createTextNode(extraLightFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);
};
