import { StyleSheet } from 'react-native';

import { sizeConfig, themeConfig } from './configs';
import { FONT_FAMILY } from '../../../helpers/Enums';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * ProgressBar styles
 *
 * @param {string} size - size
 * @param {boolean} focused - focused
 * @param {boolean} circle - circle
 * @param {string} theme - theme
 * @param {string} themeFocused - theme focus
 * @param {any} themeCustom - theme custom
 * @returns {object} - styles
 */
const style = (
  size = 'medium',
  focused,
  circle = false,
  theme = 'transparent16',
  themeFocused = theme,
  themeCustom
) => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      width: GetScaledValue(800),
      height: GetScaledValue(180),
      gap: 20,
      borderRadius: circle ? 100 : sizeConfig[size].container.borderRadius,
      paddingHorizontal: circle
        ? sizeConfig[size].container.paddingCircle
        : sizeConfig[size].container.paddingHorizontal,
      paddingVertical: circle
        ? sizeConfig[size].container.paddingCircle
        : sizeConfig[size].container.paddingVertical,
      backgroundColor: focused
        ? themeConfig[themeFocused].background
        : themeConfig[theme].background,
    },

    subTitle: {
      fontFamily: FONT_FAMILY.SEMIBOLD,
      fontSize: sizeConfig[size].text.fontSize,
      lineHeight: sizeConfig[size].text.lineHeight,
      color: focused ? themeConfig[themeFocused].text : themeConfig[theme].text,
    },
    title: {
      fontFamily: FONT_FAMILY.BOLD,
      fontSize: sizeConfig[size].text.fontSize,
      lineHeight: sizeConfig[size].text.lineHeight,
      color: focused ? themeConfig[themeFocused].text : themeConfig[theme].text,
    },

    image: {
      width: GetScaledValue(60),
      height: GetScaledValue(60),
    },
  });
};

export default style;
