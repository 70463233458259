import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';

import { withFocusable } from '@digiturk/react-spatial-navigation';

import {
  getRailItemConfigurations,
  itemMargin,
  calculateForRTL,
  isWeb,
  isAnimationsDisabled,
} from '../../../helpers/CommonHelper';
import {
  runOnUI,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import AnimatedBorder from '../../molecules/AnimatedBorder';

import FocusableItem from './item';
import useLocalization from '../../../libs/localization/useLocalization';

import { IndexPropTypes } from './proptypes';
import useTransition from '../../../hooks/useTransition';

/**
 * keyExtractor method for current flatList element
 *
 * @param {object} _item - slide item
 * @param {number} index - item index
 * @returns {string} - key generator
 */
const keyExtractor = (_item, index) => `fullviewlist_rail_${index}`;

/**
 * FullViewList
 *
 * @param {object} props - props
 * @param {object} props.listStyle - listStyle
 * @param {object} props.contentContainerStyle - contentContainerStyle
 * @param {object} props.distanceFromEdge - initial and default offset for borderX
 * @param {string} props.railType - rail type
 * @param {object} props.railURL - rail url
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.setGlobalFocusedItem - setGlobalFocusedItem
 * @param {string} props.focusKeyPrefix - focusKeyPrefix
 * @param {string|boolean} props.rightFocusKey - rightFocusKey
 * @param {string|boolean} props.leftFocusKey - leftFocusKey
 * @param {Function|string|boolean} props.upFocusKey - upFocusKey
 * @param {string|boolean} props.downFocusKey - downFocusKey
 * @param {string|boolean} props.hasFocusedChild - hasFocusedChild
 * @returns {module:JSX.Element} - JSX.Element
 */
export const FullViewList = ({
  railType,
  railURL,
  onEnterPress,
  setGlobalFocusedItem,
  focusKeyPrefix,
  listStyle,
  contentContainerStyle,
  distanceFromEdge,
  rightFocusKey = false,
  leftFocusKey = false,
  upFocusKey = false,
  downFocusKey = false,
  hasFocusedChild,
}) => {
  const sliderRef = useRef();
  const { isRTL } = useLocalization();

  const { borderTransition } = useTransition();
  const railConfig = getRailItemConfigurations(railType);
  const [contentList, setContentList] = useState([]);
  const numberWidth = railConfig?.position?.start ?? 0;

  const borderX = useSharedValue(
    isRTL ? calculateForRTL(distanceFromEdge - numberWidth) : distanceFromEdge
  );

  /**
   * Fetch Rail List
   */
  useEffect(() => {
    railURL && setContentList(railURL.links[0]?.href);
  }, [railURL]);

  /**
   * onBecameFocused
   */
  const onBecameFocused = useCallback(
    (_coordinate, selected) => {
      setGlobalFocusedItem({
        ...selected.item,
        ...railConfig,
        railType,
      });

      _coordinate.node?.measure((fx, fy, width, height, px, py) => {
        const offset = (width + itemMargin) * selected?.index;

        sliderRef?.current?.scrollToOffset({
          offset: isWeb && isRTL ? calculateForRTL(offset) : offset,
          viewPosition: 0,
          animated: true,
        });

        const newBorder = borderTransition({
          width,
          px,
          selected,
          contentLength: 5,
          railConfig,
          distanceFromEdge,
        });

        runOnUI(() => {
          borderX.value = newBorder;
        })();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentList.length, distanceFromEdge]
  );

  /**
   * Render item
   */
  const renderItem = useCallback(
    ({ item, index }) => (
      <FocusableItem
        item={item}
        railType={railType}
        posterWidth={railConfig.width}
        posterHeight={railConfig.height}
        index={index}
        focusKey={`${focusKeyPrefix}-${railType}-${index}`}
        onBecameFocused={onBecameFocused}
        onEnterPress={onEnterPress}
        rightFocusKey={`${focusKeyPrefix}-${railType}-${index + 1}`}
        leftFocusKey={
          index === 0
            ? leftFocusKey
            : `${focusKeyPrefix}-${railType}-${index - 1}`
        }
        upFocusKey={upFocusKey}
        downFocusKey={downFocusKey}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentList]
  );

  const onScrollToIndexFailedHandler = useCallback(
    ({ index, averageItemLength }) => {
      sliderRef?.current?.scrollToOffset({
        offset: index * averageItemLength,
        animated: true,
      });

      setTimeout(() => {
        sliderRef?.current?.scrollToIndex({
          index: index,
          animated: true,
        });
      }, 100);
    },
    []
  );

  const animatedStyle = useAnimatedStyle(() => {
    const transform = isAnimationsDisabled
      ? [{ translateX: borderX.value }]
      : [{ translateX: withTiming(borderX.value, { duration: 350 }) }];

    return { transform };
  }, [borderX]);

  if (contentList.length === 0) return null;

  return (
    <View>
      {hasFocusedChild && (
        <AnimatedBorder
          width={railConfig.width}
          height={railConfig.height}
          animatedStyle={animatedStyle}
          borderPosition={railConfig.position}
        />
      )}
      <FlatList
        ref={sliderRef}
        horizontal={true}
        data={contentList}
        renderItem={renderItem}
        style={listStyle}
        keyExtractor={keyExtractor}
        showsHorizontalScrollIndicator={false}
        onScrollToIndexFailed={onScrollToIndexFailedHandler}
        ListFooterComponent={false}
        removeClippedSubviews={false}
        scrollEnabled={false}
        inverted={false}
        contentContainerStyle={contentContainerStyle}
      />
    </View>
  );
};

FullViewList.propTypes = IndexPropTypes;
FullViewList.whyDidYouRender = false;

export default withFocusable({ trackChildren: true })(FullViewList);
