import PropTypes from 'prop-types';
import { FONT_SIZES } from '../../../helpers/Enums';

export default {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  color: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(FONT_SIZES)),
  align: PropTypes.string,
  multiline: PropTypes.bool,
  numberOfLines: PropTypes.number,
};
