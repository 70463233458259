import { StyleSheet, View } from 'react-native';
import React from 'react';
import Text from '../../atoms/Text';
import { GetScaledValue } from '@digiturk/screen-size';
import Rounded from '../../atoms/Rounded';

/**
 * TextWithDots
 *
 * @param {object} props - props
 * @param {object} props.containerStyle - containerStyle
 * @param {Array} props.texts - texts
 * @param {string} props.fontFamily - fontFamily
 * @param {string} props.size - size
 * @returns {module:JSX.Element} -
 */
const TextWithDots = ({ containerStyle, texts, fontFamily, size }) => {
  const data = texts?.filter((item) => item !== undefined);

  return (
    <View style={[styles.container, containerStyle]}>
      {data?.map((text, index) => (
        <View key={index} style={styles.itemContainer}>
          <Text text={text.toString()} fontFamily={fontFamily} size={size} />
          {index < data?.length - 1 && <Rounded />}
        </View>
      ))}
    </View>
  );
};

export default TextWithDots;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: GetScaledValue(12),
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: GetScaledValue(12),
  },
});
