import { StyleSheet } from 'react-native';
import {
  GetScaledValue,
  GetWindowHeight,
  GetWindowWidth,
} from '@digiturk/screen-size';
import { SPACING_VALUES } from '../../../helpers/Enums';

/**
 * Sidebar styles
 *
 * @param {boolean} isSidebarOpened - isSidebarOpened
 * @returns {object} - object
 */
const styles = (isSidebarOpened) => {
  return StyleSheet.create({
    external: {
      position: 'absolute',
      zIndex: 99,
      width: isSidebarOpened ? GetWindowWidth() : GetScaledValue(200),
      height: GetWindowHeight(),
    },
    container: {
      flex: 1,
      maxWidth: isSidebarOpened ? GetScaledValue(720) : GetScaledValue(200),
      minHeight: GetWindowHeight(),
      paddingTop: 0,
      paddingStart: 0,
    },
    topContainer: {
      height: GetScaledValue(280),
    },
    brand: {
      alignItems: 'center',
      marginTop: GetScaledValue(60),
    },
    avatar: {
      flex: 1,
      marginTop: GetScaledValue(60),
      paddingStart: GetScaledValue(60),
    },
    bottomMenu: {
      flex: 2,
      justifyContent: 'flex-start',
      paddingStart: GetScaledValue(SPACING_VALUES.SXXXLG),
    },
    bigLogo: {
      position: 'absolute',
      end: GetScaledValue(60),
      top: GetScaledValue(60),
    },
    outerContainer: {
      flex: 8,
      justifyContent: 'flex-start',
      paddingStart: GetScaledValue(SPACING_VALUES.SXXXLG),
    },
    bottomMenuContainer: {
      flex: 1,
    },
    menuItem: {
      marginBottom: GetScaledValue(35),
    },
  });
};

export default styles;
