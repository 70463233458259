import { GetScaledValue } from '@digiturk/screen-size';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.blackTransparent72,
  },
  zContainer: {
    zIndex: 100,
  },
  iconStyle: {
    width: 40,
    height: 33,
    color: colors.shades00,
  },
  textStyle: {
    fontFamily: FONT_FAMILY.REGULAR,
    size: FONT_SIZES.HEADING3,
  },
  backButton: {
    flexDirection: 'row',
    marginStart: GetScaledValue(80),
    marginTop: GetScaledValue(60),
    gap: GetScaledValue(20),
    alignItems: 'center',
  },
  headText: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: GetScaledValue(40),
    gap: GetScaledValue(20),
  },
  highligtedContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: GetScaledValue(10),
  },
  alerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.destructive500,
    borderRadius: GetScaledValue(20),
    paddingVertical: GetScaledValue(10),
    paddingHorizontal: GetScaledValue(20),
    gap: GetScaledValue(12),
  },
  numpadContainer: {
    alignSelf: 'center',
    marginTop: GetScaledValue(40),
  },
  digitContainer: {
    alignItems: 'center',
    gap: GetScaledValue(20),
  },
});

export default styles;
