import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

/**
 * Live Player page styles
 *
 * @returns {object} - styles
 */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.shades100,
  },
});

export default styles;
