import { View } from 'react-native';
import React from 'react';
import Icon from '../../atoms/Icon';
import { ArrowLeftBold, LockStroke } from '../../../assets/icons';
import colors from '../../../helpers/Colors';
import AgeContentRating from '../../molecules/AgeContentRating';
import { ZippyButton, buttonThemes } from '../../molecules/buttons';
import LiveChannelLists from '../../molecules/LiveChannelLists';
import styles from './styles';
import { VIDEO_MODES } from '../../../helpers/Enums';
import MODALS from '../../organisms/WatchModal/modals';

/**
 * TopContainer
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {Function} props.handleModal - handleModal
 * @param {string} props.downFocusKey - downFocusKey
 * @param {Array} props.channelList - channelList from live player
 * @returns {module:JSX.Element} - JSX.Element
 */
const PlayerTopContainer = ({
  videoType,
  handleModal,
  downFocusKey,
  channelList = null,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <Icon
          icon={ArrowLeftBold}
          width={40}
          height={40}
          color={colors.shades00}
        />
        {videoType === VIDEO_MODES.VOD && <AgeContentRating />}
      </View>
      <View style={styles.actionContainer}>
        <ZippyButton
          unfocusedText="4K"
          text="Upgrade your package for 4K Ultra HD quality"
          icon={LockStroke}
          theme={buttonThemes.neutral700}
          themeFocused={buttonThemes.blackWhite}
          style={styles.fourkbutton}
          focusKey="4kButton"
          downFocusKey={downFocusKey}
          rightFocusKey={channelList && 'channel-list-expand-button'}
          onEnterPress={() => handleModal && handleModal(MODALS.FOUR_K)}
        />
        {videoType === VIDEO_MODES.LIVE && (
          <LiveChannelLists
            channelList={channelList}
            downFocusKey={downFocusKey}
          />
        )}
      </View>
    </View>
  );
};

export default PlayerTopContainer;
