import { withFocusable } from '@digiturk/react-spatial-navigation';
import React from 'react';
import { SPACING_VALUES } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import { GetScaledValue } from '@digiturk/screen-size';
import { View, StyleSheet } from 'react-native';
import Avatar from '../../atoms/Avatar';
import { borderWidth } from '../../../helpers/CommonHelper';

/**
 * Item component
 *
 * @param {object} props - props
 * @param {string} props.item - item
 * @param {string} props.focused - focused
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Item = ({ item, focused }) => {
  const styles = style(focused);

  return (
    <View style={styles.itemContainer}>
      <Avatar
        source={item}
        isAddProfile={false}
        isKid={false}
        isLock={false}
        style={styles.avatar}
      />
    </View>
  );
};

/**
 * styles
 *
 * @param {boolean} focused - focused
 * @returns {object} - styles
 */
const style = (focused) => {
  return StyleSheet.create({
    itemContainer: {
      width: GetScaledValue(180),
      height: GetScaledValue(180),
      borderWidth: borderWidth,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: GetScaledValue(SPACING_VALUES.SXXXBIG),
      borderColor: focused ? colors.primary500 : colors.transparent,
    },
    avatar: {
      width: GetScaledValue(160),
      height: GetScaledValue(160),
      resizeMode: 'contain',
    },
  });
};

export default withFocusable()(Item);
