import React from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import Icon from '../../atoms/Icon';
import { TODLogo, Warning } from '../../../assets/icons';

/**
 * Subscription Settings Component
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Subscription = () => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text
          text={'Subscription'}
          size={FONT_SIZES.HEADING3}
          fontFamily={FONT_FAMILY.SEMIBOLD}
        />
        <View style={styles.iconContainer}>
          <Icon icon={TODLogo} width={60} height={60} />
          <Text
            text={'4K'}
            size={FONT_SIZES.HEADING3}
            fontFamily={FONT_FAMILY.SEMIBOLD}
            color={colors.neutral900}
            style={styles.button}
          />
        </View>
      </View>
      <Text
        text={
          'Ultra HD (4K) and HDR video quality. Watch on any phone, tablet, computer, or TV. Benefit from the download feature on 6 devices.'
        }
        size={FONT_SIZES.HEADING4}
        fontFamily={FONT_FAMILY.REGULAR}
        color={colors.neutral300}
        multiline
        numberOfLines={4}
      />
      <View style={styles.warning}>
        <Icon
          icon={Warning}
          width={40}
          height={40}
          style={styles.warningIcon}
          color={colors.primary500}
        />
        <Text
          text={"Unfortunately, we can't process your payment."}
          size={FONT_SIZES.HEADING5}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          color={colors.primary400}
        />
      </View>
      <Text
        text={'Subscription end date: 20/04/2024'}
        size={FONT_SIZES.HEADING5}
        fontFamily={FONT_FAMILY.SEMIBOLD}
      />
    </View>
  );
};

export default Subscription;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral800,
    padding: GetScaledValue(40),
    width: GetScaledValue(640),
    height: GetScaledValue(398),
    borderRadius: GetScaledValue(20),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: GetScaledValue(30),
  },
  iconContainer: {
    flexDirection: 'row',
  },
  button: {
    textAlign: 'center',
    borderRadius: GetScaledValue(15),
    width: GetScaledValue(71),
    height: GetScaledValue(60),
    backgroundColor: colors.primary,
    marginLeft: GetScaledValue(15),
    paddingTop: GetScaledValue(7.5),
  },
  warningIcon: {
    color: colors.primary,
  },
  warning: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: GetScaledValue(20),
    gap: GetScaledValue(20),
    height: GetScaledValue(40),
  },
});
