import { View } from 'react-native';
import React from 'react';
import Avatar from '../../atoms/Avatar';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES, ProfileTypes } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import styles from './styles';

const PROFILE_TYPES = {
  [ProfileTypes.STANDART]: 'Standart Profile',
  [ProfileTypes.RESTRICTED]: 'Restricted Profile',
  [ProfileTypes.KID]: 'Kids Profile',
};

/**
 *
 * @param {object} props -props
 * @param {object} props.item - item
 * @param {object} props.containerStyle - containerStyle
 * @param {boolean} props.focused - focused
 * @param {boolean} props.showType - showType
 * @returns {module:JSX.Element} -
 */
const AvatarWithText = ({ item, containerStyle, focused, showType }) => {
  const style = styles(focused);

  if (!item) return null;

  return (
    <View style={[style.container, containerStyle]}>
      <Avatar
        focused={focused}
        source={item.iconPath}
        style={style.avatar}
        isLock={item.profileType === ProfileTypes.RESTRICTED}
        isKid={item.profileType === ProfileTypes.KID}
        isAddProfile={item.isAddProfile}
        iconWidth={focused ? 130 : 100}
      />
      <View style={style.textContainer}>
        <Text
          text={item.profileName}
          fontFamily={FONT_FAMILY.REGULAR}
          size={FONT_SIZES.HEADING2}
          color={colors.neutral50}
          align="center"
        />
        {showType && (
          <Text
            text={PROFILE_TYPES[item.profileType]}
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING3}
            color={colors.neutral300}
            align="center"
          />
        )}
      </View>
    </View>
  );
};

export default withFocusable()(AvatarWithText);
