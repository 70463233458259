import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';
import {
  isFullScreenSection,
  isWeb,
  itemMargin,
  marginEnd,
  padding,
  sidebarWidth,
  windowHeight,
} from '../../../helpers/CommonHelper';
/**
 *
 * @param {number} distanceFromEdge - distanceFromEdge
 * @param {boolean} hasFocusedChild - hasFocusedChild
 * @param {string} railType - railType
 * @param {string} contentType - contentType
 * @returns {object} - The styles object.
 */
const style = (distanceFromEdge, hasFocusedChild, railType, contentType) => {
  return StyleSheet.create({
    fullScreenContainer: {
      height: windowHeight,
      backgroundColor: colors.blackTransparent72,
    },
    upArrowStyle: {
      position: 'absolute',
      zIndex: 3,
      top: GetScaledValue(60),
      paddingStart: GetScaledValue(140),
    },
    iconStyle: {
      color: colors.shades00,
      width: 40,
      height: 40,
    },
    iconArea: {
      position: 'absolute',
      zIndex: 3,
      top: GetScaledValue(60),
      end: GetScaledValue(140),
    },
    logo: {
      width: GetScaledValue(265),
      height: GetScaledValue(120),
    },
    downArrowStyle: {
      position: 'absolute',
      zIndex: 3,
      bottom: GetScaledValue(60),
      paddingStart: GetScaledValue(140),
    },
    sectionContainer: {
      paddingTop: isFullScreenSection(railType)
        ? 0
        : contentType === 3
        ? GetScaledValue(272)
        : GetScaledValue(192),
      opacity: hasFocusedChild ? 1 : 0.6,
    },
    sectionListStyle: {
      paddingVertical: padding,
      paddingStart: distanceFromEdge + padding,
      marginEnd: isWeb ? sidebarWidth - distanceFromEdge : 0,
    },
    sectionContentContainerStyle: {
      paddingEnd: isWeb ? marginEnd : sidebarWidth + marginEnd + padding,
      gap: itemMargin,
    },
    sectionContentContainerStyleRTL: {
      paddingStart: isWeb ? 0 : distanceFromEdge + padding,
      paddingEnd: isWeb
        ? marginEnd
        : sidebarWidth - distanceFromEdge + marginEnd,
      gap: itemMargin,
    },
    loading: {
      position: 'absolute',
      backgroundColor: 'black',
      zIndex: 99,
      top: 0,
      bottom: 0,
      start: 0,
      right: 0,
    },
  });
};

export default style;
