import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { FONT_FAMILY, FONT_VALUES } from '../../../helpers/Enums';
import Text from '../../atoms/Text';
import colors from '../../../helpers/Colors';
import { titleHeight } from '../../../helpers/CommonHelper';

const blockList = ['Showcase', 'Slider'];

/**
 * Rail Title
 *
 * @param {object} props - props
 * @param {string} props.text - text
 * @param {object} props.containerStyle - containerStyle
 * @param {number} props.railOffsetInlineStart - railOffsetInlineStart
 * @returns {module:JSX.Element} - JSX Element
 */
const RailTitle = ({ text, containerStyle, railOffsetInlineStart }) => {
  return (
    <View
      style={[
        styles.container,
        containerStyle,
        { marginHorizontal: GetScaledValue(railOffsetInlineStart) },
      ]}>
      {!blockList.includes(text) && <Text text={text} style={styles.title} />}
    </View>
  );
};

export default memo(RailTitle);

const styles = StyleSheet.create({
  container: {
    height: titleHeight,
  },
  title: {
    fontSize: GetScaledValue(FONT_VALUES.H2.FONT_SIZE),
    lineHeight: GetScaledValue(FONT_VALUES.H2.LINE_HEIGHT),
    color: colors.shades00,
    fontFamily: FONT_FAMILY.SEMIBOLD,
  },
});
