import { GetScaledValue } from '@digiturk/screen-size';
import {
  BackspaceStroke,
  CapsLock,
  Globe,
  SpaceStroke,
} from '../../../assets/icons';
/**
 *
 * @param {string} prefix prefix
 * @param {string} rightEdgeFocusKey rightEdgeFocusKey
 * @param {string} leftEdgeFocusKey leftEdgeFocusKey
 * @returns {object} KeyboardData
 */
const KeyboardData = (prefix, rightEdgeFocusKey, leftEdgeFocusKey) => {
  const data = {
    en: {
      keys: [
        {
          label: 'a',
          longPress: ['à', 'á', 'â', 'ä', 'æ', 'ã', 'å', 'ā'],
        },
        {
          label: 'b',
          longPress: [],
        },
        {
          label: 'c',
          longPress: ['ç', 'ć', 'č'],
        },
        {
          label: 'd',
          longPress: [],
        },
        {
          label: 'e',
          longPress: ['è', 'é', 'ê', 'ë', 'ē', 'ė', 'ę'],
        },
        {
          label: 'f',
          longPress: [],
        },
        {
          label: 'g',
          longPress: [],
        },
        {
          label: 'h',
          longPress: [],
        },
        {
          label: 'i',
          longPress: ['ì', 'í', 'î', 'ï', 'ī', 'į', 'ı'],
        },
        {
          label: 'j',
          longPress: [],
        },
        {
          label: 'k',
          longPress: [],
        },
        {
          label: 'l',
          longPress: ['ł'],
        },
        {
          label: 'm',
          longPress: [],
        },
        {
          label: 'n',
          longPress: ['ñ', 'ń'],
        },
        {
          label: 'o',
          longPress: ['ò', 'ó', 'ô', 'õ', 'ö', 'ø', 'ō', 'œ'],
        },
        {
          label: 'p',
          longPress: [],
        },
        {
          label: 'q',
          longPress: [],
        },
        {
          label: 'r',
          longPress: [],
        },
        {
          label: 's',
          longPress: ['ß', 'ś', 'š'],
        },
        {
          label: 't',
          longPress: [],
        },
        {
          label: 'u',
          longPress: ['ù', 'ú', 'û', 'ü', 'ū'],
        },
        {
          label: 'v',
          longPress: [],
        },
        {
          label: 'w',
          longPress: [],
        },
        {
          label: 'x',
          longPress: [],
        },
        {
          label: 'y',
          longPress: ['ÿ'],
        },
        {
          label: 'z',
          longPress: ['ž', 'ź', 'ż'],
        },
        {
          label: '1',
          longPress: [],
        },
        {
          label: '2',
          longPress: [],
        },
        {
          label: '3',
          longPress: [],
        },
        {
          label: '4',
          longPress: [],
        },
        {
          label: '5',
          longPress: [],
        },
        {
          label: '6',
          longPress: [],
        },
        {
          label: '7',
          longPress: [],
        },
        {
          label: '8',
          longPress: [],
        },
        {
          label: '9',
          longPress: [],
        },
        {
          label: '0',
          longPress: [],
        },
        {
          label: '@',
          longPress: [],
        },
        {
          label: '_',
          longPress: [],
        },
        {
          label: '&',
          longPress: [],
        },
        {
          label: 'symbols',
          longPress: [],
        },
        {
          label: 'caps',
          longPress: [],
        },
        {
          label: 'globe',
          longPress: [],
        },
        {
          label: 'space',
          longPress: [],
        },
        {
          label: 'backspace',
          longPress: [],
        },
      ],
      keyIconsAndStyles: {
        globe: {
          icon: Globe,
        },
        backspace: {
          icon: BackspaceStroke,
          style: { width: GetScaledValue(124) },
          size: { width: 46, height: 46 },
        },
        space: {
          icon: SpaceStroke,
          style: { width: GetScaledValue(188) },
          size: { width: 60, height: 60 },
        },
        caps: {
          icon: CapsLock,
          style: {
            width: GetScaledValue(124),
            gap: GetScaledValue(4),
          },
          textSize: {
            fontSize: GetScaledValue(24),
            lineHeight: GetScaledValue(30),
            marginStart: GetScaledValue(4),
          },
          text: 'Caps',
        },
        symbols: {
          textSize: {
            fontSize: GetScaledValue(24),
            lineHeight: GetScaledValue(36),
          },
          text: '#+=',
        },
      },
    },
    ar: {
      keys: [
        {
          label: 'ح',
          longPress: [],
        },
        {
          label: 'ج',
          longPress: [],
        },
        {
          label: 'ث',
          longPress: [],
        },
        {
          label: 'ت',
          longPress: [],
        },
        {
          label: 'ب',
          longPress: [],
        },
        {
          label: 'ا',
          longPress: ['أ', 'إ', 'آ', 'ء', 'ى'],
        },
        {
          label: 'س',
          longPress: ['ش'],
        },
        {
          label: 'ز',
          longPress: [],
        },
        {
          label: 'ر',
          longPress: [],
        },
        {
          label: 'ذ',
          longPress: [],
        },
        {
          label: 'د',
          longPress: [],
        },
        {
          label: 'خ',
          longPress: [],
        },
        {
          label: 'ع',
          longPress: [],
        },
        {
          label: 'ظ',
          longPress: [],
        },
        {
          label: 'ط',
          longPress: [],
        },
        {
          label: 'ض',
          longPress: [],
        },
        {
          label: 'ص',
          longPress: [],
        },
        {
          label: 'ش',
          longPress: [],
        },

        {
          label: 'م',
          longPress: [],
        },
        {
          label: 'ل',
          longPress: ['لا'],
        },
        {
          label: 'ك',
          longPress: ['گ'],
        },
        {
          label: 'ق',
          longPress: [],
        },
        {
          label: 'ف',
          longPress: [],
        },
        {
          label: 'غ',
          longPress: [],
        },
        {
          label: 'ء',
          longPress: [],
        },
        {
          label: 'ي',
          longPress: ['ئ', 'ى'],
        },
        {
          label: 'لا',
          longPress: ['لآ'],
        },
        {
          label: 'و',
          longPress: ['ؤ'],
        },
        {
          label: 'ه',
          longPress: ['ة'],
        },
        {
          label: 'ن',
          longPress: [],
        },
        {
          label: '١',
          longPress: [],
        },
        {
          label: '٢',
          longPress: [],
        },
        {
          label: '٣',
          longPress: [],
        },
        {
          label: '٤',
          longPress: [],
        },
        {
          label: '٥',
          longPress: [],
        },
        {
          label: '٦',
          longPress: [],
        },
        {
          label: '٧',
          longPress: [],
        },
        {
          label: '٨',
          longPress: [],
        },
        {
          label: '٩',
          longPress: [],
        },
        {
          label: '٠',
          longPress: [],
        },
        {
          label: 'symbols',
          longPress: [],
        },
        {
          label: 'globe',
          longPress: [],
        },
        {
          label: 'space',
          longPress: [],
        },
        {
          label: 'backspace',
          longPress: [],
        },
      ],
      keyIconsAndStyles: {
        globe: {
          icon: Globe,
        },
        backspace: {
          icon: BackspaceStroke,
          style: { width: GetScaledValue(124) },
          size: { width: 46, height: 46 },
        },
        space: {
          icon: SpaceStroke,
          style: { width: GetScaledValue(188) },
          size: { width: 60, height: 60 },
        },
        symbols: {
          style: {
            width: GetScaledValue(124),
            gap: GetScaledValue(4),
          },
          textSize: {
            fontSize: GetScaledValue(24),
            lineHeight: GetScaledValue(36),
          },
          text: '#+=',
        },
      },
    },
    symbols: {
      keys: [
        {
          label: '!',
          longPress: [],
        },
        {
          label: '@',
          longPress: [],
        },
        {
          label: '#',
          longPress: [],
        },
        {
          label: '$',
          longPress: [],
        },
        {
          label: '%',
          longPress: [],
        },
        {
          label: '^',
          longPress: [],
        },
        {
          label: '&',
          longPress: [],
        },
        {
          label: '*',
          longPress: [],
        },
        {
          label: '(',
          longPress: [],
        },
        {
          label: ')',
          longPress: [],
        },
        {
          label: '-',
          longPress: [],
        },
        {
          label: '_',
          longPress: [],
        },
        {
          label: '=',
          longPress: [],
        },
        {
          label: '+',
          longPress: [],
        },
        {
          label: '[',
          longPress: [],
        },
        {
          label: ']',
          longPress: [],
        },
        {
          label: '\\',
          longPress: [],
        },
        {
          label: '|',
          longPress: [],
        },
        {
          label: ';',
          longPress: [],
        },
        {
          label: ':',
          longPress: [],
        },
        {
          label: "'",
          longPress: [],
        },
        {
          label: '"',
          longPress: [],
        },
        {
          label: ',',
          longPress: [],
        },
        {
          label: '.',
          longPress: [],
        },
        {
          label: '/',
          longPress: [],
        },
        {
          label: '?',
          longPress: [],
        },
        {
          label: '~',
          longPress: [],
        },
        {
          label: '1',
          longPress: [],
        },
        {
          label: '2',
          longPress: [],
        },
        {
          label: '3',
          longPress: [],
        },
        {
          label: '4',
          longPress: [],
        },
        {
          label: '5',
          longPress: [],
        },
        {
          label: '6',
          longPress: [],
        },
        {
          label: '7',
          longPress: [],
        },
        {
          label: '8',
          longPress: [],
        },
        {
          label: '9',
          longPress: [],
        },
        {
          label: '0',
          longPress: [],
        },
        {
          label: '@',
          longPress: [],
        },
        {
          label: '_',
          longPress: [],
        },
        {
          label: '&',
          longPress: [],
        },
        {
          label: 'symbols',
          longPress: [],
        },
        {
          label: 'globe',
          longPress: [],
        },
        {
          label: 'space',
          longPress: [],
        },
        {
          label: 'backspace',
          longPress: [],
        },
      ],
      keyIconsAndStyles: {
        globe: {
          icon: Globe,
        },
        backspace: {
          icon: BackspaceStroke,
          style: { width: GetScaledValue(124) },
          size: { width: 46, height: 46 },
        },
        space: {
          icon: SpaceStroke,
          style: { width: GetScaledValue(188) },
          size: { width: 60, height: 60 },
        },
        symbols: {
          style: {
            width: GetScaledValue(124),
            gap: GetScaledValue(4),
          },
          textSize: {
            fontSize: GetScaledValue(24),
            lineHeight: GetScaledValue(36),
          },
          text: 'ABC',
        },
      },
    },
    special: {
      0: {
        upFocusKey: 41,
        leftFocusKey: leftEdgeFocusKey ? leftEdgeFocusKey : `${prefix}-5`,
      },
      1: {
        upFocusKey: 42,
      },
      2: {
        upFocusKey: 42,
      },
      3: {
        upFocusKey: 42,
      },
      4: {
        upFocusKey: 43,
      },
      5: {
        upFocusKey: 43,
      },
      35: {
        downFocusKey: 40,
      },
      36: {
        downFocusKey: 41,
        leftFocusKey: leftEdgeFocusKey ? leftEdgeFocusKey : `${prefix}-40`,
      },
      37: {
        downFocusKey: 42,
      },
      38: {
        downFocusKey: 42,
      },
      39: {
        downFocusKey: 42,
      },
      40: {
        rightFocusKey: rightEdgeFocusKey ? rightEdgeFocusKey : `${prefix}-36`,
        downFocusKey: 43,
      },
      41: {
        rightFocusKey: `${prefix}-42`,
        leftFocusKey: leftEdgeFocusKey ? leftEdgeFocusKey : `${prefix}-43`,
        upFocusKey: 36,
        downFocusKey: 0,
      },
      42: {
        upFocusKey: 38,
        downFocusKey: 2,
        leftFocusKey: `${prefix}-41`,
      },
      43: {
        upFocusKey: 40,
        rightFocusKey: rightEdgeFocusKey ? rightEdgeFocusKey : `${prefix}-41`,
        downFocusKey: 5,
      },
    },
    upThreshold: 6,
    downThreshold: 47,
  };

  return data;
};

export { KeyboardData };
