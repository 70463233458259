import React, { useRef, memo } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import PlayerStatistics from '../../organisms/PlayerStatistics';

const teams = [
  {
    team: 'Real Madrid',
    totalPasses: 235,
    accuracy: '77%',
    title: 'Total Passes / Accuracy',
    players: [
      { name: 'Raphael Varane', passes: 31, accuracy: '90%' },
      { name: 'Bruno Fernandes', passes: 27, accuracy: '63%' },
      { name: 'Andre Onana', passes: 27, accuracy: '63%' },
      { name: 'Kobbie Mainoo', passes: 27, accuracy: '63%' },
      { name: 'Aaron Wan Bisaka', passes: 27, accuracy: '63%' },
    ],
  },
  {
    team: 'Barcelona',
    totalPasses: 235,
    accuracy: '77%',
    title: 'Total Passes / Accuracy',
    players: [
      { name: 'Raphael Varane', passes: 31, accuracy: '90%' },
      { name: 'Bruno Fernandes', passes: 27, accuracy: '63%' },
      { name: 'Andre Onana', passes: 27, accuracy: '63%' },
      { name: 'Kobbie Mainoo', passes: 27, accuracy: '63%' },
      { name: 'Aaron Wan Bisaka', passes: 27, accuracy: '63%' },
    ],
  },
];

/**
 * Top Players
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const TopPlayers = () => {
  const flatListRef = useRef(null);

  const data = Array.from({ length: 2 }, (_, i) => ({
    key: `focusKey-${i + 1}`,
    focusKey: `focusKey-${i + 1}`,
    downFocusKey: `focusKey-${i + 2}`,
    upFocusKey: i > 0 ? `focusKey-${i}` : 'Commentary',
    teams: teams,
  }));

  /**
   * Flat list scroll controller
   *
   * @param {number} index - index
   */
  const handleFocus = (index) => {
    if (flatListRef.current) {
      flatListRef.current.scrollToIndex({ index, animated: true });
    }
  };

  /**
   * Flat list render
   *
   * @param {object} props - props
   * @param {object} props.item - render item
   * @param {number} props.index - index
   * @returns {React.JSX.Element} - returns flatlist items
   */
  const renderItem = ({ item, index }) => (
    <PlayerStatistics {...item} onFocus={() => handleFocus(index)} />
  );

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        ref={flatListRef}
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item.key}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.statisticsArea}
        scrollEnabled={true}
        onLayout={() => {
          flatListRef.current?.scrollToIndex({ index: 0, animated: false });
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: GetScaledValue(20),
    borderWidth: 4,
    borderColor: colors.gray200,
    borderRadius: 10,
  },
  header: {
    fontSize: 24,
    color: colors.shades100,
    textAlign: 'center',
    marginBottom: GetScaledValue(20),
  },
  teams: {
    width: GetScaledValue(300),
    padding: GetScaledValue(10),
  },
  teamContainer: {
    padding: GetScaledValue(20),
    margin: GetScaledValue(10),
    width: GetScaledValue(720),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  teamHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: GetScaledValue(10),
  },
  teamLogo: {
    width: GetScaledValue(40),
    height: GetScaledValue(40),
    marginRight: GetScaledValue(10),
  },
  playerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: GetScaledValue(5),
  },
});

export default memo(TopPlayers);
