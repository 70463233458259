import React from 'react';
import { marginBottom, sidebarWidth } from '../../../helpers/CommonHelper';
import styles from './styles';
import { View } from 'react-native';
import FullViewList from '../../organisms/FullViewList';
import useLocalization from '../../../libs/localization/useLocalization';
import { withFocusable } from '@digiturk/react-spatial-navigation';

/**
 * Rail Item
 *
 * @param {object} props - props
 * @param {object} props.item - item
 * @param {number} props.index - index
 * @param {string} props.prefix - prefix
 * @param {string} props.leftFocusKey - leftFocusKey
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.setGlobalFocusedItem - setGlobalFocusedItem
 * @param {object} props.onScrollHandler - onScrollHandler
 * @returns {module:JSX.Element} -
 */
const RailItem = ({
  item,
  index,
  prefix,
  leftFocusKey,
  onEnterPress,
  setGlobalFocusedItem,
  onScrollHandler,
}) => {
  const { isRTL } = useLocalization();

  return (
    <View style={{ marginBottom }}>
      <FullViewList
        moduleIndex={index}
        railType={item?.railType}
        railURL={item && item}
        distanceFromEdge={sidebarWidth}
        listStyle={styles.listStyle}
        contentContainerStyle={
          isRTL ? styles.contentContainerStyleRTL : styles.contentContainerStyle
        }
        onEnterPress={onEnterPress}
        focusKey={`${prefix}-${index}`}
        focusKeyPrefix={`${prefix}-${index}`}
        leftFocusKey={leftFocusKey}
        onScrollHandler={onScrollHandler}
        upFocusKey={`${prefix}-${index - 1}`}
        downFocusKey={`${prefix}-${index + 1}`}
        setGlobalFocusedItem={setGlobalFocusedItem}
      />
    </View>
  );
};

export default React.memo(withFocusable({ trackChildren: true })(RailItem));
