import TYPES from './types';
import STORAGE_KEYS from '../storageKeys';
import { setStorage } from '../storage';

const actions = {
  init: () => ({
    type: TYPES.INIT,
  }),

  setProfiles: (profiles) => {
    return {
      type: TYPES.SET_PROFILES,
      payload: profiles,
    };
  },

  setSelectedProfile: (profile) => {
    setStorage(STORAGE_KEYS.selectedProfile, profile);

    return {
      type: TYPES.SET_SELECTED_PROFILE,
      payload: profile,
    };
  },

  updateProfile: (profile) => {
    return {
      type: TYPES.UPDATE_PROFILE,
      payload: profile,
    };
  },

  addProfile: (profile) => {
    return {
      type: TYPES.ADD_PROFILE,
      payload: profile,
    };
  },

  deleteProfile: (userProfileId) => {
    return {
      type: TYPES.DELETE_PROFILE,
      payload: userProfileId,
    };
  },

  error: (error) => {
    return {
      type: TYPES.ERROR,
      payload: error,
    };
  },
};

export default actions;
