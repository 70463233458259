import React, { useState, useCallback, useEffect, useRef } from 'react';
import { View, FlatList } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { GetScaledValue, GetWindowHeight } from '@digiturk/screen-size';
import {
  runOnUI,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { setFocusCustom } from '../../../helpers/FocusHelper';
import {
  getRailItemConfigurations,
  isAnimationsDisabled,
  sidebarWidth,
} from '../../../helpers/CommonHelper';
import useLocalization from '../../../libs/localization/useLocalization';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { getLink } from '../../../helpers/CommonHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import Hero from '../../molecules/SelectedComponent/Hero';
import RailTitle from '../../molecules/RailTitle';
import AnimatedBorder from '../../molecules/AnimatedBorder';

import Skeleton from './skeleton';
import FocusableItem from './item';
import { PageContentPropTypes } from './proptypes';
import styles from './styles';
import { makeApiCall } from '../../../middleware/dynamic';
import { ApiRels } from '../../../helpers/Enums';

/**
 * Grid View component of pages
 *
 * @param {object} props - props
 * @param {object} props.contentSource - content source
 * @param {string} props.prefix - prefix
 * @param {string} props.apiKey - apiKey
 * @param {string|boolean} props.leftFocusKey - leftFocusKey
 * @returns {module:JSX.Element} - JSX.Element
 */
const GridView = ({ contentSource, prefix, apiKey, leftFocusKey }) => {
  const sliderRef = useRef();
  const { isRTL } = useLocalization();
  const [isLoading, setIsLoading] = useState(true);
  const [globalFocusedItem, setGlobalFocusedItem] = useState({});
  const [contentData, setContentData] = useState();
  const { navigate } = useCustomNavigation();
  const railConfig = getRailItemConfigurations(contentSource.railType);
  const itemFullWidth = GetScaledValue(railConfig?.width + 40);
  const itemFullHeight = GetScaledValue(railConfig?.height + 30);
  const columns = Math.floor(GetScaledValue(1720) / itemFullWidth);

  const borderX = useSharedValue(0);

  /**
   * Fetch Page Rail List
   */
  useEffect(() => {
    if (contentSource.isReady) {
      (async () => {
        const contentRequest = await makeApiCall({ url: contentSource.url });

        setContentData(contentRequest);

        // loading finish
        setTimeout(() => setFocusCustom(`viewall-raillist-gridview-0`), 700);
        setTimeout(() => setIsLoading(false), 1000);
      })();
    }
  }, [apiKey, contentSource]);

  /**
   * Rail Item OnEnterPress
   *
   * @param {object} selected - selected item
   */
  const onEnterPress = useCallback((selected) => {
    const { url } = getLink(selected.item, ApiRels.SELF);

    navigate(NavigationRoutes.contentdetail, {
      url: url,
      contentType: selected?.item?.contentType?.type || 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyExtractor = useCallback(
    (_item, index) => `${prefix}_${index}`,
    [prefix]
  );

  /**
   * Row calculation
   *
   * @param {number} index - index
   * @returns {number} - row
   */
  const calcRow = (index) => Math.ceil((index + 1) / columns) - 1;

  /**
   * onBecameFocused
   */
  const onBecameFocused = useCallback(
    (_coordinate, selected) => {
      setGlobalFocusedItem((_data) => {
        return {
          ...selected.item,
          ...railConfig,
          ...contentSource,
        };
      });

      const calcHorizontal =
        (selected?.index % columns) * itemFullWidth - GetScaledValue(12);

      runOnUI(() => {
        borderX.value = isRTL ? calcHorizontal * -1 : calcHorizontal;
      })();

      const offsetVertical = itemFullHeight * calcRow(selected?.index);

      sliderRef?.current?.scrollToOffset({
        offset: offsetVertical,
        viewPosition: 0,
        animated: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentData, contentSource]
  );

  const animatedStyle = useAnimatedStyle(() => {
    const transform = isAnimationsDisabled
      ? [{ translateX: borderX.value }]
      : [{ translateX: withTiming(borderX.value, { duration: 350 }) }];

    return { transform };
  }, [borderX]);

  /**
   * Render item
   */
  const renderItem = useCallback(
    ({ item, index }) => (
      <View style={styles.renderItem}>
        <FocusableItem
          item={item}
          railType={contentSource.railType}
          posterWidth={railConfig.width}
          posterHeight={railConfig.height}
          index={index}
          focusKey={`${prefix}-gridview-${index}`}
          onBecameFocused={onBecameFocused}
          onEnterPress={onEnterPress}
          rightFocusKey={
            index % columns === columns - 1
              ? false
              : `${prefix}-gridview-${index + 1}`
          }
          leftFocusKey={
            index % columns === 0
              ? 'sidebar'
              : `${prefix}-gridview-${index - 1}`
          }
          upFocusKey={
            index === 0 ? false : `${prefix}-gridview-${index - columns}`
          }
          downFocusKey={
            index >= contentData?.items?.length - columns
              ? false
              : `${prefix}-gridview-${index + columns}`
          }
        />
      </View>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentData?.items]
  );

  /**
   * onScrollToIndexFailedHandler
   */
  const onScrollToIndexFailedHandler = useCallback(
    ({ index, averageItemLength }) => {
      sliderRef?.current?.scrollToOffset({
        offset: index * averageItemLength,
        animated: true,
      });

      setTimeout(() => {
        sliderRef?.current?.scrollToIndex({
          index: index,
          animated: true,
        });
      }, 100);
    },
    []
  );

  if (!contentData?.items.length) return false;

  return (
    <View style={styles.pageArea}>
      {isLoading && <Skeleton />}
      <Hero globalFocusedItem={globalFocusedItem} />
      <View
        style={[
          styles.gridContainer,
          {
            top: GetScaledValue(railConfig.railTop),
            height: GetWindowHeight() - GetScaledValue(railConfig.railTop),
          },
        ]}>
        <RailTitle
          text={contentData?.title}
          railOffsetInlineStart={sidebarWidth}
        />
        <View style={styles.flatListWrapper}>
          <View style={styles.flatListArea}>
            <AnimatedBorder
              width={railConfig.width}
              height={railConfig.height}
              animatedStyle={animatedStyle}
              borderPosition={railConfig.position}
            />
            <FlatList
              ref={sliderRef}
              horizontal={false}
              numColumns={columns}
              data={contentData?.items}
              renderItem={renderItem}
              style={styles.listStyle}
              keyExtractor={keyExtractor}
              showsHorizontalScrollIndicator={false}
              removeClippedSubviews={false}
              scrollEnabled={false}
              inverted={false}
              contentContainerStyle={styles.contentContainerStyle}
              columnWrapperStyle={styles.columnWrapperStyle}
              showsVerticalScrollIndicator={false}
              onScrollToIndexFailed={onScrollToIndexFailedHandler}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

GridView.propTypes = PageContentPropTypes;
GridView.whyDidYouRender = false;

export default withFocusable()(GridView);
