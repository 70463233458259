import { GetScaledValue } from '@digiturk/screen-size/src';
import { StyleSheet } from 'react-native';
import { FONT_FAMILY, FONT_SIZES } from '../../../../helpers/Enums';
import colors from '../../../../helpers/Colors';

const styles = StyleSheet.create({
  page: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainerHeader: {
    paddingBottom: GetScaledValue(20),
  },
  titleOptions: {
    fontFamily: FONT_FAMILY.SEMIBOLD,
    size: FONT_SIZES.HEADING1,
  },
  textContainerContent: {
    paddingBottom: GetScaledValue(60),
  },
  contentOptions: {
    fontFamily: FONT_FAMILY.REGULAR,
    size: FONT_SIZES.HEADING3,
    color: colors.neutral300,
  },
  buttonContainerStyle: {
    paddingBottom: GetScaledValue(8),
  },
  buttonStyle: {
    width: GetScaledValue(400),
    height: GetScaledValue(80),
    borderRadius: GetScaledValue(12),
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  highlightedTextStyle: {
    paddingBottom: GetScaledValue(20),
  },
  highlightedTextOptions: {
    fontFamily: FONT_FAMILY.SEMIBOLD,
    size: FONT_SIZES.HEADING1,
    color: colors.primary500,
  },
});

export default styles;
