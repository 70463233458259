import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import { windowHeight } from '../../../helpers/CommonHelper';

/**
 * ExpandedContainer styles
 *
 * @returns {object} - styles
 */
const style = StyleSheet.create({
  expandedContainer: {
    width: GetScaledValue(800),
    height: windowHeight - GetScaledValue(320),
    position: 'absolute',
    top: GetScaledValue(100),
    right: 0,
    zIndex: 999,
  },
  listItemContainer: {
    gap: GetScaledValue(10),
  },
});

export default style;
