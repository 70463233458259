import React, {
  createContext,
  useEffect,
  useContext,
  useReducer,
  useCallback,
} from 'react';
import { isEmpty } from '../helper';
import appActions from './actions';
import reducer from './reducer';
import { getStorage } from '../storage';
import STORAGE_KEYS from '../storageKeys';

const initialState = {
  appState: {
    isInitiated: false,
    languageDirection: 'ltr',
  },
};

const App = createContext();

/**
 * Context Hook
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const useApp = () => useContext(App);

/**
 * App Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDeviceStore = useCallback(async () => {
    // Check "languageDirection" in device storage
    const appStorageLanguageDirection = await getStorage(
      STORAGE_KEYS.languageDirection
    );

    if (!isEmpty(appStorageLanguageDirection)) {
      await dispatch(
        appActions.setLanguageDirection(appStorageLanguageDirection)
      );
    }

    await dispatch(appActions.init());
  }, []);

  useEffect(() => {
    getDeviceStore();
  }, [getDeviceStore]);

  const data = {
    ...state,
    appDispatch: dispatch,
  };

  return <App.Provider value={data}>{children}</App.Provider>;
};
