import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
    marginTop: GetScaledValue(40),
    flexDirection: 'row',
  },
  childContainer: {
    width: GetScaledValue(1120),
  },
  avatarContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: GetScaledValue(60),
  },
  avatar: {
    width: GetScaledValue(260),
    height: GetScaledValue(260),
    borderRadius: GetScaledValue(20),
    backgroundColor: colors.neutral800,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconStyle: {
    width: 40,
    height: 33,
    color: colors.shades00,
  },
  textStyle: {
    fontFamily: FONT_FAMILY.REGULAR,
    size: FONT_SIZES.HEADING3,
  },
  backButton: {
    flexDirection: 'row',
    gap: GetScaledValue(20),
    alignItems: 'center',
  },
});

export default styles;
