/* eslint-disable jsdoc/require-returns-check */
import React, { memo } from 'react';
import PropList from './proptypes';

/**
 * Icon
 *
 * @param {object} props - props
 * @param {any} props.icon - SVG URL or image URL
 * @param {number} props.width - width
 * @param {number} props.height - height
 * @param {any} props.style - style
 * @param {string} props.color - color
 * @param {any} props.svg - svd
 * @returns {React.JSX.Element} - JSX.Element
 */
const Icon = ({
  icon: IconComponent,
  width,
  height,
  style,
  color,
  ...props
}) => {
  if (typeof IconComponent === 'string') {
    return (
      <img
        src={IconComponent}
        alt=""
        style={{ width, height, ...style }}
        {...props}
      />
    );
  }

  if (IconComponent) {
    return (
      <IconComponent
        width={width}
        height={height}
        style={{ fill: color, ...style }}
        {...props}
      />
    );
  }
};

Icon.propTypes = PropList;
export default memo(Icon);
