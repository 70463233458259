import { lazy } from 'react';

const Carousel = lazy(() => import('../../organisms/Carousel'));
const ComingSoon = lazy(() => import('../../organisms/ComingSoon'));
const FootballCompetitions = lazy(() =>
  import('../../organisms/CarouselDouble')
);
const Collections = lazy(() => import('../../organisms/Collections'));

const railComponentsMap = {
  Default: Carousel,
  ComingSoon: ComingSoon,
  Collection: Collections,
  FootballCompetitions: FootballCompetitions,
};

export default railComponentsMap;
