import { StyleSheet, View } from 'react-native';
import React, { useCallback } from 'react';
import { GetScaledValue } from '@digiturk/screen-size';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import Text from '../../atoms/Text';
import {
  FONT_FAMILY,
  FONT_SIZES,
  ProfileTypes,
  SectionTypes,
} from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import { useFocusEffect } from '@react-navigation/native';
import ButtonWithDescription from '../../molecules/ButtonWithDescription';
import { LockStroke, PinWheel, Smily } from '../../../assets/icons';

/**
 *
 * @param {object} props -
 * @param {object} props.currentProfile -
 * @param {Function} props.onSelectHandler -
 * @returns {module:JSX.Element}-
 */
const EditProfileType = ({ currentProfile, onSelectHandler }) => {
  const prefix = 'profile-type-buttons-';

  const buttons = [
    {
      id: 0,
      type: ProfileTypes.STANDART,
      label: 'Standart',
      description:
        "The standard profile offers unrestricted access to the entire catalog, providing personalized content recommendations based on users' individual viewing habits.",
      icon: Smily,
      isAvailable: true,
    },
    {
      id: 1,
      type: ProfileTypes.RESTRICTED,
      label: 'Restricted',
      description:
        'PIN-protected profile feature allows users to personalize their viewing experience and enhance their security by securing specific profiles with a PIN code.',
      icon: LockStroke,
      isAvailable: currentProfile?.profileType !== ProfileTypes.KID,
    },
    {
      id: 2,
      type: ProfileTypes.KID,
      label: 'Kids',
      description:
        "The TOD's Kids profile feature provides a safe viewing environment designed for children to discover content appropriate for their age.",
      icon: PinWheel,
      isAvailable: !currentProfile?.isMainProfile,
    },
  ];

  const filteredButtons = buttons.filter((btn) => btn.isAvailable);

  useFocusEffect(
    useCallback(() => {
      setFocusCustom(prefix + '0');
    }, [])
  );

  const onEnterPress = useCallback(
    (selected) => {
      if (currentProfile?.profileType !== selected.item.type) {
        if (selected.item.type === ProfileTypes.RESTRICTED) {
          onSelectHandler(SectionTypes.PinCode);
        } else {
          onSelectHandler(SectionTypes.Main, {
            profileType: selected.item.type,
          });
        }
      }
    },
    [currentProfile, onSelectHandler]
  );

  return (
    <View style={styles.container}>
      <Text
        text={'Profile Type'}
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.DISPLAYSMALL}
        color={colors.shades00}
        style={styles.titleContainer}
      />
      <View style={styles.buttonContainer}>
        {filteredButtons.map((item, index) => (
          <ButtonWithDescription
            key={index}
            item={item}
            profileType={currentProfile?.profileType}
            focusKey={prefix + index.toString()}
            onEnterPress={onEnterPress}
            downFocusKey={
              index < filteredButtons.length - 1
                ? prefix + (index + 1).toString()
                : false
            }
            upFocusKey={prefix + (index - 1).toString()}
          />
        ))}
      </View>
    </View>
  );
};

export default EditProfileType;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  titleContainer: {
    marginBottom: GetScaledValue(40),
  },
  buttonContainer: {
    gap: GetScaledValue(8),
  },
});
