import { Dimensions } from 'react-native';

/**
 * Returns correct value for given value according to window width.
 *
 * @param {number} data - Value to be scaled
 * @returns {number} - Scaled value
 */
export const GetScaledValue = (data) => {
  return data / (1920 / Dimensions.get('window').width);
};

/**
 * Returns window width
 *
 * @returns {number} - Window width
 */
export const GetWindowWidth = () => {
  return Dimensions.get('window').width;
};

/**
 * Returns window height
 *
 * @returns {number} - Window height
 */
export const GetWindowHeight = () => {
  return Dimensions.get('window').height;
};

/**
 * Grid
 * Splits the screen at the specified ratio
 *
 * @param {number} sum - Total number of divisions
 * @param {number} div - Number of divisions
 * @returns {number} - Grid width
 */
export const GetGrid = (sum, div) => {
  return (GetWindowWidth() / sum) * div;
};

/**
 * The value that comes with different resolutions gives the general resolution (1920) value.
 *
 * @param {number} data - Value to be scaled
 * @returns {number} - Scaled value
 */
export const convertToGlobalResolution = (data) => {
  const resolutionRatio = 1920 / Dimensions.get('window').width;

  return data * resolutionRatio;
};
