import React from 'react';
import { View } from 'react-native';
import { buttonThemes, ZippyButton } from '../../molecules/buttons';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Item
 *
 * @param {object} props - props
 * @param {string} props.unfocusedText - unFocused Text
 * @param {string} props.text - text
 * @param {object} props.icon - icon
 * @param {string} props.focusKey - focusKey
 * @param {boolean} props.focused - focused
 * @param {string} props.rightFocusKey - rightFocusKey
 * @param {number} props.index - index
 * @param {string} props.size - size
 * @param {Function} props.onBecameFocus - onBecameFocus
 * @returns {module:JSX.Element} - JSX.Element
 */
const Item = ({
  unfocusedText,
  text,
  icon,
  focusKey,
  rightFocusKey,
  focused,
  index,
  size,
  onBecameFocus,
}) => {
  const themeCustom = focused
    ? buttonThemes.neutralPrimary
    : buttonThemes.transparent24;

  return (
    <View style={{ marginRight: GetScaledValue(20) }}>
      <ZippyButton
        style={{
          minWidth: GetScaledValue(160),
          justifyContent: 'center',
        }}
        unfocusedText={focused ? text : unfocusedText}
        text={text}
        focused={focused}
        index={index}
        icon={icon}
        size={size}
        rightFocusKey={rightFocusKey}
        focusKey={focusKey}
        theme={themeCustom}
      />
    </View>
  );
};

export default withFocusable()(Item);
