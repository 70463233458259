import { StyleSheet } from 'react-native';

import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

/**
 * View All Styles
 */
const styles = StyleSheet.create({
  message: {
    marginBottom: GetScaledValue(40),
  },
  messageTitle: {
    color: colors.primary500,
    marginBottom: GetScaledValue(8),
  },
  messageParagraph: {
    color: colors.shades00,
  },
});

export default styles;
