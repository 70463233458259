import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: GetScaledValue(35),
    marginRight: GetScaledValue(0),
    flexDirection: 'row',
  },
  progressBar: {
    flex: 1,
    marginHorizontal: GetScaledValue(16),
  },
  liveView: {
    flexDirection: 'column',
    marginTop: GetScaledValue(25),
  },
  vodView: {
    marginTop: GetScaledValue(47),
  },
  iconTextStyle: {
    fontSize: GetScaledValue(23),
    marginTop: GetScaledValue(10),
    marginLeft: GetScaledValue(35),
    fontWeight: 'normal',
    color: '#fff',
    position: 'absolute',
  },
  liveTextWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: GetScaledValue(10),
    borderColor: colors.neutral400,
    borderWidth: GetScaledValue(1),
    borderRadius: GetScaledValue(100),
    paddingHorizontal: GetScaledValue(20),
    paddingVertical: GetScaledValue(8),
  },
  redDot: {
    width: GetScaledValue(10),
    height: GetScaledValue(10),
    backgroundColor: colors.destructive500,
    borderRadius: GetScaledValue(100),
    marginRight: GetScaledValue(5),
  },
});

export default styles;
