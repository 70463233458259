import React, { memo, Suspense, useMemo } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import useLocalization from '../../../libs/localization/useLocalization';
import SelectedSectionComponent from '../../molecules/SelectedComponent/Section';

import style from './styles';
import useGetRailContent from '../../../hooks/useGetRailContent';

/**
 * Renders a section component.
 *
 * @param {object} props - The component props.
 * @param {object} props.item - The item object.
 * @param {number} props.index - The index of the item.
 * @param {string} props.focusKeyPrefix - The focusKeyPrefix string.
 * @param {Function} props.setGlobalFocusedItem - The setGlobalFocusedItem function.
 * @param {Function} props.setImage - The setImage function.
 * @param {string|boolean} props.hasFocusedChild - hasFocusedChild
 * @param {number} props.contentLength - The content length.
 * @param {number} props.contentType - The content type.
 * @returns {module:JSX.Element} The rendered section component.
 */
const Section = ({
  item,
  index,
  focusKeyPrefix,
  contentLength,
  setGlobalFocusedItem,
  setImage,
  hasFocusedChild,
  contentType,
}) => {
  const { isRTL } = useLocalization();

  const { railContentData } = useGetRailContent({ item });

  const distanceFromEdge = GetScaledValue(140);
  const styles = style(
    distanceFromEdge,
    hasFocusedChild,
    item.railType,
    contentType
  );

  const SelectedComponent = useMemo(
    () =>
      SelectedSectionComponent[item.railType] ||
      SelectedSectionComponent.default,
    [item.railType]
  );

  const upFocusKey = index > 0 ? `${focusKeyPrefix}-${index - 1}` : false;
  const downFocusKey =
    index < contentLength - 1 ? `${focusKeyPrefix}-${index + 1}` : false;

  if (!railContentData) return null;

  return (
    <View style={styles.sectionContainer}>
      <Suspense>
        <SelectedComponent
          title={item.title}
          contentData={railContentData}
          contentType={contentType}
          index={index}
          listStyle={styles.sectionListStyle}
          distanceFromEdge={distanceFromEdge}
          focusKeyPrefix={`${focusKeyPrefix}-${index}`}
          focusKey={`${focusKeyPrefix}-${index}`}
          setGlobalFocusedItem={setGlobalFocusedItem}
          upFocusKey={upFocusKey}
          downFocusKey={downFocusKey}
          setImage={setImage}
          railType={item.railType}
          contentContainerStyle={
            isRTL
              ? styles.sectionContentContainerStyleRTL
              : styles.sectionContentContainerStyle
          }
          railOffsetStartOfPage={0}
          railOffsetInlineStart={140}
          railWidth={1920}
          railItemGap={40}
        />
      </Suspense>
    </View>
  );
};

export default memo(withFocusable({ trackChildren: true })(Section));
