import { Platform } from 'react-native';

import SpatialNavigation from '@digiturk/react-spatial-navigation/src/spatialNavigation';

import NavigationKeys from '../helpers/NavigationKeys/index';
import AbstractKeys from '../helpers/NavigationKeys/abstractKeys';
import { isWeb } from './CommonHelper';

const longPressQueue = [];
var isLongPressForWeb = false;

export const isLongPressActive = isWeb
  ? isLongPressForWeb
  : longPressQueue.length !== 0;

/**
 * Get Current Focus Key
 *
 * @returns {string} - current focus key
 */
export const getCurrentFocusKey = () => {
  return SpatialNavigation.focusKey;
};

/**
 * Update Layouts For Native
 */
export const updateLayoutsForNative = () => {
  Object.keys(SpatialNavigation.focusableComponents).map((focusableItemKey) => {
    const focusableItem =
      SpatialNavigation.focusableComponents[focusableItemKey];

    if (focusableItem.layout.node) {
      focusableItem.layout.node?.measure((fx, fy, width, height, px, py) => {
        focusableItem.layout = {
          node: focusableItem.layout.node,
          height: height,
          width: width,
          top: Math.ceil(py),
          y: 0,
          left: Math.ceil(px),
          x: 0,
        };
      });
    } else {
      focusableItem.node?.measure((fx, fy, width, height, px, py) => {
        focusableItem.layout = {
          node: focusableItem.layout.node,
          height: height,
          width: width,
          top: Math.ceil(py),
          y: 0,
          left: Math.ceil(px),
          x: 0,
        };
      });
    }
  });
};

/**
 * Update Layouts Custom
 */
export const updateLayoutsCustom = () => {
  setTimeout(() => {
    updateLayoutsForPlatform();
  }, 10);
};

/**
 * Update Layouts for Platform
 */
const updateLayoutsForPlatform = () => {
  if (Platform.OS !== 'web') {
    updateLayoutsForNative();
  } else {
    SpatialNavigation.updateAllLayouts();
  }
};

/**
 * Navigate By Direction Custom
 *
 * @param {string} direction direction
 */
export const navigateByDirectionCustom = (direction) => {
  SpatialNavigation.navigateByDirection(direction);
};

/**
 * Navigate and Update Layouts
 *
 * @param {string} direction direction
 */
export const navigateAndUpdateLayouts = (direction) => {
  navigateByDirectionCustom(direction);

  updateLayoutsCustom();
};

/**
 * Trigger onEnterPressHandler function for spatial navigation
 */
export const triggerOnEnterPressHandler = () => {
  SpatialNavigation.focusableComponents[
    SpatialNavigation.focusKey
  ]?.onEnterPressHandler();
};

/**
 * Trigger Arrow Press function
 *
 * @param {string} direction - direction
 * @returns {boolean} result
 */
export const triggerArrowPress = (direction) => {
  const result =
    SpatialNavigation.focusableComponents[
      SpatialNavigation.focusKey
    ]?.onArrowPressHandler(direction);

  if (result !== false) {
    return true;
  }

  return false;
};

/**
 * Custom SetFocus method for spatial navigation
 *
 * @param {string} focusKey - focusKey
 */
export const setFocusCustom = (focusKey) => {
  setTimeout(() => {
    SpatialNavigation.setFocus(focusKey);
    updateLayoutsCustom();
  }, 10);
};

let focusableComponentsStack = [];

/**
 * Empty Focusable Components Stack
 */
export const emptyFocusableComponentsStack = () => {
  focusableComponentsStack = [];
};

/**
 * Push FocusableComponents to Stack
 */
export const pushFocusableComponentsToStack = () => {
  focusableComponentsStack.push({
    focusables: { ...SpatialNavigation.focusableComponents },
    lastFocusedKeyOnThisPage: SpatialNavigation.focusKey,
  });

  SpatialNavigation.focusableComponents = {};
};

/**
 * Pop FocusableComponents to Stack
 */
export const popFocusableComponentsFromStack = () => {
  if (focusableComponentsStack.length <= 0) {
    console.info(
      'There is no focusable component in focusable components stack, moving on without any operation...'
    );

    return;
  }

  const poppedItem = focusableComponentsStack.pop();

  SpatialNavigation.focusableComponents = {};
  SpatialNavigation.focusableComponents = {
    ...poppedItem.focusables,
  };

  setFocusCustom(poppedItem.lastFocusedKeyOnThisPage);
};

/**
 * Get Current Focusable Object
 *
 * @returns {object} focusable object
 */
const getCurrentFocusableObject = () => {
  return SpatialNavigation.focusableComponents[SpatialNavigation.focusKey];
};

/**
 * Get Focusable Object By Key
 *
 * @param {string} focusKey - focusKey
 * @returns {object} focusable object
 */
const getFocusableObjectByKey = (focusKey) => {
  return SpatialNavigation.focusableComponents[focusKey];
};

/**
 * Is Focusable Object Available?
 *
 * @param {string} key - key of the focusable object
 * @returns {boolean} - true if focusable object is available, false otherwise
 */
export const isFocusableAvailable = (key) => {
  if (getFocusableObjectByKey(key) !== undefined) {
    return true;
  }

  return false;
};

/**
 * PressedKey Event Handler
 *
 * @param {string} result pressed key code
 */
export const pressedKeyEventHandler = (result) => {
  let key;

  isLongPressForWeb = result.repeat === true;

  if (Platform.OS === 'web') {
    //INFO: Long key codes are temporary.
    //The codes are calculated by multiplied 10 to the original key code.
    key = isLongPressForWeb ? result.keyCode * 10 : result.keyCode;
    result.eventKeyAction = isLongPressForWeb ? 0 : 1;
  } else if (Platform.OS === 'android') {
    key = result.eventType;
  } else {
    key = result;
  }

  /**
   * In the RTL, remote control keys change
   * Should be change Right key = Left, Left key = right
   */
  if (SpatialNavigation.directionRTL) {
    switch (key) {
      case 'right':
        key = 'left';
        break;
      case 'left':
        key = 'right';
        break;
      case 'longRight':
        key = 'longLeft';
        break;
      case 'longLeft':
        key = 'longRight';
        break;
      case 39:
        key = 37;
        break;
      case 37:
        key = 39;
        break;
      case 390:
        key = 370;
        break;
      case 370:
        key = 390;
        break;
    }
  }

  const abstractKey = NavigationKeys[key];

  handleKeyNavigation(abstractKey, result?.eventKeyAction);
};

/**
 * check input has char and is cursor 0
 *
 * @param {object} param -focusKey
 * @param {string} param.nextFocus -focusKey
 * @param {number} param.inputLength -inputLength
 * @param {string} param.currentFocus -currentFocus
 * @param {number} param.cursorPosition -cursorPosition
 * @returns {string} -string
 */
export const handleLeftFocusAction = ({
  nextFocus,
  inputLength,
  currentFocus,
  cursorPosition,
}) => {
  if (inputLength === 0 || cursorPosition === 0) return nextFocus;
  else return currentFocus;
};

/**
 *
 * @param {string} nextFocusKey -
 * @param {Function} nextAction -
 */
export const onArrowPressHandler = (nextFocusKey, nextAction) => {
  if (nextFocusKey) {
    if (nextAction) {
      nextAction();
    }

    setFocusCustom(nextFocusKey);
  }
};

/**
 * Set Direction RTL for spatial navigation
 *
 * @param {boolean} direction - direction value
 */
export const setDirectionRTL = (direction) => {
  setTimeout(() => {
    SpatialNavigation.setDirectionRTL(direction);

    updateLayoutsCustom();
  }, 10);
};

// Last focus key variable of memorized
let lastFocusKeyData = null;

/**
 * Last Focus Key Custom Memorize
 *
 * @returns {object} - get and set method
 */
export const lastFocusKey = {
  set: (data) => {
    lastFocusKeyData = data;
  },
  get: () => lastFocusKeyData,
};

/**
 *
 * @param {string} abstractKey - abstractKey
 * @param {number} eventKeyAction - eventKeyAction from Routes.android for long press decision. 1 equal to keydown
 * @returns {void} -
 */
const handleKeyNavigation = (abstractKey, eventKeyAction = 1) => {
  /**
   * handleNoFocusableObjectsWarning
   */
  const handleNoFocusableObjectsWarning = () => {
    console.info(
      'WARNING: There is no focusable object with this key',
      SpatialNavigation.focusKey,
      Object.keys(SpatialNavigation.focusableComponents)
    );
  };

  /**
   *
   * @param {string} nextFocusKey - nextFocusKey
   * @returns {void} -
   */
  const handleStringFocusKey = (nextFocusKey) => {
    if (getFocusableObjectByKey(nextFocusKey)) {
      setFocusCustom(nextFocusKey);
    } else {
      console.info(
        `WARNING: There is no focusable object with this key -> ${nextFocusKey}`,
        Object.keys(SpatialNavigation.focusableComponents)
      );
      handleArrowPress(abstractKey);
    }
  };

  /**
   * handleArrowPress
   */
  const handleArrowPress = () => {
    if (triggerArrowPress(abstractKey)) {
      // for dpad navigation
      navigateAndUpdateLayouts(abstractKey);
    }
  };

  /**
   *
   * @param {string} absoluteKey - modified abstractKey for longPressEvents
   * @param {Function} nextFocusKeyAction - nextFocusKeyAction for if [right, left, up, down]FocusKey is a function
   * @returns {void} -
   */
  const handleLongPress = (absoluteKey, nextFocusKeyAction) => {
    longPressQueue.push(absoluteKey);

    const currentFocusKey = getCurrentFocusKey();
    const currentFocusableObject = nextFocusKeyAction
      ? SpatialNavigation.focusableComponents[nextFocusKeyAction()]
      : SpatialNavigation.focusableComponents[currentFocusKey];

    if (currentFocusableObject) {
      if (Platform.OS === 'ios') {
        findNextFocusables(currentFocusKey, absoluteKey, nextFocusKeyAction);
        setFocusCustom(getCurrentFocusKey());
      } else {
        if (eventKeyAction === 0) {
          const nextFocusKey = nextFocusKeyAction
            ? nextFocusKeyAction()
            : currentFocusableObject[absoluteKey + 'FocusKey'];

          setFocusCustom(nextFocusKey);
        } else {
          longPressQueue.splice(0);
          setFocusCustom(getCurrentFocusKey());
        }
      }
    }
  };

  /**
   * Handle Long Select
   *
   * @param {string} absoluteKey - absolute key
   * @param {Function} nextFocusKey - next focus key
   */
  const handleLongSelect = (absoluteKey, nextFocusKey) => {
    if (longPressQueue.length === 0) {
      longPressQueue.push(absoluteKey);
      nextFocusKey();
    } else if (eventKeyAction === 1) {
      longPressQueue.splice(0);
    }
  };

  /**
   *
   * @param {string} currentKey - getCurrentFocusKey(
   * @param {string} absoluteKey - modified abstractKey for longPressEvents
   * @param {Function} nextFocusKeyAction - nextFocusKeyAction for if [right, left, up, down]FocusKey is a function
   * @returns {Promise} - recursive method with Promise for longPressEvents
   */
  const findNextFocusables = (
    currentKey,
    absoluteKey,
    nextFocusKeyAction = false
  ) => {
    if (typeof currentKey === 'function') {
      currentKey = currentKey();
    }

    const currentFocusableObject =
      SpatialNavigation.focusableComponents[currentKey];

    if (!currentFocusableObject) {
      longPressQueue.splice(0);

      return null;
    }

    if (longPressQueue.length !== 1) {
      longPressQueue.splice(0);

      return null;
    }

    if (nextFocusKeyAction) nextFocusKeyAction();
    setFocusCustom(currentKey);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          findNextFocusables(
            currentFocusableObject[absoluteKey + 'FocusKey'],
            absoluteKey,
            nextFocusKeyAction
          )
        );
      }, 250);
    });
  };

  // eslint-disable-next-line require-jsdoc
  const seekHandleForTvos = (seek) => {
    if (longPressQueue.length !== 1) {
      longPressQueue.splice(0);

      return null;
    }

    seek();

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(seekHandleForTvos(seek));
      }, 100);
    });
  };

  /**
   * Handle Long Seek
   *
   * @param {string} absoluteKey -
   * @param {Function} seek -
   */
  const handleLongSeek = (absoluteKey, seek) => {
    if (Platform.OS === 'ios') {
      longPressQueue.push(absoluteKey);
      seekHandleForTvos(seek);
    } else {
      if (eventKeyAction === 0) {
        longPressQueue.push(absoluteKey);
        seek();
      } else longPressQueue.splice(0);
    }
  };

  if (abstractKey === AbstractKeys.ENTER_PRESS && eventKeyAction === 1) {
    triggerOnEnterPressHandler();
  } else {
    const currentFocusableObject = getCurrentFocusableObject();

    if (!currentFocusableObject) {
      handleNoFocusableObjectsWarning();

      return;
    }

    const isLongPress = abstractKey?.startsWith('long');
    const isLongSelectPress = abstractKey === 'longSelect';
    const absoluteKey = isLongPress
      ? abstractKey.slice(4).toLowerCase()
      : abstractKey;

    const identifier = isLongSelectPress
      ? abstractKey + 'FocusKey'
      : absoluteKey + 'FocusKey';
    const nextFocusKey = currentFocusableObject[identifier];

    if (isLongPress && typeof nextFocusKey === 'string') {
      handleLongPress(absoluteKey);
    } else if (isLongPress && typeof nextFocusKey === 'function') {
      handleLongPress(absoluteKey, nextFocusKey);
    } else if (isLongSelectPress && typeof nextFocusKey === 'function') {
      handleLongSelect(absoluteKey, nextFocusKey);
    } else if (isLongPress && typeof nextFocusKey === 'object') {
      // INFO: Developed for seek of progress bar
      // INFO: Next focus should be a object is containing seek function because of seperation from normal long press
      handleLongSeek(absoluteKey, nextFocusKey.action);
    } else if (typeof nextFocusKey === 'string' && eventKeyAction === 1) {
      handleStringFocusKey(nextFocusKey);
    } else if (typeof nextFocusKey === 'function' && eventKeyAction === 1) {
      const result = nextFocusKey();

      setFocusCustom(result);
    }
  }
};

/**
 * Filter Focusable Components
 * Developed for When rail item is empty
 * Filter and sort SpatialNavigation.focusableComponents object for missing focus keys
 * So focus can jump to the next focusable and available component
 *
 * @param {string} prefix - focusKey prefix
 * @param {number} currentIndex - currentIndex is number of current rail generated by flatlist
 * @param {number} directionalValue - directionalValue -1 is up 1 is down
 * @returns {string} - string
 */
export const filterFocusableComponents = (
  prefix,
  currentIndex,
  directionalValue
) => {
  const focusableComponents = Object.keys(
    SpatialNavigation.focusableComponents
  );

  const filteredFocusableComponents = focusableComponents.filter((key) =>
    key.startsWith(prefix)
  );

  const slicedFocusableComponents = filteredFocusableComponents.map((i) =>
    i.split('-').slice(0, 3).join('-')
  );

  const distinctFocusableComponents = [...new Set(slicedFocusableComponents)];

  const sortedArray = distinctFocusableComponents.sort((a, b) => {
    const numA = parseInt(a.split('-').pop(), 10);
    const numB = parseInt(b.split('-').pop(), 10);

    return numA - numB;
  });

  const filteredIndex = sortedArray.findIndex(
    (i) => i === `${prefix}-${currentIndex}`
  );

  const index = filteredIndex === -1 ? currentIndex : filteredIndex;

  return sortedArray[index + directionalValue] ?? sortedArray[index];
};
