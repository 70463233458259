import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: GetScaledValue(880),
    height: GetScaledValue(146),
    padding: GetScaledValue(20),
    borderRadius: GetScaledValue(12),
    gap: GetScaledValue(10),
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: GetScaledValue(12),
  },
});

export default styles;
