import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import styles from './styles';
import ProgressBar from '../../molecules/ProgressBar';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES, VIDEO_MODES } from '../../../helpers/Enums';
import convertTimeFormat from '../../../libs/convertTimeFormat';
import colors from '../../../helpers/Colors';
import { translate } from '@digiturk/i18n';
import { Languages } from './language';

import timeKeeper from '../../../libs/timeKeeper';
import { withFocusable } from '@digiturk/react-spatial-navigation';
// import { BasicButton } from '../buttons';

/**
 * Player ProgressBar with TimeLine
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {boolean} props.focused - focused
 * @param {object} props.refPlayer - refPlayer
 * @param {boolean} props.isPlayerReady - isPlayerReady
 * @returns {module:JSX.Element} - JSX.Element
 */
const PlayerProgressBar = ({
  videoType,
  totalTime,
  currentTime,
  focused,
  refPlayer,
  isPlayerReady,
}) => {
  const offsetForStartFeed = -totalTime;

  const [nowTime, setNowTime] = useState(timeKeeper().hourAndMin);
  const [startingTime, setStartingTime] = useState(
    timeKeeper(null, offsetForStartFeed).hourAndMin
  );

  const oneSecondTimeInterval = useRef(null);

  useEffect(() => {
    oneSecondTimeInterval.current = setInterval(() => {
      setNowTime(timeKeeper().hourAndMin);
      setStartingTime(timeKeeper(null, offsetForStartFeed).hourAndMin);
    }, 1000);

    return () => {
      clearInterval(oneSecondTimeInterval.current);
    };
  }, [offsetForStartFeed]);

  /**
   * Computes the progress and returns
   *
   * @returns {number} - progress
   */
  const progressCompute = () => {
    if (totalTime === 0) {
      return 0;
    }

    const progress = (currentTime / totalTime) * 100;
    const defaultProgress = videoType === VIDEO_MODES.LIVE ? 100 : 0;

    return isNaN(progress) ? defaultProgress : progress;
  };

  /**
   * Computes the buffer progress and returns
   *
   * @returns {any} - buffer progress
   */
  const bufferProgressCompute = () => {
    if (!refPlayer.current) {
      return;
    }

    const bufferProgress = Math.round(
      (getVideoBufferLevel() / totalTime) * 100
    );

    return isNaN(bufferProgress) ? 0 : bufferProgress;
  };

  /**
   *  Returns video buffer level
   *
   * @returns {number|any} - video buffer level
   */
  const getVideoBufferLevel = function () {
    return Math.round(
      refPlayer.current.buffer.getLevel('forwardduration', 'video').level
    );
  };

  /**
   * counter text handler
   *
   * @returns {string|null} - counterText
   */
  const counterTextHandler = () => {
    return convertTimeFormat(currentTime) + '\n';
  };

  return (
    <View style={styles.mainContainer}>
      {isPlayerReady && (
        <Text
          text={
            videoType === VIDEO_MODES.LIVE
              ? startingTime
              : convertTimeFormat(currentTime)
          }
          fontFamily={FONT_FAMILY.REGULAR}
          size={FONT_SIZES.HEADING4}
          color={colors.neutral400}
        />
      )}
      <View style={styles.progressBar}>
        <ProgressBar
          focused={focused}
          point={isPlayerReady}
          progress={progressCompute()}
          bufferProgress={bufferProgressCompute()}
          color={colors.primary500}
          backgroundColor={colors.gray200}
          height={8}
          borderRadius={20}
          counterText={counterTextHandler()}
        />
      </View>

      {videoType === VIDEO_MODES.LIVE && (
        <View style={styles.liveTextWrapper}>
          <View style={styles.redDot} />
          <Text
            text={translate(Languages.LIVE)}
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING4}
            color={colors.neutral400}
          />
        </View>
      )}
      {!!totalTime && (
        <Text
          text={
            videoType === VIDEO_MODES.LIVE
              ? nowTime
              : convertTimeFormat(totalTime - currentTime)
          }
          fontFamily={FONT_FAMILY.REGULAR}
          size={FONT_SIZES.HEADING4}
          color={colors.neutral400}
        />
      )}
    </View>
  );
};

export default withFocusable()(PlayerProgressBar);
