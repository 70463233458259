/**
 * Locales
 */
const LOCALES = {
  ENGLISH: { id: 1, name: 'en', label: 'ENGLISH' },
  TURKISH: { id: 2, name: 'tr', label: 'TÜRKÇE' },
  ARABIC: { id: 3, name: 'ar', label: 'العربية' },
};

export default LOCALES;
