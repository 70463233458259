import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: GetScaledValue(80),
  },
  contentContainerStyle: {
    gap: GetScaledValue(20),
  },
  itemContainer: {
    height: GetScaledValue(500),
  },
  iconContainer: {
    marginTop: GetScaledValue(80),
    marginBottom: GetScaledValue(116),
  },
  skeletonContainer: {
    flexDirection: 'row',
    marginTop: GetScaledValue(100),
    gap: GetScaledValue(20),
  },
  skeleton: {
    width: GetScaledValue(200),
    height: GetScaledValue(200),
  },
});

export default styles;
