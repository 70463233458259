import AbstractKeys from './abstractKeys';

//INFO: Long key codes are temporary. The codes are calculated by multiplied 10 to the original key code.
const NavigationKeys = {
  39: AbstractKeys.RIGHT,
  390: AbstractKeys.LONG_RIGHT,
  37: AbstractKeys.LEFT,
  370: AbstractKeys.LONG_LEFT,
  38: AbstractKeys.UP,
  380: AbstractKeys.LONG_UP,
  40: AbstractKeys.DOWN,
  400: AbstractKeys.LONG_DOWN,
  13: AbstractKeys.ENTER_PRESS,
};

export default NavigationKeys;
