/**
 * Language
 */
const Language = {
  EDIT_PROFILE_NAME: 'EDIT_PROFILE_NAME',
  EDIT_PROFILE_NAME_TITLE: 'EDIT_PROFILE_NAME_TITLE',
  EDIT_PROFILE_AVATAR: 'EDIT_PROFILE_AVATAR',
  EDIT_PROFILE_PROFILE_TYPES: 'EDIT_PROFILE_PROFILE_TYPES',
  EDIT_PROFILE_NO_SPOILER: 'EDIT_PROFILE_NO_SPOILER',
  EDIT_PROFILE: 'EDIT_PROFILE',
  EDIT_PROFILE_DESCRIPTION: 'EDIT_PROFILE_DESCRIPTION',
  EDIT_PROFILE_PIN_CODE: 'EDIT_PROFILE_PIN_CODE',
  EDIT_PROFILE_DELETE_PROFILE: 'EDIT_PROFILE_DELETE_PROFILE',
  ADD_NEW_PROFILE_NAME: 'ADD_NEW_PROFILE_NAME',
  ADD_NEW_PROFILE_AVATAR: 'ADD_NEW_PROFILE_AVATAR',
  ADD_NEW_PROFILE_PROFILE_TYPES: 'ADD_NEW_PROFILE_PROFILE_TYPES',
  ADD_NEW_PROFILE: 'ADD_NEW_PROFILE',
  ADD_NEW_PROFILE_DESCRIPTION: 'ADD_NEW_PROFILE_DESCRIPTION',
  ADD_NEW_PROFILE_ADD_BUTTON: 'ADD_NEW_PROFILE_ADD_BUTTON',
  NO_SPOILER: 'NO_SPOILER',
  ON: 'ON',
  OFF: 'OFF',
};

export default Language;
