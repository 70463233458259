import { StyleSheet, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../../helpers/Colors';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Overlay Component
 *
 * @param {object} props - props
 * @param {boolean} props.userActivity - userActivity
 * @returns {module:JSX.Element} - JSX.Element
 */
const Overlay = ({ userActivity }) => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setOpacity(userActivity ? 1 : 0);
  }, [userActivity]);

  return (
    <View style={styles.overlayContainer}>
      <LinearGradient
        colors={[
          colors.blackTransparent07,
          colors.blackTransparent40,
          colors.blackTransparent01,
          colors.transparent,
        ]}
        style={[styles.container, { opacity }]}
      />
      <LinearGradient
        colors={[
          colors.transparent,
          colors.blackTransparent40,
          colors.blackTransparent64,
          colors.blackTransparent92,
          colors.shades100,
        ]}
        style={[styles.container, { opacity }]}
      />
    </View>
  );
};

export default Overlay;

const styles = StyleSheet.create({
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    height: GetScaledValue(1080),
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
  },
});
