/* eslint-disable react-native/no-inline-styles */
import React, { memo, lazy, Suspense, useMemo } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { railIsCollection } from '../../../helpers/CommonHelper';

// Heros
const HeroBasic = lazy(() => import('../../molecules/Heros/Basic'));
const HeroWithDetail = lazy(() =>
  import('../../molecules/Heros/HeroWithDetail')
);
const HeroWithTrailer = lazy(() => import('../Heros/HeroWithTrailer'));
const HeroWithFootball = lazy(() => import('../Heros/HeroWithFootball'));
const HeroCollection = lazy(() => import('../Heros/HeroWithCollections'));

const listHero = {
  default: HeroBasic,
  Slider: HeroWithTrailer,
  Showcase: HeroBasic,
  NewOnTod: HeroWithTrailer,
  Competitions: HeroBasic,
  ContinueWatching: HeroWithDetail,
  BoxSets: HeroWithDetail,
  TrendingOnTod: HeroWithDetail,
  Sports: HeroBasic,
  TopTenMoviesByRegionForToday: HeroWithDetail,
  TopTenMoviesByCountryForToday: HeroWithDetail,
  TopTenMoviesByGenreWithRegion: HeroWithDetail,
  WhatsOnNow: HeroBasic,
  MovieCollection: HeroCollection,
  MovieStarCollection: HeroCollection,
  verticalcollection: HeroCollection,
  SuperHeroes: HeroWithDetail,
  Tournaments: HeroWithFootball,
  //will be updated after api integration
  DontMissOut: HeroWithFootball,
  LiveAndUpcoming: HeroWithFootball,
  tod360: HeroWithFootball,
  Football: HeroWithFootball,
  SerieCollection: HeroBasic,
  CompetitionCollection: HeroBasic,
};

/**
 * Hero
 *
 * @param {object} props - props
 * @param {object} props.globalFocusedItem - global focused item
 * @returns {module:JSX.Element} -
 */
const Hero = ({ globalFocusedItem }) => {
  const heroHeight = GetScaledValue(globalFocusedItem?.heroHeight || 630);
  const railType = globalFocusedItem?.railType || null;

  const SelectedComponent = useMemo(
    () =>
      railType && listHero[railType] ? listHero[railType] : listHero.default,
    [railType]
  );

  if (!globalFocusedItem.railType) return null;
  if (railIsCollection(globalFocusedItem.railType)) return null;

  return (
    <View style={{ height: heroHeight, zIndex: 97 }}>
      <Suspense>
        <SelectedComponent data={globalFocusedItem} />
      </Suspense>
    </View>
  );
};

export default memo(Hero);
