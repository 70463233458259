import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import useLocalization from '../../../libs/localization/useLocalization';
import AccountDetails from './AccountDetails';
import Subscription from './Subscription';
import Devices from './Devices';
import QRCode from './QRCode';
import IconWithText from '../../molecules/IconWithText';
import { ArrowLeftBold, ArrowRightBold } from '../../../assets/icons';
import colors from '../../../helpers/Colors';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import Text from '../../atoms/Text';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';

/**
 * Settings Page
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Settings = () => {
  const { isRTL, languageDirection } = useLocalization();
  const { navigate } = useCustomNavigation();

  useEffect(() => {
    setFocusCustom('SettingsButton-0');
  }, []);

  useHardwareBackPress(() => {
    //INFO: Home needs url params but this one should be goBack()
    navigate(NavigationRoutes.home);
  }, true);

  const buttons = [
    {
      text: 'Renew Subscription',
      size: buttonSize.big,
      theme: buttonThemes.neutral800,
      themeFocused: buttonThemes.primary,
    },
    {
      text: 'Sign Out',
      size: buttonSize.big,
      theme: buttonThemes.neutral800,
      themeFocused: buttonThemes.primary,
      onPress: () => {
        navigate(NavigationRoutes.signOut);
      },
    },
  ];

  return (
    <View dir={languageDirection} style={styles.container}>
      <IconWithText
        containerStyle={styles.backButton}
        icon={isRTL ? ArrowRightBold : ArrowLeftBold}
        iconStyle={styles.iconStyle}
        text={'Back'}
        textStyle={styles.textStyle}
      />
      <View style={styles.topRow}>
        <AccountDetails />
        <Subscription />
        <QRCode />
      </View>
      <View style={styles.bottomRow}>
        <Devices />
      </View>
      <View style={styles.version}>
        <Text
          text={'All rights reserved © TOD 2024'}
          size={FONT_SIZES.HEADING5}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
        <Text
          text={'v2.0.2.4'}
          size={FONT_SIZES.HEADING5}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
      </View>
      <View style={styles.buttonArea}>
        {buttons.map((button, index) => (
          <BasicButton
            key={index}
            text={button.text}
            size={button.size}
            theme={button.theme}
            themeFocused={button.themeFocused}
            style={styles.buttonItem}
            focusKey={`SettingsButton-${index}`}
            leftFocusKey={index > 0 ? `SettingsButton-${index - 1}` : undefined}
            rightFocusKey={
              index < buttons.length - 1
                ? `SettingsButton-${index + 1}`
                : undefined
            }
            onEnterPress={button.onPress}
          />
        ))}
      </View>
    </View>
  );
};

export default Settings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    padding: GetScaledValue(80),
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: GetScaledValue(398),
    marginTop: GetScaledValue(60),
  },
  bottomRow: {
    marginTop: GetScaledValue(20),
  },
  version: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: GetScaledValue(20),
  },
  iconStyle: {
    width: 40,
    height: 33,
    color: colors.shades00,
  },
  textStyle: {
    fontFamily: FONT_FAMILY.REGULAR,
    size: FONT_SIZES.HEADING3,
  },
  backButton: {
    flexDirection: 'row',
    gap: GetScaledValue(20),
    alignItems: 'center',
  },
  buttonArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: GetScaledValue(40),
    gap: GetScaledValue(20),
    justifyContent: 'center',
  },
  buttonItem: {
    width: GetScaledValue(400),
    justifyContent: 'center',
  },
});
