/* eslint-disable require-jsdoc */
import React from 'react';
import Text from '../../atoms/Text';

/**
 * Subtitle (Molecule)
 *
 * @returns {module:JSX.Element} - JSX.Element
 */

const Subtitle = ({
  text,
  background,
  fontSize,
  color,
  styles,
  fontFamily,
}) => {
  return (
    <Text
      text={text}
      size={fontSize}
      fontFamily={fontFamily}
      style={(styles, { background: background })}
      color={color}
    />
  );
};

export default React.memo(Subtitle);
