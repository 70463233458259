import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

/**
 * InfoBubble styles
 *
 * @param {boolean} focused - focused
 * @returns {object} - styles
 */
const styles = (focused) => {
  return StyleSheet.create({
    container: {
      backgroundColor: '#000',
      border: `5px solid ${
        focused ? colors.primary : colors.whiteTransparent16
      }`,
      borderRadius: GetScaledValue(20),
      padding: GetScaledValue(40),
      marginTop: GetScaledValue(20),
    },
    header: {
      fontSize: 24,
      color: colors.shades100,
      textAlign: 'center',
      marginBottom: GetScaledValue(20),
    },
    teams: {
      padding: GetScaledValue(10),
    },
    teamContainer: {
      padding: GetScaledValue(20),
      margin: GetScaledValue(10),
      display: 'flex',
      flexDirection: 'row',
    },
    teamHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: GetScaledValue(10),
    },
    teamLogo: {
      width: GetScaledValue(40),
      height: GetScaledValue(40),
      marginRight: GetScaledValue(10),
    },
    playerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: GetScaledValue(5),
    },
    miniHeader: {
      color: colors.neutral200,
    },
    playerArea: {
      marginTop: GetScaledValue(20),
    },
  });
};

export default styles;
