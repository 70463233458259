import React, { useCallback } from 'react';
import { Player, PlayerEvent } from 'bitmovin-player';
import { PlayerPropTypes, PlayerDefaultProps } from './proptypes';
import { useFocusEffect } from '@react-navigation/native';

/**
 * Player component
 *
 * @param {object} props -props
 * @param {Function} props.onLoad - on load callback
 * @param {object} props.onCurrentTimeUpdate - on current time update callback - currentTime
 * @param {object} props.onBufferHandler - onBufferHandler
 * @param {object} props.source - player source
 * @returns {module:JSX.Element} - player html
 */
const VodPlayer = ({
  onLoad,
  onCurrentTimeUpdate,
  onBufferHandler,
  source,
}) => {
  const playerConfig = {
    key: '047DDDE8-7D3F-4355-959A-4DC51EC5B10E',
    ui: false,
  };

  const playerSource = {
    dash: source.dash,
    hls: source.hls,
    progressive:
      'https://bitdash-a.akamaihd.net/content/MI201109210084_1/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4',
    poster:
      'https://bitdash-a.akamaihd.net/content/MI201109210084_1/poster.jpg',
    smooth: `${source.dash}`,
  };

  /**
   * İnitialize Player
   */
  const initializePlayer = useCallback(() => {
    const player = new Player(document.getElementById('player'), playerConfig);

    player.load(playerSource).then(() => {
      player.on(PlayerEvent.TimeChanged, (event) => {
        onCurrentTimeUpdate && onCurrentTimeUpdate(event);
        onBufferHandler && onBufferHandler(player.getVideoBufferLength());
      });

      player.on(PlayerEvent.SourceUnloaded, (error) => {
        console.info('Source Error', error);
      });

      player.on(PlayerEvent.Destroy, (error) => {
        console.info('Destroyed Vod', error);
      });

      player.on(PlayerEvent.Ready, (event) => {
        onLoad && onLoad(player);
      });
    });

    return () => {
      player?.destroy();
    };
  }, [playerConfig, playerSource]);

  useFocusEffect(initializePlayer);

  return <div id={'player'} />;
};

VodPlayer.propTypes = PlayerPropTypes;
VodPlayer.defaultProps = PlayerDefaultProps;
VodPlayer.whyDidYouRender = true;

export default VodPlayer;
