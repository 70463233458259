import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import axios from 'axios';
import api from '../../../middleware/services/api';

/**
 * QRCode Settings Component
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const QRCode = () => {
  const [loginAuth, setLoginAuth] = useState('');

  useEffect(() => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://user.tod2-test.beiniz.biz/api/v1/auth/generate-auth-code',
      headers: api.defaults.headers,
    };

    axios
      .request(config)
      .then((response) => {
        setLoginAuth(response.data.data);
      })
      .catch((error) => {
        console.info(error);
      });
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.qrCode}>
        <Image source={{ uri: loginAuth?.imagePath }} style={styles.qrImage} />
      </View>
      <Text
        text={'Scan the QR code to edit your subscription & account settings'}
        size={FONT_SIZES.HEADING4}
        fontFamily={FONT_FAMILY.REGULAR}
        color={colors.neutral300}
        multiline
        numberOfLines={4}
        style={styles.text}
      />
    </View>
  );
};

export default QRCode;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.neutral800,
    padding: GetScaledValue(60),
    width: GetScaledValue(441),
    height: GetScaledValue(644),
    borderRadius: GetScaledValue(20),
  },
  qrCode: {
    backgroundColor: colors.neutral500,
    width: GetScaledValue(320),
    height: GetScaledValue(320),
    borderRadius: GetScaledValue(27),
    marginBottom: GetScaledValue(40),
  },
  text: {
    textAlign: 'center',
  },
  qrImage: {
    width: GetScaledValue(320),
    height: GetScaledValue(320),
    alignSelf: 'center',
    borderRadius: GetScaledValue(27),
  },
});
