import React, { useEffect, useState } from 'react';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { View } from 'react-native';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { ArrowLeftBold } from '../../../assets/icons';
import colors from '../../../helpers/Colors';
import Language from './language';
import { translate } from '@digiturk/i18n';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import Subtitle from '../../molecules/Subtitle';

import styles from './styles';
/**
 * Hidden focus placeholder component
 *
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {Function} props.handleSubtitleTextStyle - handleSubtitleTextStyle
 * @param {Function} props.handleModal - handleModal
 * @returns {module:JSX.Element} - JSX.Element
 */

// eslint-disable-next-line require-jsdoc
const SubtitleSettingModal = ({
  subtitleSettingsCallback,
  handleSubtitleTextStyle,
  handleModal,
}) => {
  const [color, setColor] = useState('#fff');
  const [selectedBackground, setSelectedBackground] = useState(
    colors.transparent
  );
  const [fontSize, setFontSize] = useState(FONT_SIZES.DISPLAYLARGE);
  const [selectedFocus, setSelectedFocus] = useState('regular-size');

  useEffect(() => setFocusCustom(selectedFocus), [selectedFocus]);

  /**
   *
   * @param {string} cl - color
   * @param {string} bg - background
   */
  function changeSubtitleStyle(cl, bg) {
    setColor(cl);
    setSelectedBackground(bg);
  }

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <BasicButton
          icon={ArrowLeftBold}
          theme={buttonThemes.ghostWhite}
          text={'Back'}
          focusKey="close-modal"
          downFocusKey={'regular-size'}
          onEnterPress={() => {
            /*   subtitleSettingsCallback(); */
            handleModal(null);
            setFocusCustom('player-activity-button-0');
          }}
        />
      </View>
      <View style={styles.background} />

      <View style={styles.box}>
        <View style={styles.size}>
          <Text text="Size" size="DL" fontFamily={FONT_FAMILY.SEMIBOLD} />

          <BasicButton
            size={buttonSize.big}
            text={translate(Language.REGULAR)}
            theme={buttonThemes.ghostTransparent16}
            themeFocused={buttonThemes.primary}
            style={styles.buttonContainer}
            focusKey="regular-size"
            downFocusKey="small-size"
            rightFocusKey="default"
            onEnterPress={() => {
              setFontSize(FONT_SIZES.HEADING2);
              handleSubtitleTextStyle({
                fontSize: FONT_SIZES.HEADING2,
              });
              setSelectedFocus('regular-size');
            }}
          />

          <BasicButton
            size={buttonSize.big}
            text="Small"
            theme={buttonThemes.ghostTransparent16}
            themeFocused={buttonThemes.primary}
            style={styles.buttonContainer}
            focusKey="small-size"
            downFocusKey="big-size"
            upFocusKey="regular-size"
            rightFocusKey="default"
            onEnterPress={() => {
              setFontSize(FONT_SIZES.HEADING5);
              handleSubtitleTextStyle({
                fontSize: FONT_SIZES.HEADING5,
              });
              setSelectedFocus('small-size');
            }}
          />

          <BasicButton
            size={buttonSize.big}
            text="Big"
            theme={buttonThemes.ghostTransparent16}
            themeFocused={buttonThemes.primary}
            style={styles.buttonContainer}
            focusKey="big-size"
            upFocusKey="small-size"
            rightFocusKey="default"
            leftFocusKey={false}
            onEnterPress={() => {
              setFontSize(FONT_SIZES.DISPLAYLARGE);
              handleSubtitleTextStyle({
                fontSize: FONT_SIZES.DISPLAYLARGE,
              });
              setSelectedFocus('big-size');
            }}
          />
        </View>
        <View style={styles.color}>
          <Text text="Color" size="DL" fontFamily={FONT_FAMILY.SEMIBOLD} />
          <BasicButton
            size={buttonSize.big}
            text="Aa"
            focusKey="default"
            downFocusKey="secondary"
            leftFocusKey={selectedFocus}
            theme={buttonThemes.ghostTransparent16}
            themeFocused={buttonThemes.ghostTransparent24}
            style={styles.buttonContainer}
            onEnterPress={() => {
              handleSubtitleTextStyle({
                color: colors.text,
                backgroundColor: colors.transparent,
              });
              changeSubtitleStyle(colors.text, colors.transparent);
            }}
          />

          <BasicButton
            size={buttonSize.big}
            text="Aa"
            focusKey="secondary"
            downFocusKey="tertiary"
            upFocusKey="default"
            leftFocusKey={selectedFocus}
            theme={buttonThemes.ghostTransparent16}
            themeFocused={buttonThemes.neutralSecondary}
            style={styles.buttonContainer}
            textAreaStyle={styles.blackWhite}
            onEnterPress={() => {
              handleSubtitleTextStyle({
                color: colors.text,
                backgroundColor: colors.background,
              });
              changeSubtitleStyle(colors.text, colors.background);
            }}
          />

          <BasicButton
            size={buttonSize.big}
            text="Aa"
            focusKey="tertiary"
            downFocusKey="quaternary"
            upFocusKey="secondary"
            leftFocusKey={selectedFocus}
            theme={buttonThemes.ghostPrimaryTransparent16}
            themeFocused={buttonThemes.neutralTertiary}
            style={styles.buttonContainer}
            textAreaStyle={styles.blackWhite}
            onEnterPress={() => {
              handleSubtitleTextStyle({
                color: colors.primary,
                backgroundColor: colors.background,
              });
            }}
          />

          <BasicButton
            size={buttonSize.big}
            text="Aa"
            focusKey="quaternary"
            upFocusKey="tertiary"
            leftFocusKey={selectedFocus}
            theme={buttonThemes.ghostShadesTransparent16}
            themeFocused={buttonThemes.neutralPrimary}
            style={styles.buttonContainer}
            textAreaStyle={styles.whiteBlack}
            onEnterPress={() =>
              changeSubtitleStyle(colors.background, colors.text)
            }
          />
        </View>
      </View>
      <View style={styles.subTitle}>
        <Subtitle
          text="This is what your subtitles will look like"
          fontSize={fontSize}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          style={styles.wbSubtitle}
          color={color}
          background={selectedBackground}
        />
      </View>
    </View>
  );
};

export default withFocusable()(SubtitleSettingModal);
