import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppRegistry, Platform } from 'react-native';

import Root from 'shared/src/setup';

import { loadFontsForWeb } from '../../../util/webFontLoader';

loadFontsForWeb();

// AppRegistry.registerComponent('beONETV', () => Root);
// AppRegistry.runApplication('beONETV', {
//   rootTag: document.getElementById('root'),
// });

AppRegistry.registerComponent('beONETV', () => Root);
if (Platform.OS === 'web') {
  const rootTag = createRoot(document.getElementById('root'));

  const RootComponent = Root;

  rootTag.render(<RootComponent />);
}
