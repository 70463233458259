import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { BasicButton, buttonThemes } from '../../molecules/buttons';
import { styles } from './styles';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { NumpadData } from './data';
import Item from './item';
import useLocalization from '../../../libs/localization/useLocalization';
import { setFocusCustom } from '../../../helpers/FocusHelper';

/**
 * Numpad component.
 *
 * @param {object} props - Component props.
 * @param {string} props.value - Value.
 * @param {number} props.limit - limit.
 * @param {Function} props.setValue - Set value function.
 * @param {object} props.containerStyle - Container style.
 * @param {string} props.prefix - Prefix.
 * @param {Function} props.onDonePress - On done press callback.
 * @param {string} props.rightEdgeFocusKey - Right edge focus key.
 * @param {string} props.leftEdgeFocusKey - Left edge focus key.
 * @param {string} props.downEdgeFocusKey - Down edge focus key.
 * @param {string} props.upEdgeFocusKey - Up edge focus key.
 * @returns {module:JSX.Element} Keyboard component.
 */
const Numpad = ({
  value,
  prefix,
  limit,
  setValue,
  onDonePress,
  containerStyle,
  rightEdgeFocusKey,
  leftEdgeFocusKey,
  downEdgeFocusKey = onDonePress ? 'doneButton' : false,
  upEdgeFocusKey,
}) => {
  const { isRTL } = useLocalization();

  const keys = isRTL ? NumpadData.keysRTL : NumpadData.keys;
  /**
   * Keyboard OnEnterPress method
   *
   * @param {string} key - key
   */
  const onEnterPress = useCallback(
    (key) => {
      switch (key.label) {
        case 'trash':
          setValue((prevState) => '');
          break;
        case 'backspace':
          setValue(value.substring(0, value?.length - 1));
          break;
        default:
          value.length < limit && setValue((prevState) => prevState + key.text);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, limit]
  );

  useEffect(() => {
    if (value.length === limit && onDonePress) {
      setFocusCustom('doneButton');
    }
  }, [value, limit, onDonePress]);

  return (
    <View style={containerStyle}>
      <View style={styles.container}>
        {keys.map((item, index) => (
          <Item
            item={item}
            index={index}
            key={index}
            prefix={prefix}
            onEnterPress={onEnterPress}
            onDonePress={onDonePress}
            downEdgeFocusKey={downEdgeFocusKey}
            upEdgeFocusKey={upEdgeFocusKey}
            rightEdgeFocusKey={rightEdgeFocusKey}
            leftEdgeFocusKey={leftEdgeFocusKey}
          />
        ))}
      </View>
      {onDonePress && (
        <BasicButton
          style={styles.buttonStyle}
          theme={buttonThemes.neutral800}
          themeFocused={buttonThemes.primary}
          text={'Done'}
          focusKey="doneButton"
          upFocusKey={prefix}
          downFocusKey={prefix}
          onEnterPress={onDonePress}
        />
      )}
    </View>
  );
};

export default withFocusable()(Numpad);
