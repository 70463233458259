import PropTypes from 'prop-types';
import colors from '../../../helpers/Colors';

export default {
  progress: PropTypes.number,
  color: PropTypes.oneOf(Object.values(colors)),
  backgroundColor: PropTypes.string,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  counterText: PropTypes.string,
  point: PropTypes.bool,
  isRTL: PropTypes.bool,
};
