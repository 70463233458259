import React from 'react';
import './tools/wdyr';
import { LocaleContextProvider } from '@digiturk/i18n';
import setupNavigation from './navigation/setup';
import { LogBox } from 'react-native';
import { GlobalContextProvider } from './context';
import { AuthProvider } from './context/Auth';
LogBox.ignoreLogs(['Warning: ...']);
LogBox.ignoreAllLogs();
// eslint-disable-next-line no-console
console.disableYellowBox = true;

/**
 * This function is start point of project
 *
 * @param {module:App} App - App
 * @returns {module:JSX.Element} - JSX.Element
 */
function setupApp(App) {
  return () => (
    <LocaleContextProvider>
      <AuthProvider>
        <GlobalContextProvider>
          <App />
        </GlobalContextProvider>
      </AuthProvider>
    </LocaleContextProvider>
  );
}

/**
 * Setup Application
 */
const Root = setupApp(setupNavigation());

export default Root;
