import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';
import { SPACING_VALUES } from '../../../helpers/Enums';
import { borderWidth } from '../../../helpers/CommonHelper';

const styles = StyleSheet.create({
  loader: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  iconContainer: {
    position: 'absolute',
    bottom: GetScaledValue(-5),
    alignItems: 'center',
  },
  buttonContainer: {
    backgroundColor: colors.neutral500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    width: GetScaledValue(180),
    height: GetScaledValue(180),
    borderWidth,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: GetScaledValue(SPACING_VALUES.SBIG),
  },
  color: {
    borderRadius: GetScaledValue(SPACING_VALUES.SSM),
    width: GetScaledValue(180),
    height: GetScaledValue(180),
  },
});

export default styles;
