import React, { useEffect } from 'react';
import {
  DeviceEventEmitter,
  NativeModules,
  NativeEventEmitter,
  Platform,
} from 'react-native';

import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { initNavigation } from '@digiturk/react-spatial-navigation';

import AppNavigator from './AppNavigator';

import PropList from './proptypes';

import { pressedKeyEventHandler } from '../helpers/FocusHelper';

initNavigation({
  debug: false,
  visualDebug: false,
});

let KeyHandlerEventEmitter = null;

try {
  // eslint-disable-next-line no-unused-vars
  KeyHandlerEventEmitter = new NativeEventEmitter(
    NativeModules.KeyHandlerModule
  );
} catch (e) {}

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

/**
 * switch to other stack navigator when screen is a video one
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const Routes = () => {
  useEffect(() => {
    if (Platform.OS === 'windows') {
      // TODO: change DeviceEventEmitter to KeyHandlerEventEmitter
      DeviceEventEmitter.addListener(
        'PressedKeyCustom',
        pressedKeyEventHandler
      );
    } else if (Platform.OS === 'web') {
      // catch key and call pressed key event handler method
      window.addEventListener('keydown', (event) => {
        if (event.repeat === true) {
          pressedKeyEventHandler(event);
        } else window.addEventListener('keyup', pressedKeyEventHandler);
      });
    } else {
      // ???
    }

    return () => {
      if (Platform.OS === 'windows') {
        DeviceEventEmitter.removeListener(
          'PressedKeyCustom',
          pressedKeyEventHandler
        );
      } else if (Platform.OS === 'web') {
        // catch key and call pressed key event handler method
        window.addEventListener('keydown', (event) => {
          if (event.repeat === true) {
            pressedKeyEventHandler(event);
          } else window.addEventListener('keyup', pressedKeyEventHandler);
        });
      } else {
        // ???
      }
    };
  }, []);

  return (
    <NavigationContainer theme={navTheme}>
      <AppNavigator />
    </NavigationContainer>
  );
};

Routes.propTypes = PropList;

export default Routes;
