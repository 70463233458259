import React from 'react';
import PropList from './proptypes';
import './styles.css';

/**
 * Background Blur on Web
 *
 * @returns {React.JSX.Element} - JSX.Element
 */
const BackgroundBlur = () => {
  return <div className="backgroundBlur" />;
};

BackgroundBlur.propTypes = PropList;

export default BackgroundBlur;
