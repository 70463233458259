import { View } from 'react-native';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import styleFunction from './styles';
import PropList from './proptypes';
import Text from '../../../atoms/Text';
import Animated, {
  interpolate,
  runOnUI,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { isAnimationsDisabled } from '../../../../helpers/CommonHelper';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Animated Button
 *
 * @param {object} props - props
 * @param {string} props.text - text
 * @param {boolean} props.focused - focused
 * @param {any} props.themeCustom - color custom
 * @param {number} props.startTime - start time
 * @param {number} props.endTime - end time
 * @returns {module:JSX.Element} - JSX.Element
 */
const AnimatedButton = ({ text, focused, themeCustom, startTime, endTime }) => {
  const [btnWidth, setBtnWidth] = useState(0);
  const styles = styleFunction({ focused, themeCustom, btnWidth });
  const animatedWidth = useSharedValue(0);

  const calculateButtonWidth = useCallback((textWidth, pxToWord = 10) => {
    return textWidth?.length * pxToWord;
  }, []);

  useLayoutEffect(() => {
    setBtnWidth(calculateButtonWidth(text, GetScaledValue(22)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    runOnUI(() => {
      animatedWidth.value = 1;
    })();
  }, [animatedWidth.value]);

  const animatedStyle = useAnimatedStyle(() => {
    const _interpolate = interpolate(
      animatedWidth.value,
      [0, 1],
      [0, btnWidth]
    );

    const duration = endTime && startTime ? endTime - startTime : 5000;

    const _width = isAnimationsDisabled
      ? _interpolate
      : withTiming(_interpolate, { duration });

    return { width: _width };
  }, [animatedWidth, btnWidth]);

  return (
    <View style={styles.animatedContainer}>
      <Animated.View style={[styles.animatedInner, animatedStyle]} />
      <View style={styles.animatedButton} />
      <Text text={text} style={styles.text} />
    </View>
  );
};

AnimatedButton.whyDidYouRender = false;
AnimatedButton.propTypes = PropList;

export default withFocusable()(AnimatedButton);
