import React from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import Text from '../../../atoms/Text';
import Icon from '../../../atoms/Icon';

import styleFunction from './styles';
import PropList from './proptypes';

/**
 * Zippy Button With Icon Text
 *
 * @param {object} props - props
 * @param {string} props.size - size
 * @param {string} props.text - text
 * @param {boolean} props.focused - focused
 * @param {any} props.icon - icon
 * @param {string} props.theme - theme
 * @param {string} props.themeFocused - theme focus
 * @param {any} props.themeCustom - theme custom
 * @param {any} props.style - style
 * @param {any} props.unfocusedText - unfocusedText
 * @returns {module:JSX.Element} - JSX.Element
 */
const ZippyButton = ({
  size,
  text,
  focused,
  icon,
  theme,
  themeFocused,
  themeCustom,
  style,
  unfocusedText,
}) => {
  const styles = styleFunction(size, focused, theme, themeFocused, themeCustom);

  return (
    <View style={[styles.container, style && style]}>
      <View style={styles.iconArea}>
        <Icon icon={icon} {...styles.icon} />
      </View>
      {unfocusedText && !focused && (
        <View style={styles.textArea}>
          <Text text={unfocusedText} style={styles.text} />
        </View>
      )}
      {focused && text && (
        <View style={styles.textArea}>
          <Text text={text} style={styles.text} />
        </View>
      )}
    </View>
  );
};

ZippyButton.whyDidYouRender = false;
ZippyButton.propTypes = PropList;

export default withFocusable()(ZippyButton);
