import React, { useEffect } from 'react';
import { View } from 'react-native';
import Text from '../../atoms/Text';
import styleFunction from './styles';
import { FONT_SIZES } from '../../../helpers/Enums';
import Image from '../../atoms/Image';
import { withFocusable } from '@digiturk/react-spatial-navigation';

/**
 * HighlightBubble
 *
 * @param {object} props - props
 * @param {string} props.text - Text
 * @param {boolean} props.focused - focused
 * @param {any} props.icon - icon
 * @param {string | number} props.minute - minute
 * @param {string} props.theme - theme
 * @param {string} props.themeFocused - themeFocused
 * @param {Function} props.onFocus - onFocus
 * @returns {module:JSX.Element} - JSX.Element
 */
const HighlightBubble = ({
  text,
  focused,
  icon,
  minute,
  theme,
  themeFocused,
  onFocus,
}) => {
  const style = styleFunction(focused, theme, themeFocused);

  useEffect(() => {
    if (focused && onFocus) {
      onFocus();
    }
  }, [focused, onFocus]);

  return (
    <View style={style.statisticItem}>
      <View style={style.imageArea}>
        <Image
          style={style.imageArea}
          source="https://s3-alpha-sig.figma.com/img/fffb/8304/94025f499b62ed11934d2df11505f7ca?Expires=1721001600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iHw~ckrIzKu5vf17l8H~kVX1NCcA~XB4LDOpzU3Is40lCBPsnzJQHxGaIQ1SJEXdCP85wpDbBf1M7RcD7TDh4rrZu61egFEsBWTVqcqMuhBlnOZVl8kXvpLMy9F~H~7uWjQr-IPnxG48HtDboK-U-WDD6RPeEWwUQiRzr9ikHCBWH9lhv2y7RwCOM-xJDAwA2FrSmgKb2ZnQDmBVMvznv8-8fTlcUJORxgMm~88Wy-7ZEhFsdNyR0QXnUXmA58s8EP49fN22X8XCrMxarzEooWV14HbQVQYYtHaRtKD7MfqALOIAd~rhw1seKFFaIuZO6LGeYgTujYG3ZYoi6AsUeg__"
        />
      </View>
      <View>
        <Text
          style={style.text}
          text="Highlights Short"
          size={FONT_SIZES.HEADING3}
        />
        <Text style={style.text} text="1.40" size={FONT_SIZES.HEADING5} />
      </View>
    </View>
  );
};

export default withFocusable()(HighlightBubble);
