import React, { useCallback } from 'react';
import { FlatList, View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { PageRailListPropTypes } from './proptypes';
import RailItem from './railItem';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Page Horizontal Rail List
 *
 * @param {object} props - props
 * @param {string} props.prefix - prefix
 * @param {Array} props.content - rail list
 * @param {string} props.railListRef - railListRef
 * @param {string} props.leftFocusKey - leftFocusKey
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.setGlobalFocusedItem - setGlobalFocusedItem
 * @param {string} props.railFocusIndexSetter - railFocusIndexSetter
 * @param {Function} props.onScrollToIndexFailedHandler - onScrollToIndexFailedHandler
 * @param {number} props.baseRailListLength - baseRailListLength
 * @returns {module:JSX.Element} - JSX.Element
 */
const RailList = ({
  prefix,
  content,
  railListRef,
  leftFocusKey,
  onEnterPress,
  railFocusIndexSetter,
  setGlobalFocusedItem,
  onScrollToIndexFailedHandler,
  baseRailListLength,
}) => {
  const keyExtractor = useCallback(
    (_item, index) => `${prefix}_${index}`,
    [prefix]
  );

  const onBecameFocused = useCallback((_coordinate, selected) => {
    railFocusIndexSetter(selected?.index + 1);
    railListRef?.current?.scrollToIndex({
      index: selected.index,
      animated: true,
      viewPosition: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <RailItem
          item={item}
          index={index}
          prefix={prefix}
          leftFocusKey={leftFocusKey}
          onEnterPress={onEnterPress}
          onBecameFocused={onBecameFocused}
          setGlobalFocusedItem={setGlobalFocusedItem}
          baseRailListLength={baseRailListLength}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setGlobalFocusedItem, baseRailListLength, prefix]
  );

  return (
    <FlatList
      data={content}
      ref={railListRef}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      showsVerticalScrollIndicator={false}
      onScrollToIndexFailed={onScrollToIndexFailedHandler}
      removeClippedSubviews={false}
      scrollEnabled={false}
      windowSize={50}
      ListFooterComponent={() => (
        <View style={{ height: GetScaledValue(400) }} />
      )}
    />
  );
};

RailList.propTypes = PageRailListPropTypes;
RailList.whyDidYouRender = false;

export default React.memo(withFocusable()(RailList));
