import React, { memo } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import ContentLoader, { Rect } from 'react-content-loader/native';

import useLocalization from '../../../libs/localization/useLocalization';
import colors from '../../../helpers/Colors';

import styles from './styles';

/**
 * Skeleton Loader
 *
 * @param {object} props - props
 * @returns {module:JSX.Element} - JSX.Element
 */
const Skeleton = memo(() => {
  const { isRTL } = useLocalization();

  return (
    <View style={styles.loading}>
      <ContentLoader
        rtl={isRTL}
        speed={1}
        width={GetScaledValue(1920)}
        height={GetScaledValue(1080)}
        backgroundColor={colors.neutral700}
        foregroundColor={colors.neutral500}>
        <Rect
          x={GetScaledValue(0)}
          y={GetScaledValue(40)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(590)}
        />
        <Rect
          x={GetScaledValue(0)}
          y={GetScaledValue(670)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(380)}
          height={GetScaledValue(420)}
        />
        <Rect
          x={GetScaledValue(420)}
          y={GetScaledValue(40)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(1300)}
          height={GetScaledValue(60)}
        />

        <Rect
          x={GetScaledValue(420)}
          y={GetScaledValue(130)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(300)}
          height={GetScaledValue(50)}
        />
        <Rect
          x={GetScaledValue(430)}
          y={GetScaledValue(200)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(630)}
          y={GetScaledValue(200)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(830)}
          y={GetScaledValue(200)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1030)}
          y={GetScaledValue(200)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1230)}
          y={GetScaledValue(200)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1430)}
          y={GetScaledValue(200)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1630)}
          y={GetScaledValue(200)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />

        <Rect
          x={GetScaledValue(420)}
          y={GetScaledValue(530)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(300)}
          height={GetScaledValue(50)}
        />
        <Rect
          x={GetScaledValue(430)}
          y={GetScaledValue(590)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(630)}
          y={GetScaledValue(590)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(830)}
          y={GetScaledValue(590)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1030)}
          y={GetScaledValue(590)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1230)}
          y={GetScaledValue(590)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1430)}
          y={GetScaledValue(590)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1630)}
          y={GetScaledValue(590)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />

        <Rect
          x={GetScaledValue(420)}
          y={GetScaledValue(930)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(300)}
          height={GetScaledValue(50)}
        />
        <Rect
          x={GetScaledValue(430)}
          y={GetScaledValue(990)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(630)}
          y={GetScaledValue(990)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(830)}
          y={GetScaledValue(990)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1030)}
          y={GetScaledValue(990)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1230)}
          y={GetScaledValue(990)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1430)}
          y={GetScaledValue(990)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
        <Rect
          x={GetScaledValue(1630)}
          y={GetScaledValue(990)}
          rx={GetScaledValue(20)}
          ry={GetScaledValue(20)}
          width={GetScaledValue(180)}
          height={GetScaledValue(270)}
        />
      </ContentLoader>
    </View>
  );
});

export default Skeleton;
