import { GetScaledValue } from '@digiturk/screen-size';

import { getAssignArrayToObject } from '../../../helpers/CommonHelper';

import colors from '../../../helpers/Colors';
import { FONT_VALUES, SPACING_VALUES } from '../../../helpers/Enums';

const sizeConfig = {
  xlarge: {
    container: {
      borderRadius: GetScaledValue(12),
      paddingHorizontal: GetScaledValue(32),
      paddingVertical: GetScaledValue(19),
      paddingCircle: GetScaledValue(24),
      height: GetScaledValue(80),
    },
    iconArea: {},
    icon: {
      // Icon component already uses GetScaledValue inside
      width: 40,
      height: 40,
    },
    textArea: {
      marginStart: GetScaledValue(SPACING_VALUES.SSM),
    },
    text: {
      fontSize: GetScaledValue(FONT_VALUES.H3.FONT_SIZE),
      lineHeight: GetScaledValue(FONT_VALUES.H3.LINE_HEIGHT),
    },
  },
  big: {
    container: {
      borderRadius: GetScaledValue(12),
      paddingHorizontal: GetScaledValue(32),
      paddingVertical: GetScaledValue(19),
      paddingCircle: GetScaledValue(24),
      height: GetScaledValue(80),
    },
    iconArea: {},
    icon: {
      // Icon component already uses GetScaledValue inside
      width: 32,
      height: 32,
    },
    textArea: {
      marginStart: GetScaledValue(SPACING_VALUES.SSM),
    },
    text: {
      fontSize: GetScaledValue(FONT_VALUES.H3.FONT_SIZE),
      lineHeight: GetScaledValue(FONT_VALUES.H3.LINE_HEIGHT),
    },
  },
  medium: {
    container: {
      borderRadius: GetScaledValue(12),
      paddingHorizontal: GetScaledValue(24),
      paddingVertical: GetScaledValue(13),
      paddingCircle: GetScaledValue(19),
      height: GetScaledValue(62),
    },
    iconArea: {},
    icon: {
      // Icon component already uses GetScaledValue inside
      width: 24,
      height: 24,
    },
    textArea: {
      marginStart: GetScaledValue(8),
    },
    text: {
      fontSize: GetScaledValue(FONT_VALUES.H4.FONT_SIZE),
      lineHeight: GetScaledValue(FONT_VALUES.H4.LINE_HEIGHT),
    },
  },
  small: {
    container: {
      borderRadius: GetScaledValue(12),
      paddingHorizontal: GetScaledValue(16),
      paddingVertical: GetScaledValue(11),
      paddingCircle: GetScaledValue(15),
      height: GetScaledValue(50),
    },
    iconArea: {},
    icon: {
      // Icon component already uses GetScaledValue inside
      width: 20,
      height: 20,
    },
    textArea: {
      marginStart: GetScaledValue(8),
    },
    text: {
      fontSize: GetScaledValue(FONT_VALUES.H6.FONT_SIZE),
      lineHeight: GetScaledValue(FONT_VALUES.H6.LINE_HEIGHT),
    },
  },
  xsmall: {
    container: {
      borderRadius: GetScaledValue(12),
      paddingHorizontal: GetScaledValue(16),
      paddingVertical: GetScaledValue(8),
      paddingCircle: GetScaledValue(14),
      height: GetScaledValue(44),
    },
    iconArea: {},
    icon: {
      // Icon component already uses GetScaledValue inside
      width: 16,
      height: 16,
    },
    textArea: {
      marginStart: GetScaledValue(8),
    },
    text: {
      fontSize: GetScaledValue(FONT_VALUES.H6.FONT_SIZE),
      lineHeight: GetScaledValue(FONT_VALUES.H6.LINE_HEIGHT),
    },
  },
  xxsmall: {
    container: {
      borderRadius: GetScaledValue(8),
      paddingHorizontal: GetScaledValue(16),
      paddingVertical: GetScaledValue(6),
      paddingCircle: GetScaledValue(12),
      height: GetScaledValue(36),
    },
    iconArea: {},
    icon: {
      // Icon component already uses GetScaledValue inside
      width: 12,
      height: 12,
    },
    textArea: {
      marginStart: GetScaledValue(8),
    },
    text: {
      fontSize: GetScaledValue(FONT_VALUES.P1.FONT_SIZE),
      lineHeight: GetScaledValue(FONT_VALUES.P1.LINE_HEIGHT),
    },
  },
};

const themeConfig = {
  primary: {
    background: colors.primary500,
    text: colors.shades100,
    icon: colors.shades100,
  },
  primary600: {
    background: colors.primary600,
    text: colors.shades100,
    icon: colors.shades100,
  },
  primary800: {
    background: colors.primary800,
    text: colors.shades100,
    icon: colors.shades100,
  },
  primary200: {
    background: colors.primary200,
    text: colors.blackTransparent24,
    icon: colors.blackTransparent24,
  },
  transparent24: {
    background: colors.whiteTransparent24,
    text: colors.shades00,
    icon: colors.shades00,
  },
  transparent16: {
    background: colors.whiteTransparent16,
    text: colors.shades00,
    icon: colors.shades00,
  },
  neutralPrimary: {
    background: colors.shades00,
    text: colors.shades100,
    icon: colors.shades100,
  },
  neutralSecondary: {
    background: colors.shades100,
    text: colors.shades00,
    icon: colors.shades00,
  },
  neutralTertiary: {
    background: colors.shades100,
    text: colors.primary,
    icon: colors.primary,
  },
  neutral200: {
    background: colors.neutral200,
    text: colors.shades100,
    icon: colors.shades100,
  },
  neutral300: {
    background: colors.neutral200,
    text: colors.neutral300,
    icon: colors.neutral300,
  },
  neutral500: {
    background: colors.neutral500,
    text: colors.neutral300,
    icon: colors.shades00,
  },
  ghostWhite: {
    background: colors.transparent,
    text: colors.shades00,
    icon: colors.shades00,
  },
  ghostPrimary: {
    background: colors.transparent,
    text: colors.primary,
    icon: colors.primary,
  },
  ghostPrimary700: {
    background: colors.transparent,
    text: colors.primary700,
    icon: colors.primary700,
  },
  ghostPrimary800: {
    background: colors.transparent,
    text: colors.primary800,
    icon: colors.primary800,
  },
  ghostTransparent24: {
    background: colors.transparent,
    text: colors.blackTransparent24,
    icon: colors.blackTransparent24,
  },
  ghostTransparent16: {
    background: colors.blackTransparent16,
    text: colors.shades00,
    icon: colors.shades00,
  },
  ghostPrimaryTransparent16: {
    background: colors.blackTransparent16,
    text: colors.primary500,
    icon: colors.primary500,
  },
  ghostShadesTransparent16: {
    background: colors.blackTransparent16,
    text: colors.shades100,
    icon: colors.shades100,
  },
};

const buttonSize = getAssignArrayToObject(sizeConfig);
const buttonThemes = getAssignArrayToObject(themeConfig);

export { sizeConfig, themeConfig, buttonSize, buttonThemes };
