import PropTypes from 'prop-types';

/**
 * propType definitions for player component
 */
export const PlayerWidgetPropTypes = {
  source: PropTypes.object,
  streamFormat: PropTypes.string,
  drmData: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  onDurationChange: PropTypes.func,
  onCurrentTimeUpdate: PropTypes.func,
  onSeek: PropTypes.func,
  onLoad: PropTypes.func,
  onLoadStart: PropTypes.func,
  refPlayer: PropTypes.any,
  selectedAudioTrack: PropTypes.any,
  selectedTextTrack: PropTypes.any,
  isPaused: PropTypes.bool,
};
