import { StyleSheet } from 'react-native';

/**
 *  Styles
 *
 * @param {boolean} focused - focused
 * @returns {object} - styles
 */
const style = (focused) => {
  return StyleSheet.create({
    container: { alignItems: 'center' },
  });
};

export default style;
