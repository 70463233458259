import { lazy } from 'react';

const Carousel = lazy(() => import('../../organisms/Carousel'));
const ContentDetailContainer = lazy(() =>
  import('../../organisms/ContentDetail')
);
const Recommendation = lazy(() => import('../../organisms/Recommendation'));
const Seasons = lazy(() => import('../../organisms/Seasons'));
const FootballLiveStandings = lazy(() => import('../FootballLiveStandings'));
const Head2Head = lazy(() => import('../Head2Head'));
const FootballLineUps = lazy(() => import('../FootballLineUps'));
const Statistics = lazy(() => import('../Statistics'));
const KeyPlayers = lazy(() => import('../../organisms/KeyPlayers'));
const Substitutes = lazy(() => import('../Substitutes'));
const MatchStats = lazy(() => import('../MatchStats'));
const AllStages = lazy(() => import('../../organisms/AllStages'));
const TournamentRanking = lazy(() =>
  import('../../organisms/TournamentRanking')
);

const sectionComponentsMap = {
  default: Carousel,
  MoreLikeThis: Recommendation,
  ContentSeriesDetail: ContentDetailContainer,
  ContentFilmDetail: ContentDetailContainer,
  ContentSeriesSeasonsDetail: Seasons,
  FootballHero: ContentDetailContainer,
  HeaderHero: ContentDetailContainer,
  FootballLiveStandings: FootballLiveStandings,
  FootballHead2Head: Head2Head,
  FootballLineups: FootballLineUps,
  FootballLiveStats: Statistics,
  FootballStats: Statistics,
  FootballKeyPlayers: KeyPlayers,
  FootballSubstitutes: Substitutes,
  FootballMatchStats: MatchStats,
  Tournaments: AllStages,
  TournamentRanking: TournamentRanking,
};

export default sectionComponentsMap;
